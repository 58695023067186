import "./PickOrder.scss";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Drawer,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
  InputAdornment,
  Box,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  SwipeableDrawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  fabClasses,
} from "@mui/material";
import { Slide } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import Utils from "../../Common/Utils";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import MobileBarCodeScannerScandit from "../FulfillOrders/MobileBarCodeScannerScandit";
import ItemAction from "./ItemAction";
import { NotificationPopup } from "../Shared/NotificationPopup";
import Constants from "../../Common/Constants";
import {
  INotificationPopupSettings,
  IButton,
} from "../../Models/INotificationPopupSettings";
import { Reasons } from "../Shared/Reasons";
import { ICustomerContact, Item } from "../../Models/ICustomerContact";
import { IReasons, IReasonState } from "../../Models/IReasons";
import { IContactCustomer } from "../../Models/IContactCustomer";
import { ContactCustomer } from "../Shared/ContactCustomer";
import LabelText from "../../Common/LabelText";
import { IVerticalStepper, IStepOption } from "../../Models/IVerticalStepper";
import SiteBreadCrumbs from "../Shared/SiteBreadCrumbs";
import {
  ArrowBackIosNewOutlinedIcon,
  BallotIcon,
  EmailOutlinedIcon,
  LocalPrintshopOutlinedIcon,
  MoreVertIcon,
  SaveIcon,
  ExpandMoreIcon,
  CancelOutlinedIcon,
  StopCircleOutlinedIcon,
  AccountCircleOutlinedIcon,
  LogoutIcon,
  BallotOutlinedIcon,
} from "../Shared/SharedIcons";
import PickSlip from "../FulfillOrders/PickSlip";
import { Note } from "../Shared/Note";
import Tooltip from "@mui/material/Tooltip";
import { ResourceItem } from "./ResourceItem";
import OMSManager from "../../Managers/Oms.manager";
import ContactCustomerManager from "../../Managers/ContactCustomer.Manager";
import { PickupPrintSlip } from "../../Subcomponent/CustomerPickup/PickupPrintSlip";
import DummyDataManager from "../../Managers/DummyDataManager";
import {
  DirectionsCar,
  ShoppingCart,
  ApartmentIcon,
  DoneIcon,
  CloseIcon,
  SendIcon,
  AddCommentIcon,
  ArrowBackIcon,
  DocumentScannerIcon,
  CommentIcon,
  HighlightOffIcon,
  PersonIcon,
  AddLocationAltIcon,
  KeyboardReturnIcon,
  EmailIcon,
  LocalPhoneIcon,
  CheckIcon,
  PrintIcon,
  StopIcon,
  PlayArrowIcon,
  ReportProblemIcon,
  ArrowForwardIcon,
} from "../Shared/SharedIcons";
import PdfIframe from "./PdfIframe";
import StoreManager from "../../Managers/Store.manager";
import { ICustomerDetail } from "../../Models/IPickOrder";
import allActions from "../../Store/Actions/AllActions";
import OrderLineShimmer from "./OrderLineShimmer";
import OrderLineShimmerMobile from "./OrderLineShimmerMobile";
import CustomerDetailShimmer from "./CustomerDetailShimmer";
import CustomerDetailShimmerMobile from "./CustomerDetailShimmerMobile";
import OrderHistory from "../Shared/OrderHistory";
import OrderManager from "../../Managers/Orders.Manager";
import Chip from "@mui/material/Chip";
import { IResourceView } from "../../Models/IResourceView";
import {
  OmsPickOrderDetail,
  ShipmentLine,
} from "../../Models/OmsPickOrderDetail";
import CancelLine from "../Shared/CancelLine";
import logger from "../../Common/logger";
import IdentityManager from "../../Managers/Identity.manager";
import PFManager from "../../Managers/Process.Fulfillment.Manager";
import OrderDetailShimmer from "./OrderDetailShimmer";
import OrderDetailMobileShimmer from "./OrderDetailMobileShimmer";
import ApiService from "../../Common/ApiService";
import { OrderDetailGrid } from "../Shared/OrderDetailGrid";
import { CustomerDetails } from "../Shared/CustomerDetails";
import { IImageAttachment } from "../../Models/IImageAttachment";
import {
  IImageViewerDetail,
  IImageViewerPopupSettings,
} from "../../Models/IImageViewerPopupSettings";
import { ImageViewerPopup } from "../Shared/ImageViewerPopup";
import POSInventory from "./POSInventory";
import ShipmentStatusConstants from "../../Common/ShipmentStatusConstants";
import { ReportErrorPopup } from "../Shared/ReportErrorPopup";

import {
  DataCaptureView,
  Camera,
  DataCaptureContext,
  configure,
  FrameSourceState,
  RadiusLocationSelection,
  NumberWithUnit,
  MeasureUnit,
  AimerViewfinder,
  Brush,
  Color,
} from "scandit-web-datacapture-core";
import type {
  SymbologySettings,
  Barcode,
  BarcodeCaptureSession,
} from "scandit-web-datacapture-barcode";
import {
  barcodeCaptureLoader,
  BarcodeCapture,
  BarcodeCaptureSettings,
  Symbology,
  BarcodeCaptureOverlay,
  SymbologyDescription,
  BarcodeTrackingSettings,
  BarcodeTrackingScenario,
  BarcodeTracking,
  BarcodeTrackingBasicOverlay,
} from "scandit-web-datacapture-barcode";
import { PickBoxPopup } from "../Shared/PickBox";
import { ServiceNowCnfrmText } from "../Shared/ServiceNowCnfrmText";
import ItemLocations from "./ItemLocations";
import MobileBarCodeMatrixScan from "../FulfillOrders/MobileBarCodeMatrixScan";
import ExtendedDescription from "./ExtendedDescription";
var moment = require("moment");

let step: IStepOption[] = [];
step.push({
  label: "Order Release",
  description: ``,
  time: "Jan 20 2022 13:11:17 ",
});
step.push({
  label: "Customer Contacted",
  description: "dummy",
  time: "Jan 20 2022 13:11:17 ",
});
step.push({ label: "Picking", description: "", time: "" });
step.push({ label: "Packing", description: ``, time: "" });
step.push({ label: "Shipped", description: ``, time: "" });
let verticalStepperOptions: IVerticalStepper = {
  activeStep: "Picking",
  stepsOptions: step,
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PickOrder = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = window.location.search;
  const pathname = window.location.pathname;
  const licenseKey = StoreManager.config.scanditLicense;

  const urlParams = new URLSearchParams(queryString);
  const shipmentkey = urlParams.get("shipmentkey");
  const orderFromUrl = urlParams.get("orderno");
  const queryParamStore = urlParams.get("store");
  console.log(orderFromUrl, "orderFromUrl_PickOrder");
  const [openDrawer, setDrawer] = useState(false);
  const [openHistoryDrawer, setHistoryDrawer] = useState(false);
  const [isScan, setScan] = useState(false);
  const [pickComplete, setPickComplete] = useState(false);
  const [onHoldComplete, setonHoldComplete] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [resource, setResource] = useState(false);
  const [verifyCredentials, setVerifyCredentials] = useState(false);
  const [dynamicHtml, setDynamicHtml] = useState("");
  const [resourceData, setResourceData] = useState<IResourceView>();
  const [contactCustomer, setContactCustomer] = useState(false);
  const [notes, setNotes] = useState("");
  const [notePopup, setNotesPopup] = useState(false);
  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);
  const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions);
  const [blobUrl, setBlobUrl] = useState("");
  const [showPdf, SetShowPdf] = useState(false);
  const [showPickupSlip, setShowPickupSlip] = useState(0);
  const [printPickupSlipData, setPrintPickupSlipData] = useState({} as any);
  const isHistoryLoading = useSelector(
    (state: any) => state.OrderHistoryReducerState.isLoading
  );
  let customerDetail: ICustomerDetail = useSelector(
    (state: any) => state.PickOrdersReducersState.customerDetail
  );
  const [comissionAssociateIds, setComissionAssociateIds] = useState([] as any);
  const [isResourced, setIsResourced] = useState(false);
  // let customerDetailOMS: ICustomerDetail = useSelector((state: any) => state.PickOrdersReducersState.customerDetailOMS);

  let orderDetail: OmsPickOrderDetail = useSelector(
    (state: any) => state.PickOrdersReducersState.orderDetail
  );

  let scanedShipmentLines = useSelector(
    (state: any) => state.PickOrdersReducersState.scanedShipmentLines
  );
  // cancel line popup
  let cancelReason: IReasonState = useSelector(
    (state: any) => state.ActionLoaderReducersState.cancelReason
  );
  const showModal = cancelReason.showModal;
  // contact customer
  let storeEmail = useSelector(
    (state: any) => state.ContactCustomerReducersState.storeEmail
  );
  let selectedTemplate = useSelector(
    (state: any) => state.ContactCustomerReducersState.selectedTemplate
  );

  let selectedOrderLines: ICustomerContact = useSelector(
    (state: any) => state.ContactCustomerReducersState.selectedOrderLines
  );
  let contactCustomerHistory = useSelector(
    (state: any) => state.ContactCustomerReducersState.contactCustomerHistory
  );
  let customBody = useSelector(
    (state: any) => state.ContactCustomerReducersState.customBody
  );
  let customSub = useSelector(
    (state: any) => state.ContactCustomerReducersState.customSub
  );

  let parentContactCustomer = useSelector(
    (state: any) => state.ContactCustomerReducersState.parentContactCustomer
  );
  const attachmentImages = useSelector(
    (state: any) => state.ActionLoaderReducersState.attachmentImages
  );
  const storesPopUp = useSelector(
    (state: any) => state.ActionLoaderReducersState.openDrawerDropdowns
  );
  //
  const [orderNumber, setOrderNumber] = useState("");
  const [loadingOrderLines, setLoadingOrderLines] = useState(true);
  const [loadingScanner, setLoadingScanner] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong");
  const [openError, setOpenError] = useState(false);
  const [skuToScan, setSKUNumberForScan] = useState("");
  const [scannedQty, setScannedQty] = useState("1");
  const [zebraScanner, setZebraScanner] = useState(false);
  const [pickedItems, setPickedItems] = useState([] as any);
  const [showLoader, setShowLoader] = useState(false);
  const [openGeneralError, setOpenGeneralError] = useState(false);
  const [openSuccess, setSuccess] = useState(false);
  const [navigationFulfillment, setNavigationFulfillment] = useState(false);
  const [errorGeneralMsg, setErrorGeneralMsg] = useState("");
  const [orderDetails, setOrderDetails] = useState({
    fulfillmentType: "",
    levelOfService: "",
    pickUpStore: "",
    storeNumber: "",
  });
  const [navigateTo, setNavigateTo] = useState("");
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imageData, setImageData] = useState({} as IImageViewerDetail);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Report Error Pop Up Use States
  const [openReportError, setOpenReportError] = useState(false);
  const [openAlreadyReportedError, setOpenAlreadyReportedError] =
    useState(false);
  const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
  const [errorReportMsg, setErrorReportMsg] = useState("");

  //Report Error Action Use State
  const [reportErrorAction, setReportErrorAction] = useState("");

  //Box Feature PopUp Use State
  const [openBoxFeature, setOpenBoxFeature] = useState(false);
  const [orderDetailsFromElastic, setOrderDetailsFromElastic] = useState(
    {} as any
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  //Scandit States
  const [scanditView, setScanditView] = useState(
    {} as DataCaptureView | undefined
  );
  const [context, setScanditContext] = useState(
    {} as DataCaptureContext | undefined
  );
  const [camera, setCamera] = useState({} as Camera | undefined);
  const [barCodeTracking, setBarcodeTracking] = useState(
    {} as BarcodeTracking | undefined
  );
  const [barCodeOverlay, setBarCodeOverlay] = useState(
    {} as BarcodeTrackingBasicOverlay | undefined
  );
  const inputRef: any = useRef(null);
  const scannedInput = useRef<string>("");
  const [randomInputValue, setRandomInputValue] = useState(""); // Stores the final input value
  const notesPopup = useSelector(
    (state: any) => state.ActionLoaderReducersState.notesPopup
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openLoadingOrderLines = () => {
    setLoadingOrderLines(true);
  };
  const closeLoadingOrderLines = () => {
    setLoadingOrderLines(false);
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const classes = useStyles();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#D93A2F",
      },
      secondary: {
        main: "#FFFFFF",
      },
    },
  });

  const navigateToFulfillment = () => {
    navigate("/FulfillOrders");
  };

  const toPack = () => {
    //dispatch(allActions.fulfillOrdersActions.changeFOTab(2));
    //navigate("/FulfillOrders");
    navigate(
      "/PackOrder?orderno=" +
        orderNumber +
        "&shipmentkey=" +
        shipmentkey +
        "&store=" +
        queryParamStore +
        ""
    );
  };
  const toPickup = () => {
    //dispatch(allActions.fulfillOrdersActions.changeFOTab(2));
    //navigate("/FulfillOrders");
    navigate(
      "/CustomerPickup?orderno=" +
        orderNumber +
        "&shipmentkey=" +
        shipmentkey +
        "&store=" +
        queryParamStore +
        ""
    );
  };
  console.log(classes.root, "asas");
  const TestState = useSelector((state: any) => state.TestReducerState);
  console.log(TestState, "TestState");
  var startProductBarPos = -1;
  window.onscroll = function () {
    console.log("scrolled");
    var bar = document.getElementById("bar") as any;
    var mblBar = document.getElementById("mblBar") as any;
    if (bar) {
      if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
      if (window.pageYOffset > startProductBarPos) {
        bar.classList.add("scrolledTopFix");
      } else {
        bar.classList.remove("scrolledTopFix");
      }
    }
  };
  function findPosY(obj: any) {
    var curtop = 0;
    if (obj && typeof obj.offsetParent != "undefined" && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    } else if (obj && obj.y) curtop += obj.y;
    return curtop;
  }
  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  };
  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  };
  const openSuccessNotification = (message: any) => {
    dispatch(allActions.genericNotificationActions.showSuccessMessage(message));
  };

  const onScannedComplete = (data: any, isError: any) => {
    //
    if (isError == false) {
      scanItemId();
    }
    setScan(false);
  };
  const closePickComplete = () => {
    setPickComplete(false);
    navigateToFulfillment();
  };
  const closeNotes = () => {
    setNotesPopup(false);
  };
  const openNotes = () => {
    setNotesPopup(true);
    setNotes("");
  };
  const changeNote = (event: any) => {
    setNotes(event.target.value);
  };

  const onHoldClick = () => {
    GCPickConfirm(true);
  };
  const onUnHoldClick = async () => {
    try {
      showActionLoader();
      let response: any = {};
      response = await OMSManager.GCUnHoldPickConfirm(orderDetail);
      console.log("Un Hold response", response);
      if (response.success && response.data) {
        await OrderManager.saveOrderHistory({
          shipmentKey: shipmentkey,
          logType: "history",
          transactionType: Constants.OrderHistoryTypes.UnHold,
          message: notes,
          transactionDate: moment
            .utc(moment.tz("America/Los_Angeles"))
            .toISOString(),
        });

        hideActionLoader();
        //navigateToFulfillment();
      } else {
        let errorMsg = "";
        hideActionLoader();
        //showGeneralErrorModel(LabelText.generalErrorMessage);
        if (!response.success && response.msg) {
          errorMsg = response.msg;
        } else {
          // errorMsg = LabelText.generalErrorMessage;
          // showGeneralErrorModel(errorMsg);
        }
      }
    } catch (error) {
      showGeneralErrorModel(
        LabelText.generalErrorMessage,
        Constants.REPORT_ERROR_ACTIONS.PICK_UNHOLD
      );
      hideActionLoader();
      return;
    }
  };

  const pickCompleteClick = () => {
    //Open Popup for box feature
    if (
      orderDetailsFromElastic?.IsSameStorePickup === "Y" &&
      orderDetailsFromElastic?.ExtnPickupStoreID ===
        orderDetailsFromElastic?.ShipNode
    ) {
      setOpenBoxFeature(true);
    } else {
      GCPickConfirm();
    }
  };

  const cancelClick = (item: any) => {
    setCancel(true);
  };
  const openNavigationFulfillment = () => {
    setNavigationFulfillment(true);
  };

  const resourceClick = (shipmentKey: any) => {
    if (
      (props.store == undefined || props.store?.length == 0) &&
      StoreManager.currentStore.StoreId == undefined
    ) {
      showGeneralErrorModel("Please select store", null);
      return;
    } else {
      dispatch(
        allActions.pickOrderActions.chagneSelectedShipmentKey(shipmentKey)
      );
      setResource(true);
    }
  };

  const contactCustomerClick = () => {
    if (
      (props.store == undefined || props.store?.length == 0) &&
      StoreManager.currentStore.StoreId == undefined
    ) {
      showGeneralErrorModel("Please select store", null);
      return;
    } else {
      setContactCustomer(true);
    }
  };

  const closeContactCustomer = () => {
    dispatch(allActions.actionLoaderActions.setAttachmentImages([]));
    setContactCustomer(false);
  };

  const validateTemplateParameters = () => {
    let valid = true;
    let anyChecked = false;
    let errorMessage = "";
    if (StoreManager.currentStore.StoreEmail == "" && storeEmail == "") {
      valid = false;
      errorMessage = "Please add store email";
    } else if (
      selectedTemplate.filename == undefined ||
      selectedTemplate.filename == ""
    ) {
      valid = false;
      errorMessage = "Please select template";
    }
    selectedOrderLines.items.forEach((item: Item) => {
      if (item.selected) {
        anyChecked = true;
      }
    });
    if (
      anyChecked == false &&
      selectedTemplate.filename != Constants.ContactCustomerFileName.FollowUp
    ) {
      valid = false;
      errorMessage = "Please check an item";
    }
    if (
      (selectedTemplate.filename ==
        Constants.ContactCustomerFileName.FollowUp ||
        selectedTemplate.filename ==
          Constants.ContactCustomerFileName.Custom) &&
      customBody == ""
    ) {
      valid = false;
      errorMessage = "Please add Body";
    }
    if (
      (selectedTemplate.filename ==
        Constants.ContactCustomerFileName.FollowUp ||
        selectedTemplate.filename ==
          Constants.ContactCustomerFileName.Custom) &&
      customSub == ""
    ) {
      valid = false;
      errorMessage = "Please add Subject";
    }
    if (valid) {
      return true;
    } else {
      showGeneralErrorModel(errorMessage, "");
      return false;
    }
  };

  const getImageAttachments = () => {
    let attachments: IImageAttachment[] = [];
    let payload = {} as IImageAttachment;
    let fileName = "";
    attachmentImages?.forEach((image: any) => {
      fileName = `${Utils.generateUUID()}.${image?.type?.split("/")[1]}`;
      payload = {
        content: image?.binary?.substring(image?.binary?.indexOf(",") + 1),
        filename: fileName,
        type: image.type,
        path: `${orderFromUrl}/${shipmentkey}/${fileName}`,
      };
      attachments.push(payload);
    });
    return attachments;
  };

  const getCustomerContactPayload = () => {
    let payload = JSON.parse(JSON.stringify(selectedOrderLines));
    let ccShipmentLineKeys = [] as any;
    if (payload.storeemail == "") {
      payload.storeemail = storeEmail;
    }
    if (payload.filename == "") {
      payload.filename = selectedTemplate.filename;
      payload.emailsubject =
        selectedTemplate.filename == Constants.ContactCustomerFileName.Custom
          ? customSub
          : selectedTemplate.subject;
    }
    if (selectedTemplate.filename == Constants.ContactCustomerFileName.Custom) {
      payload.customBody = customBody;
    }
    let selectedItems = [] as any;
    selectedOrderLines.items.forEach((item) => {
      if (item.selected) {
        selectedItems.push(item);
        ccShipmentLineKeys.push(item.shipmentlinekey);
      }
    });
    payload.items = selectedItems;
    payload["ccShipmentLineKeys"] = ccShipmentLineKeys;
    return payload;
  };
  const getFollowupCustomerPayload = () => {
    let payload = JSON.parse(JSON.stringify(selectedOrderLines));
    if (payload.storeemail == "") {
      payload.storeemail = storeEmail;
    }
    if (payload.filename == "") {
      payload.filename = selectedTemplate.filename;
      payload.emailsubject = customSub;
    }
    payload.customBody = customBody;
    payload.contactCustomerHistory = Utils.sortContactCustomerHistory(
      JSON.parse(JSON.stringify(contactCustomerHistory))
    );
    return payload;
  };
  const getOrderLinesNote = () => {
    let selectedNotes = "";
    if (
      selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp
    ) {
      selectedNotes = customSub + "-" + customBody;
    }
    if (selectedTemplate.filename == Constants.ContactCustomerFileName.Custom) {
      selectedNotes =
        customSub +
        "-" +
        customBody +
        "-" +
        selectedOrderLines.items
          .filter((item) => item.selected && item.message)
          .map((a) => a.message)
          .join(",");
    } else {
      selectedNotes =
        selectedTemplate.subject +
        "-" +
        selectedOrderLines.items
          .filter((item) => item.selected && item.message)
          .map((a) => a.message)
          .join(",");
    }
    return selectedNotes;
  };

  const getNotesPayload = () => {
    let payload = {
      Order: {
        OrderHeaderKey: orderDetail.OrderHeaderKey,
        Notes: {
          Note: {
            AuditTransactionid: Constants.AuditTransactionidTypes.Associate,
            ContactType: "E-Mail",
            ContactTime: moment
              .utc(moment.tz("America/Los_Angeles"))
              .toISOString(),
            ContactUser: OMSManager.GetUserIdDoamin().user,
            Priority: "0.00",
            VisibleToAll: "Y",
            NoteText: getOrderLinesNote(),
          },
        },
      },
    };
    return payload;
  };

  const sendReceipientAndMsgs = async () => {
    // Check if Number Exist
    if (!customerDetail?.phone) {
      return {
        smsSent: false,
        payload: customerDetail?.phone,
        error: "Number is not valid",
        response: "",
      };
    }

    // Check if Number is Valid or not
    let phoneNumber = customerDetail?.phone;
    if (phoneNumber.indexOf("+1") === -1) {
      phoneNumber = "+1" + phoneNumber;
    }
    let customerPhoneNumber = Utils.validateUSNumber(phoneNumber);
    if (!customerPhoneNumber) {
      return {
        smsSent: false,
        payload: customerDetail?.phone,
        error: "Number is not valid",
        response: "",
      };
    }

    let result = JSON.parse(JSON.stringify(selectedOrderLines));

    // Get URL of specific Order
    const urlObj = {
      shipmentkey: result.shipmentkey,
      guid: result.guid,
      code: result.code,
      customeremail: result.customeremail,
      orderno: result.orderno,
      url: result.url,
    };

    let orderURL = null;
    try {
      const shortUrlResponse = await OrderManager.getDyamicOrderURL(urlObj);
      orderURL = shortUrlResponse?.data?.response?.data;
    } catch (error) {
      return {
        smsSent: false,
        payload: urlObj,
        error: JSON.stringify(error),
        response: "",
      };
    }

    const selectedTemplateName = selectedTemplate?.filename?.replace(
      ".txt",
      ""
    );
    // Get SMS from dynamo
    let smsBody = "";
    try {
      smsBody = Utils.getSMSMsg(selectedTemplateName);
    } catch (error) {
      return {
        smsSent: false,
        payload: selectedTemplateName,
        error: JSON.stringify(error),
        response: "",
      };
    }

    if (smsBody && customerPhoneNumber && orderURL) {
      // Get short URL from Order Manager

      let shortUrl = "";
      try {
        const shortUrlResponse = await OrderManager.getShortURL({
          url: orderURL,
        });
        shortUrl = shortUrlResponse?.data?.data?.tiny_url.replace(
          "https://",
          ""
        );
      } catch (error) {
        return {
          smsSent: false,
          payload: orderURL,
          error: JSON.stringify(error),
          response: "",
        };
      }

      if (shortUrl) {
        let payload = { phone: "", message: "" };
        payload.phone = customerPhoneNumber;
        payload.message = smsBody;
        payload.message = payload.message.replace("GCxxxxx", result?.orderno);
        payload.message = payload.message.replace("xxxx", result?.code);
        payload.message = payload.message.replace(
          "[Shortened URL—23 Char]",
          shortUrl ? shortUrl.trim() : ""
        );

        if (shortUrl?.length <= 23) {
          try {
            var response = await OrderManager.sendInfoToSNS(payload);
            return {
              smsSent: true,
              payload: payload,
              error: "",
              response: JSON.stringify(response),
            };
          } catch (error) {
            return {
              smsSent: false,
              payload: payload,
              error: JSON.stringify(error),
              response: "",
            };
          }
        } else {
          return {
            smsSent: false,
            payload: payload,
            error: "Length Excceds 23 Characters",
            response: "",
          };
        }
      }
    }
  };
  const getCommissionAssociateEmails = async () => {
    var emailList = [] as any;
    if (comissionAssociateIds && comissionAssociateIds != "") {
      for (let i = 0; i < comissionAssociateIds.length; i++) {
        if (comissionAssociateIds[i] != "") {
          const oktaProfileResponse =
            await ContactCustomerManager.getDisplayNameFromOktaByEmpId(
              comissionAssociateIds[i]
            );
          if (
            oktaProfileResponse &&
            oktaProfileResponse.data &&
            oktaProfileResponse.data.length > 0
          ) {
            if (oktaProfileResponse.data[0]?.profile?.email) {
              emailList.push(oktaProfileResponse.data[0]?.profile?.email);
            }
          }
        }
      }
    }
    const distinctemailsList = emailList.filter(
      (value: any, index: any, self: any) => self.indexOf(value) === index
    );
    return distinctemailsList.join(",");
  };
  const saveContactCustomer = async () => {
    // add on off feature settings
    var comissionAssociateEmails = await getCommissionAssociateEmails();
    let sendMsgResponse = {} as any;
    if (validateTemplateParameters()) {
      if (
        selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp
      ) {
        let payload = getFollowupCustomerPayload();
        let imagesPayload: IImageAttachment[] = getImageAttachments();
        let tempHistory = payload.contactCustomerHistory;
        payload.shipmentKey = shipmentkey;
        payload.logType = "history";
        payload.transactionType =
          Constants.OrderHistoryTypes.Customer_Contacted;
        payload.message = "";
        payload.transactionDate = moment
          .utc(moment.tz("America/Los_Angeles"))
          .toISOString();
        payload.responded = "false";
        payload.customerresponse = {};
        payload.customermessage = "";
        payload.isFollowup = true;
        payload.isCustom = false;
        payload.urlDisabled = false;
        payload.commissionAssociatesEmails = comissionAssociateEmails;
        payload.customerresponsedate = moment
          .utc(moment.tz("America/Los_Angeles"))
          .toISOString();
        if (imagesPayload.length > 0) {
          payload.imageAttachments = imagesPayload.map((image) => {
            const { filename, type, path } = image;
            return { filename, type, path };
          });
        }
        if (payload.hasOwnProperty("contactCustomerHistory")) {
          delete payload.contactCustomerHistory;
        }
        if (parentContactCustomer) {
          payload.parentContactCustomerGuid = parentContactCustomer;
        }

        showActionLoader();
        if (
          payload.orderno &&
          payload.orderno != "" &&
          payload.parentContactCustomerGuid &&
          payload.parentContactCustomerGuid != ""
        ) {
          let disableUrlsResult = await OrderManager.getUrlDisabledByGuid({
            guid: payload.parentContactCustomerGuid,
            order: payload.orderno,
          });
        }

        if (Utils.getConfigurationWithKey("sendMessageFlag")) {
          payload.smsPhone = customerDetail?.phone;
          sendMsgResponse = await sendReceipientAndMsgs(); //{ smsSent: false, payload: payload, error: error }
          payload.isSmsSend = sendMsgResponse.smsSent;
          payload.smsPayloadString = JSON.stringify(sendMsgResponse.payload);
          payload.smsError = sendMsgResponse.error;
          payload.snsResponse = sendMsgResponse.response;
        }
        OrderManager.saveOrderHistory(payload).then((result: any) => {
          if (result.success) {
            payload.contactCustomerHistory = tempHistory;
            if (payload.hasOwnProperty("imageAttachments")) {
              payload.imageAttachments = imagesPayload;
            }
            dispatch(allActions.orderHistoryActions.forceHistoryLoad());
            ContactCustomerManager.setFollowupTemplateHtml(payload)
              .then((resultMail) => {
                if (
                  resultMail.success &&
                  resultMail?.data?.response?.success == true
                ) {
                  // show message to use success
                  openSuccessNotification("Email sent to customer");
                  hideActionLoader();
                } else {
                  hideActionLoader();
                  showGeneralErrorModel(
                    LabelText.generalErrorMessage,
                    Constants.REPORT_ERROR_ACTIONS.PICK_EMAIL_TEMPLATE
                  );
                }
                closeContactCustomer();
              })
              .catch((error) => {
                hideActionLoader();
                showGeneralErrorModel(
                  LabelText.generalErrorMessage,
                  Constants.REPORT_ERROR_ACTIONS.PICK_EMAIL_TEMPLATE
                );
                closeContactCustomer();
              });
          } else {
            hideActionLoader();
            showGeneralErrorModel(
              LabelText.generalErrorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_EMAIL_TEMPLATE
            );
          }
        });
        let notesPayload = getNotesPayload();
        // we have to send oms log as well
        OMSManager.omsGeneral({
          urlName: "GCAddNoteOnEmailTrigger",
          payLoad: notesPayload,
        }).then((result: any) => {
          // if (!result.success) {
          //     showGeneralErrorModel(LabelText.generalErrorMessage);
          // }
        });
      } else {
        let payload = getCustomerContactPayload();
        let imagesPayload: IImageAttachment[] = getImageAttachments();
        payload.shipmentKey = shipmentkey;
        payload.logType = "history";
        payload.transactionType =
          Constants.OrderHistoryTypes.Customer_Contacted;
        payload.message = "";
        payload.transactionDate = moment
          .utc(moment.tz("America/Los_Angeles"))
          .toISOString();
        payload.responded = "false";
        payload.customerresponse = {};
        payload.customermessage = "";
        payload.isFollowup = false;
        payload.urlDisabled = false;
        payload.isCustom =
          selectedTemplate.filename == Constants.ContactCustomerFileName.Custom
            ? true
            : false;
        payload.customerresponsedate = moment
          .utc(moment.tz("America/Los_Angeles"))
          .toISOString();
        payload.commissionAssociatesEmails = comissionAssociateEmails;
        if (imagesPayload.length > 0) {
          payload.imageAttachments = imagesPayload.map((image) => {
            const { filename, type, path } = image;
            return { filename, type, path };
          });
        }
        showActionLoader();
        if (Utils.getConfigurationWithKey("sendMessageFlag")) {
          payload.smsPhone = customerDetail?.phone;
          sendMsgResponse = await sendReceipientAndMsgs(); //{ smsSent: false, payload: payload, error: error }
          payload.isSmsSend = sendMsgResponse.smsSent;
          payload.smsPayloadString = JSON.stringify(sendMsgResponse.payload);
          payload.smsError = sendMsgResponse.error;
          payload.snsResponse = sendMsgResponse.response;
        }

        OrderManager.saveOrderHistory(payload).then((result: any) => {
          if (result.success) {
            dispatch(allActions.orderHistoryActions.forceHistoryLoad());
            if (payload.hasOwnProperty("imageAttachments")) {
              payload.imageAttachments = imagesPayload;
            }
            ContactCustomerManager.sendCustomerEmail(payload)
              .then((resultMail) => {
                if (
                  resultMail.success &&
                  resultMail?.data?.response?.success == true
                ) {
                  // show message to use success
                  openSuccessNotification("Email sent to customer");
                  hideActionLoader();
                } else {
                  hideActionLoader();
                  showGeneralErrorModel(
                    LabelText.generalErrorMessage,
                    Constants.REPORT_ERROR_ACTIONS.PICK_SEND_EMAIL
                  );
                }
                closeContactCustomer();
              })
              .catch((error) => {
                hideActionLoader();
                showGeneralErrorModel(
                  LabelText.generalErrorMessage,
                  Constants.REPORT_ERROR_ACTIONS.PICK_SEND_EMAIL
                );
                closeContactCustomer();
              });
          } else {
            hideActionLoader();
            showGeneralErrorModel(
              LabelText.generalErrorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_SEND_EMAIL
            );
          }
        });

        let notesPayload = getNotesPayload();
        OMSManager.omsGeneral({
          urlName: "GCAddNoteOnEmailTrigger",
          payLoad: notesPayload,
        }).then((result: any) => {
          // if (!result.success) {
          //     showGeneralErrorModel(LabelText.generalErrorMessage);
          // }
        });
      }
    }
  };

  const viewEmailTemplate = async () => {
    //
    if (validateTemplateParameters()) {
      showActionLoader();
      if (
        selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp
      ) {
        let payload = getFollowupCustomerPayload();
        ContactCustomerManager.getFollowupTemplateHtml(payload)
          .then((result) => {
            if (result.success == true) {
              if (
                result?.data?.response?.success == true &&
                result?.data?.response?.data
              ) {
                setDynamicHtml(result?.data?.response?.data);
                setOpenEmailTemplate(true);
                hideActionLoader();
              } else {
                showGeneralErrorModel(
                  LabelText.generalErrorMessage,
                  Constants.REPORT_ERROR_ACTIONS.PICK_VIEW_EMAIL_TEMPLATE
                );
                hideActionLoader();
              }
            } else {
              showGeneralErrorModel(
                LabelText.generalErrorMessage,
                Constants.REPORT_ERROR_ACTIONS.PICK_VIEW_EMAIL_TEMPLATE
              );
              hideActionLoader();
            }
            //
          })
          .catch((error) => {
            showGeneralErrorModel(
              LabelText.generalErrorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_VIEW_EMAIL_TEMPLATE
            );
            console.log(error);
            hideActionLoader();
            //
          });
      } else {
        let payload = getCustomerContactPayload();
        if (
          selectedTemplate.filename == Constants.ContactCustomerFileName.Custom
        ) {
          payload.isCustom = true;
        }
        ContactCustomerManager.getTemplateHtml(payload)
          .then((result) => {
            if (result.success == true) {
              if (
                result?.data?.response?.success == true &&
                result?.data?.response?.data
              ) {
                setDynamicHtml(result?.data?.response?.data);
                setOpenEmailTemplate(true);
                hideActionLoader();
              } else {
                showGeneralErrorModel(
                  LabelText.generalErrorMessage,
                  Constants.REPORT_ERROR_ACTIONS
                    .PICK_CONTACT_CUSTOMER_TEMPLATE_HTML
                );
                hideActionLoader();
              }
            } else {
              showGeneralErrorModel(
                LabelText.generalErrorMessage,
                Constants.REPORT_ERROR_ACTIONS
                  .PICK_CONTACT_CUSTOMER_TEMPLATE_HTML
              );
              hideActionLoader();
            }
            //
          })
          .catch((error) => {
            showGeneralErrorModel(
              LabelText.generalErrorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_CONTACT_CUSTOMER_TEMPLATE_HTML
            );
            console.log(error);
            hideActionLoader();
            //
          });
      }
    }
  };
  const closeCancel = () => {
    setCancel(false);
  };
  const closeResource = () => {
    // check validation
    setResource(false);
  };

  const keyPress = (e: any) => {
    if (e.keyCode == 13) {
      scanItemId();
    }
  };
  const checkSkuOlderOrder = async (ItemId: any) => {
    try {
      let storeId: any = IdentityManager.getStoreId(props.store);
      PFManager.checkSkuOlderOrder(storeId, ItemId).then((_data: any) => {
        console.log(_data, "checkSkuOlderOrder");
        hideActionLoader();
        if (_data.success && _data.data && _data.data.length > 0) {
          // show the popup heer
          console.log(orderFromUrl, "orderFromUrl");
          if (orderFromUrl != _data.data) {
            let msg =
              "Same ItemId/sku # has been waiting to be picked in order # " +
              _data.data;
            dispatch(
              allActions.genericNotificationActions.showSuccessMessage(msg)
            );
          }
        } else {
          hideActionLoader();
        }
      });
    } catch (e) {
      hideActionLoader();
    }
  };

  const scanItemId = async () => {
    // get quantity
    // and ScanNo
    //QuantityPicked: string, ScanNo: string, ShipmentKey: string, ShipNode: string
    debugger;
    if (!skuToScan.length) {
      return;
    }
    if (
      (props.store == undefined || props.store?.length == 0) &&
      StoreManager.currentStore.StoreId == undefined
    ) {
      showGeneralErrorModel("Please select store", null);
      return;
    }
    let storeId = IdentityManager.getStoreId(props.store);
    let quantityPicked = scannedQty;
    let ScanNo = skuToScan.trim();
    let ShipmentKey = orderDetail.ShipmentKey;
    let ShipNode = storeId;
    let response: any = {};
    try {
      showActionLoader();
      response = await OMSManager.scanSkueUpdated(
        quantityPicked,
        ScanNo,
        ShipmentKey,
        ShipNode
      );
    } catch {
      showGeneralErrorModel(
        LabelText.generalErrorMessage,
        Constants.REPORT_ERROR_ACTIONS.PICK_SCAN_SKU
      );

      hideActionLoader();
      return;
    }
    if (response.success && response.data) {
      let responseData = response.data;
      if (
        responseData?.Shipment?.length > 0 &&
        !responseData?.Shipment[0]?.ValidationError &&
        responseData?.Shipment[0]?.ShipmentLines?.ShipmentLine != undefined &&
        responseData?.Shipment[0]?.ShipmentLines?.ShipmentLine.length > 0
      ) {
        let lines = responseData?.Shipment[0]?.ShipmentLines?.ShipmentLine;
        let isSuccess = true;
        let errorMessage = "";
        if (lines.length > 0) {
          const checkResult = Utils.checkPosScanResult(lines); // Loop over all lines and check if we any any error against POSScanResult
          isSuccess = checkResult.isSuccess;
          errorMessage = checkResult.errorMessage;
        } else {
          isSuccess = false;
        }
        if (isSuccess) {
          // loop over
          try {
            let isSuccessByQuantity = true;
            //let POSScanResult = lines[0].POSScanResult // first scan // now we are not doing it
            let oneScanDone = false;
            let isWarrentyinScanResult = Utils.isWarrantyItem(lines); // if we have any warrenty line in result
            let filteredLines = Utils.filterWarrantyLines(lines); // filtered Lines without warranty lines
            filteredLines.forEach((scannedLine: any) => {
              if (oneScanDone) {
                return;
              }
              for (
                let i = 0;
                i < orderDetail.ShipmentLines.ShipmentLine.length;
                i++
              ) {
                // loop over shipment lines
                //(Number(item.Quantity) - Number(item.ScannedQty)) == 0
                if (
                  Utils.shipmentLineNeedScanning(
                    orderDetail.ShipmentLines.ShipmentLine[i]
                  )
                ) {
                  // skipping the already scanned line
                  if (
                    orderDetail.ShipmentLines.ShipmentLine[i].ShipmentLineKey ==
                    scannedLine.ShipmentLineKey
                  ) {
                    // comparing shipment line key
                    let POSScanResult = Utils.getPocScanResult(
                      lines,
                      scannedLine
                    ); // checking for duplicate result
                    let totatQtyScanned = 0;
                    if (
                      orderDetail?.ShipmentLines?.ShipmentLine[i]?.ScannedQty
                    ) {
                      // check if quantity already exists
                      totatQtyScanned = Number(
                        orderDetail?.ShipmentLines?.ShipmentLine[i]?.ScannedQty
                      );
                    }
                    totatQtyScanned =
                      Number(totatQtyScanned) + Number(quantityPicked); // sum of old quantity with new one
                    if (
                      Number(totatQtyScanned) <=
                      Number(orderDetail.ShipmentLines.ShipmentLine[i].Quantity)
                    ) {
                      // is scanning is done
                      orderDetail.ShipmentLines.ShipmentLine[i].IsScanned =
                        true;
                      orderDetail.ShipmentLines.ShipmentLine[i].ScannedQty =
                        totatQtyScanned;
                      orderDetail.ShipmentLines.ShipmentLine[
                        i
                      ].GCPickScanSkuResult = POSScanResult;
                      if (
                        pickedItems.filter(
                          (a: any) =>
                            a.skuToScan == skuToScan &&
                            a.shipmentLineKey ==
                              orderDetail.ShipmentLines.ShipmentLine[i]
                                .ShipmentLineKey
                        ).length == 0
                      ) {
                        pickedItems.push({
                          skuToScan: skuToScan,
                          Quantity: totatQtyScanned,
                          SKUNumber: POSScanResult?.SKUNumber,
                          shipmentLineKey:
                            orderDetail.ShipmentLines.ShipmentLine[i]
                              .ShipmentLineKey,
                          ItemDesc: POSScanResult?.POSItemDesc,
                          posItemId: POSScanResult?.POSItemID,
                        });
                        setPickedItems(pickedItems);
                        oneScanDone = true;
                      } else {
                        for (let p = 0; p < pickedItems.length; p++) {
                          // if scanning is partially done updating quantity
                          if (pickedItems[p].skuToScan == skuToScan) {
                            pickedItems[p].Quantity = totatQtyScanned;
                            setPickedItems(pickedItems);
                            oneScanDone = true;
                          }
                        }
                      }
                    } else {
                      // if there is issue in quantity
                      isSuccessByQuantity = false;
                      hideActionLoader();
                      showGeneralErrorModel("Invalid Picked Quantity", "");
                      oneScanDone = true;
                    }
                  }
                  if (
                    oneScanDone &&
                    orderDetail.ShipmentLines.ShipmentLine[i].hasOwnProperty(
                      "WarrantyComponents"
                    )
                  ) {
                    // for warranty component
                    if (isWarrentyinScanResult) {
                      if (
                        Array.isArray(
                          orderDetail.ShipmentLines.ShipmentLine[i]
                            .WarrantyComponents?.ShipmentLine
                        )
                      ) {
                        for (
                          let w = 0;
                          w <
                          orderDetail.ShipmentLines.ShipmentLine[i]
                            .WarrantyComponents?.ShipmentLine.length;
                          w++
                        ) {
                          const warrantyPOSScanResult =
                            Utils.getWarrantyPOSScanResult(
                              lines,
                              orderDetail.ShipmentLines.ShipmentLine[i]
                                .WarrantyComponents?.ShipmentLine[w]
                                ?.ShipmentLineKey
                            );
                          if (
                            warrantyPOSScanResult &&
                            warrantyPOSScanResult.SKUNumber &&
                            warrantyPOSScanResult.SKUNumber != ""
                          ) {
                            orderDetail.ShipmentLines.ShipmentLine[
                              i
                            ].WarrantyComponents.ShipmentLine[w].IsScanned =
                              true;
                            orderDetail.ShipmentLines.ShipmentLine[
                              i
                            ].WarrantyComponents.ShipmentLine[w].ScannedQty =
                              scannedLine.Quantity;
                            orderDetail.ShipmentLines.ShipmentLine[
                              i
                            ].WarrantyComponents.ShipmentLine[
                              w
                            ].GCPickScanSkuResult = warrantyPOSScanResult;
                          } else {
                            isSuccessByQuantity = false;
                            hideActionLoader();
                            showGeneralErrorModel(
                              "Warranty not found in Scan Result",
                              ""
                            );
                            oneScanDone = true;
                          }
                        }
                      } else {
                        const warrantyPOSScanResult =
                          Utils.getWarrantyPOSScanResult(
                            lines,
                            orderDetail.ShipmentLines.ShipmentLine[i]
                              .WarrantyComponents?.ShipmentLine?.ShipmentLineKey
                          );
                        if (
                          warrantyPOSScanResult &&
                          warrantyPOSScanResult.SKUNumber &&
                          warrantyPOSScanResult.SKUNumber != ""
                        ) {
                          orderDetail.ShipmentLines.ShipmentLine[
                            i
                          ].WarrantyComponents.ShipmentLine.IsScanned = true;
                          orderDetail.ShipmentLines.ShipmentLine[
                            i
                          ].WarrantyComponents.ShipmentLine.ScannedQty =
                            scannedLine.Quantity;
                          orderDetail.ShipmentLines.ShipmentLine[
                            i
                          ].WarrantyComponents.ShipmentLine.GCPickScanSkuResult =
                            warrantyPOSScanResult;
                        } else {
                          isSuccessByQuantity = false;
                          hideActionLoader();
                          showGeneralErrorModel(
                            "Warranty not found in Scan Result",
                            ""
                          );
                          oneScanDone = true;
                        }
                      }
                    } else {
                      isSuccessByQuantity = false;
                      hideActionLoader();
                      showGeneralErrorModel(
                        "Warranty not found in Scan Result",
                        ""
                      );
                      oneScanDone = true;
                    }
                  }
                  if (
                    orderDetail.ShipmentLines.ShipmentLine[i].hasOwnProperty(
                      "CustomComponents"
                    )
                  ) {
                    // for custom component same as main component

                    for (
                      let j = 0;
                      j <
                      orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents
                        .ShipmentLine.length;
                      j++
                    ) {
                      if (
                        Utils.customComponentShipmentLineNeedScanning(
                          orderDetail.ShipmentLines.ShipmentLine[i]
                            .CustomComponents.ShipmentLine[j]
                        )
                      ) {
                        if (
                          orderDetail.ShipmentLines.ShipmentLine[i]
                            .CustomComponents.ShipmentLine[j].ShipmentLineKey ==
                          scannedLine.ShipmentLineKey
                        ) {
                          let POSScanResult = Utils.getPocScanResult(
                            lines,
                            scannedLine
                          ); // add error check
                          let totatQtyScanned = 0;
                          //checkSkuOlderOrder(orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents.ShipmentLine[j].ItemID);
                          if (
                            orderDetail?.ShipmentLines?.ShipmentLine[i]
                              ?.CustomComponents?.ShipmentLine[j]?.ScannedQty
                          ) {
                            totatQtyScanned = Number(
                              orderDetail?.ShipmentLines?.ShipmentLine[i]
                                ?.CustomComponents?.ShipmentLine[j]?.ScannedQty
                            );
                          }
                          totatQtyScanned =
                            totatQtyScanned + Number(quantityPicked);
                          if (
                            Number(totatQtyScanned) <=
                            Number(
                              orderDetail.ShipmentLines.ShipmentLine[i]
                                .CustomComponents.ShipmentLine[j].Quantity
                            )
                          ) {
                            orderDetail.ShipmentLines.ShipmentLine[
                              i
                            ].CustomComponents.ShipmentLine[j].IsScanned = true;
                            orderDetail.ShipmentLines.ShipmentLine[
                              i
                            ].CustomComponents.ShipmentLine[j].ScannedQty =
                              totatQtyScanned;
                            orderDetail.ShipmentLines.ShipmentLine[
                              i
                            ].CustomComponents.ShipmentLine[
                              j
                            ].GCPickScanSkuResult = POSScanResult;
                            if (
                              pickedItems.filter(
                                (a: any) =>
                                  a.skuToScan == skuToScan &&
                                  a.shipmentLineKey ==
                                    orderDetail.ShipmentLines.ShipmentLine[i]
                                      .CustomComponents.ShipmentLine[j]
                                      .ShipmentLineKey
                              ).length == 0
                            ) {
                              pickedItems.push({
                                skuToScan: skuToScan,
                                Quantity: totatQtyScanned,
                                SKUNumber: POSScanResult.SKUNumber,
                                shipmentLineKey:
                                  orderDetail.ShipmentLines.ShipmentLine[i]
                                    .CustomComponents.ShipmentLine[j]
                                    .ShipmentLineKey,
                                ItemDesc: POSScanResult.POSItemDesc,
                                posItemId: POSScanResult.POSItemID,
                              });
                              setPickedItems(pickedItems);
                              oneScanDone = true;
                            } else {
                              for (let p = 0; p < pickedItems.length; p++) {
                                if (pickedItems[p].skuToScan == skuToScan) {
                                  pickedItems[p].Quantity = totatQtyScanned;
                                  setPickedItems(pickedItems);
                                  oneScanDone = true;
                                }
                              }
                            }
                          } else {
                            isSuccessByQuantity = false;
                            hideActionLoader();
                            showGeneralErrorModel(
                              "Invalid Picked Quantity",
                              ""
                            );
                            oneScanDone = true;
                          }
                        }
                        if (
                          orderDetail.ShipmentLines.ShipmentLine[
                            i
                          ].CustomComponents.ShipmentLine[j].hasOwnProperty(
                            "WarrantyComponents"
                          )
                        ) {
                          if (isWarrentyinScanResult) {
                            if (
                              Array.isArray(
                                orderDetail.ShipmentLines.ShipmentLine[i]
                                  .CustomComponents.ShipmentLine[j]
                                  .WarrantyComponents?.ShipmentLine
                              )
                            ) {
                              for (
                                let w = 0;
                                w <
                                orderDetail.ShipmentLines.ShipmentLine[i]
                                  .CustomComponents.ShipmentLine[j]
                                  .WarrantyComponents?.ShipmentLine.length;
                                w++
                              ) {
                                const warrantyPOSScanResult =
                                  Utils.getWarrantyPOSScanResult(
                                    lines,
                                    orderDetail.ShipmentLines.ShipmentLine[i]
                                      .CustomComponents.ShipmentLine[j]
                                      .WarrantyComponents?.ShipmentLine[w]
                                      ?.ShipmentLineKey
                                  );
                                if (
                                  warrantyPOSScanResult &&
                                  warrantyPOSScanResult.SKUNumber &&
                                  warrantyPOSScanResult.SKUNumber != ""
                                ) {
                                  orderDetail.ShipmentLines.ShipmentLine[
                                    i
                                  ].CustomComponents.ShipmentLine[
                                    j
                                  ].WarrantyComponents.ShipmentLine[
                                    w
                                  ].IsScanned = true;
                                  orderDetail.ShipmentLines.ShipmentLine[
                                    i
                                  ].CustomComponents.ShipmentLine[
                                    j
                                  ].WarrantyComponents.ShipmentLine[
                                    w
                                  ].ScannedQty = scannedLine.Quantity;
                                  orderDetail.ShipmentLines.ShipmentLine[
                                    i
                                  ].CustomComponents.ShipmentLine[
                                    j
                                  ].WarrantyComponents.ShipmentLine[
                                    w
                                  ].GCPickScanSkuResult = warrantyPOSScanResult;
                                } else {
                                  isSuccessByQuantity = false;
                                  hideActionLoader();
                                  showGeneralErrorModel(
                                    "Warranty not found in Scan Result",
                                    ""
                                  );
                                  oneScanDone = true;
                                }
                              }
                            } else {
                              const warrantyPOSScanResult =
                                Utils.getWarrantyPOSScanResult(
                                  lines,
                                  orderDetail.ShipmentLines.ShipmentLine[i]
                                    .CustomComponents.ShipmentLine[j]
                                    .WarrantyComponents?.ShipmentLine
                                    ?.ShipmentLineKey
                                );
                              if (
                                warrantyPOSScanResult &&
                                warrantyPOSScanResult.SKUNumber &&
                                warrantyPOSScanResult.SKUNumber != ""
                              ) {
                                orderDetail.ShipmentLines.ShipmentLine[
                                  i
                                ].CustomComponents.ShipmentLine[
                                  j
                                ].WarrantyComponents.ShipmentLine.IsScanned =
                                  true;
                                orderDetail.ShipmentLines.ShipmentLine[
                                  i
                                ].CustomComponents.ShipmentLine[
                                  j
                                ].WarrantyComponents.ShipmentLine.ScannedQty =
                                  scannedLine.Quantity;
                                orderDetail.ShipmentLines.ShipmentLine[
                                  i
                                ].CustomComponents.ShipmentLine[
                                  j
                                ].WarrantyComponents.ShipmentLine.GCPickScanSkuResult =
                                  warrantyPOSScanResult;
                              } else {
                                isSuccessByQuantity = false;
                                hideActionLoader();
                                showGeneralErrorModel(
                                  "Warranty not found in Scan Result",
                                  ""
                                );
                                oneScanDone = true;
                              }
                            }
                          } else {
                            isSuccessByQuantity = false;
                            hideActionLoader();
                            showGeneralErrorModel(
                              "Warranty not found in Scan Result",
                              ""
                            );
                            oneScanDone = true;
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
            setScannedQty("1");
            setSKUNumberForScan("");
            if (isSuccessByQuantity) {
              dispatch(
                allActions.pickOrderActions.changeOMSOrderDetails(orderDetail)
              );
            }

            hideActionLoader();
          } catch {
            hideActionLoader();
            if (errorMessage == "") {
              errorMessage = LabelText.generalErrorMessage;
            }
            showGeneralErrorModel(
              errorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_SCAN_SKU
            );
          }
        } else {
          if (errorMessage != "" && errorMessage != undefined) {
            hideActionLoader();
            showGeneralErrorModel(
              errorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_SCAN_SKU
            );
          } else {
            hideActionLoader();
            showGeneralErrorModel(
              LabelText.generalErrorMessage,
              Constants.REPORT_ERROR_ACTIONS.PICK_SCAN_SKU
            );
          }
        }
      } else if (
        responseData?.Shipment?.length > 0 &&
        responseData?.Shipment[0]?.ValidationError
      ) {
        const validationError = responseData?.Shipment[0]?.ValidationError;
        hideActionLoader();
        showGeneralErrorModel(validationError, "");
      } else {
        hideActionLoader();
        showGeneralErrorModel(`Scanned item not found "${ScanNo}"`, "");
      }
    } else {
      hideActionLoader();
      showGeneralErrorModel(
        LabelText.generalErrorMessage,
        Constants.REPORT_ERROR_ACTIONS.PICK_SCAN_SKU
      );
    }
  };
  const cancelPickScan = (shipmentLineKey: any) => {
    for (let i = 0; i < orderDetail.ShipmentLines.ShipmentLine.length; i++) {
      if (
        orderDetail.ShipmentLines.ShipmentLine[i].ShipmentLineKey ==
        shipmentLineKey
      ) {
        orderDetail.ShipmentLines.ShipmentLine[i].IsScanned = false;
        orderDetail.ShipmentLines.ShipmentLine[i].ScannedQty = 0;
        orderDetail.ShipmentLines.ShipmentLine[i].GCPickScanSkuResult =
          {} as any;
      } else {
        if (
          orderDetail.ShipmentLines.ShipmentLine[i].hasOwnProperty(
            "CustomComponents"
          )
        ) {
          for (
            let j = 0;
            j <
            orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents
              .ShipmentLine.length;
            j++
          ) {
            if (
              orderDetail.ShipmentLines.ShipmentLine[i].CustomComponents
                .ShipmentLine[j].ShipmentLineKey == shipmentLineKey
            ) {
              orderDetail.ShipmentLines.ShipmentLine[
                i
              ].CustomComponents.ShipmentLine[j].IsScanned = false;
              orderDetail.ShipmentLines.ShipmentLine[
                i
              ].CustomComponents.ShipmentLine[j].ScannedQty = 0;
              orderDetail.ShipmentLines.ShipmentLine[
                i
              ].CustomComponents.ShipmentLine[j].GCPickScanSkuResult =
                {} as any;
            }
          }
        }
      }
    }
    let filtered = pickedItems.filter((scanned: any) => {
      return scanned.shipmentLineKey != shipmentLineKey;
    });
    setPickedItems(filtered);
    dispatch(allActions.pickOrderActions.changeOMSOrderDetails(orderDetail));
  };

  const GCPickConfirm = async (onHold?: any) => {
    let response: any = {};

    try {
      showActionLoader();
      //response = await OMSManager.GCPickConfirm(orderDetail,onHold);
      if (orderDetail.Status == ShipmentStatusConstants.OnHold) {
        response = await OMSManager.GCOnHoldPickConfirm(orderDetail);
      } else {
        response = await OMSManager.GCPickConfirm(orderDetail, onHold);
      }
    } catch (error) {
      //
      showGeneralErrorModel(
        LabelText.generalErrorMessage,
        Constants.REPORT_ERROR_ACTIONS.PICK_GC_CNFRM
      );
      hideActionLoader();
      return;
    }

    if (response.success && response.data) {
      await OrderManager.saveOrderHistory({
        shipmentKey: shipmentkey,
        logType: "history",
        transactionType: onHold
          ? Constants.OrderHistoryTypes.OnHold
          : Constants.OrderHistoryTypes.Picking,
        message: notes,
        transactionDate: moment
          .utc(moment.tz("America/Los_Angeles"))
          .toISOString(),
      });
      if (onHold) {
        setonHoldComplete(true);
      } else {
        setPickComplete(true);
      }
      hideActionLoader();
      //navigateToFulfillment();
    } else {
      let errorMsg = "";
      hideActionLoader();
      //showGeneralErrorModel(LabelText.generalErrorMessage);
      if (!response.success && response.msg) {
        errorMsg = response.msg;
      } else {
        errorMsg = LabelText.generalErrorMessage;
      }
      showGeneralErrorModel(
        errorMsg,
        Constants.REPORT_ERROR_ACTIONS.PICK_GC_CNFRM
      );
    }
  };

  let pickCompleteButtons: IButton[] = [
    {
      text: "Pick Another",
      icon: <ShoppingCart />,
      action: navigateToFulfillment,
      color: Constants.Colors.red,
    },
    {
      text:
        navigateTo == Constants.OrderFlowConstants.PICK_PICKUP
          ? "Continue to Pickup"
          : "Continue to Pack",
      icon:
        navigateTo == Constants.OrderFlowConstants.PICK_PICKUP ? (
          <DirectionsCar />
        ) : (
          <ApartmentIcon />
        ),
      action:
        navigateTo == Constants.OrderFlowConstants.PICK_PICKUP
          ? toPickup
          : toPack,
      color: Constants.Colors.red,
    },
  ];
  let pickCompleteDialog: INotificationPopupSettings = {
    open: pickComplete,
    type: Constants.NotificationPopupType.Success,
    title: LabelText.NotificationPopupHeaders.Success,
    msg: LabelText.PickOrder.PickCompleteSuccess,
    handleClose: closePickComplete,
    actions: pickCompleteButtons,
  };

  let resourcePopupButtons: IButton[] = [];
  let resourcePopup: INotificationPopupSettings = {
    open: resource,
    type: Constants.NotificationPopupType.Info,
    title: LabelText.NotificationPopupHeaders.Resource,
    msg: LabelText.PickOrder.PickResourceOrderWarning,
    handleClose: closeResource,
    isCustomJSX: true,
    customJSX: (
      <ResourceItem
        closeResource={closeResource}
        setSuccess={setSuccess}
        shipmentKey={shipmentkey}
        fulfillmentType={orderDetails.fulfillmentType}
      ></ResourceItem>
    ),
    actions: resourcePopupButtons,
    fullWidth: true,
    maxWidth: "md",
  };

  let contactCustomerSettings: IContactCustomer = {
    customerEmail: "customer.email@mail.com",
    storeEmail: "storemail.email@mail.com",
    template: LabelText.MAILTEMPLATES.notOriginalPackaging,
    templateSubject: LabelText.MAILSUBJECTS.notOriginalPackaging,
  };
  let contactCustomerPopupButtons: IButton[] = [
    {
      text: "Send",
      icon: <SendIcon />,
      action: saveContactCustomer,
      color: Constants.Colors.white,
      border: Constants.Colors.red,
      textColor: Constants.Colors.red,
    },
    {
      text: "View Template",
      icon: "",
      action: viewEmailTemplate,
      color: Constants.Colors.blue,
    },
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeContactCustomer,
      // color: Constants.Colors.red,
      color: Constants.Colors.white,
      border: Constants.Colors.red,
      textColor: Constants.Colors.red,
    },
  ];
  let contactCustomerPopup: INotificationPopupSettings = {
    open: contactCustomer,
    type: Constants.NotificationPopupType.Info,
    title: LabelText.PickOrder.ContactCustomerTitle,
    msg: "",
    handleClose: closeContactCustomer,
    isCustomJSX: true,
    customJSX: <ContactCustomer store={props.store}></ContactCustomer>,
    actions: contactCustomerPopupButtons,
    fullWidth: true,
    maxWidth: "lg",
  };
  const addNotes = () => {
    if (notes != "") {
      let index = Utils.getStepperIndex(verticalStepperOptions, "Picking");
      step.splice(index, 0, {
        label: "Note",
        description: notes,
        time: "Jan 20 2022 13:11:17 ",
      });
      verticalStepperOptions.stepsOptions = step;
      setSteps(verticalStepperOptions);
    }
    setNotesPopup(false);
  };
  let notesPopupButtons: IButton[] = [
    {
      text: "Add",
      icon: <AddCommentIcon />,
      action: addNotes,
      color: Constants.Colors.blue,
    },
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeNotes,
      // color: Constants.Colors.grey,
      color: Constants.Colors.white,
      border: Constants.Colors.grey,
      textColor: Constants.Colors.grey,
    },
  ];
  let notesCustomerPopup: INotificationPopupSettings = {
    open: notePopup,
    type: Constants.NotificationPopupType.Info,
    title: LabelText.PickOrder.Note,
    msg: "",
    handleClose: closeNotes,
    isCustomJSX: true,
    customJSX: <Note note={notes} changeNote={changeNote}></Note>,
    actions: notesPopupButtons,
  };

  const closePdfiframe = () => {
    SetShowPdf(false);
  };

  const closeSuccess = () => {
    setSuccess(false);
    openLoadingOrderLines();
    getPickDetailFromOms();
  };

  let successPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeSuccess,
      // color: Constants.Colors.grey,
      color: Constants.Colors.white,
      border: Constants.Colors.grey,
      textColor: Constants.Colors.grey,
    },
    {
      text: "To Fulfillment List",
      icon: <DoneIcon />,
      action: navigateToFulfillment,
      color: Constants.Colors.red,
    },
  ];
  let success: INotificationPopupSettings = {
    open: openSuccess,
    type: Constants.NotificationPopupType.Success,
    title: "Success",
    msg: "Order Resource Complete",
    draggable: false,
    handleClose: closeSuccess,
    actions: successPopupButtons,
  };

  let navigateToFulfillOrdersButtons: IButton[] = [
    {
      text: "To Fulfillment List",
      icon: <DoneIcon />,
      action: navigateToFulfillment,
      color: Constants.Colors.red,
    },
  ];

  let navigateToFulfillOrders: INotificationPopupSettings = {
    open: navigationFulfillment,
    type: Constants.NotificationPopupType.Info,
    title: "",
    msg: "This Order doesn't belong to selected Store.",
    draggable: false,
    handleClose: navigateToFulfillment,
    actions: navigateToFulfillOrdersButtons,
  };

  let onHoldDialog: INotificationPopupSettings = {
    open: onHoldComplete,
    type: Constants.NotificationPopupType.Success,
    title: LabelText.NotificationPopupHeaders.Success,
    msg: "Order is On-Hold.",
    draggable: false,
    handleClose: navigateToFulfillment,
    actions: navigateToFulfillOrdersButtons,
  };

  const closeImageViewer = () => {
    setImagePopUp(false);
    setImageData({} as IImageViewerDetail);
  };

  let imageViewerSettings: IImageViewerPopupSettings = {
    open: imagePopUp,
    imageDetail: imageData,
    close: closeImageViewer,
  };

  const getPickDetailFromOms = async () => {
    let result = [] as any;
    try {
      // Get Email Templates
      ContactCustomerManager.getEmailTemplates().then((result) => {
        dispatch(
          allActions.contactCustomerActions.changeEmailTemplates(
            result?.data?.response?.data
          )
        );
      });
      result = await OMSManager.getPickOrdersDetail(shipmentkey);
      console.log("Result Oms", result);
      if (
        result.success &&
        result.data.ShipmentLines != undefined &&
        result.data.ShipmentLines.ShipmentLine != undefined
      ) {
        let data = result.data;
        setOrderNumber(data.ShipmentLines.ShipmentLine[0].OrderNo);
        let isAnyBulkOrder = false;
        data.ShipmentLines.ShipmentLine.forEach((line: any) => {
          if (line.hasOwnProperty("CustomComponents")) {
            isAnyBulkOrder = true;
          }
        });
        let orderDetails = {
          fulfillmentType: data?.FulfillmentType,
          levelOfService: data?.LevelOfService,
          pickUpStore: data?.Extn?.ExtnPickupStoreID,
          storeNumber: data?.ShipNode?.ShipNode,
        };
        setOrderDetails(orderDetails);

        data.isAnyBulkOrder = isAnyBulkOrder;
        dispatch(allActions.pickOrderActions.changeOMSOrderDetails(data));
        dispatch(
          allActions.pickOrderActions.changeCommonCodeReasons(
            data?.CommonCodeList?.CommonCode
          )
        );
        if (!Utils.customerInfoFromElasticOrNot()) {
          dispatch(
            allActions.pickOrderActions.changeCustomerDetails(
              OMSManager.getCustomerDetails(data)
            )
          );
        }
        if (Utils.customerInfoFromElasticOrNot()) {
          try {
            let response = await OMSManager.getCustomerDetailsFromElastic(
              shipmentkey
            );
            const data =
              response?.data?.hits?.hits[0]?._source.Shipment?.CustomerInfo;
            dispatch(
              allActions.pickOrderActions.changeCustomerDetails(
                OMSManager.getCustomerDetails(data)
              )
            );
          } catch (error) {
            console.log(error);
            throw error;
          }
        }

        closeLoadingOrderLines();
        if (data.CommonCodeList == undefined) {
          setErrorMsg(LabelText.noCommonCodeListError);
          setErrorReportMsg(LabelText.noCommonCodeListError);
          setReportErrorAction(
            Constants.REPORT_ERROR_ACTIONS.PICK_DETAILS_FROM_OMS
          );

          openErrorPopup();
        }
      } else {
        let message = "";
        if (result.success && result.data.Status == "9000") {
          message = "This Order has been canceled";
        } else if (!result.success && result.msg) {
          message = result.msg;
        } else if (
          (result.success && result.data?.IsShipmentListEmpty == "Y") ||
          (result.success && !result.data.ShipmentLines.ShipmentLine)
        ) {
          message = LabelText.noShipmentLines;
        } else {
          message = LabelText.generalErrorMessage;
        }
        showPickDetailErrorCommon(message);
        setErrorReportMsg(message);
        setReportErrorAction(
          Constants.REPORT_ERROR_ACTIONS.PICK_DETAILS_FROM_OMS
        );
        closeLoadingOrderLines();
      }
    } catch {
      closeLoadingOrderLines();
      showPickDetailErrorCommon(LabelText.generalErrorMessage);
      setErrorReportMsg(LabelText.generalErrorMessage);
      setReportErrorAction(
        Constants.REPORT_ERROR_ACTIONS.PICK_DETAILS_FROM_OMS
      );
    }
  };

  const initScandit = async () => {
    try {
      await configure({
        licenseKey: licenseKey,
        libraryLocation: new URL(
          "./scandit/build/",
          document.baseURI
        ).toString(),
        moduleLoaders: [barcodeCaptureLoader()],
      });
      let _view = new DataCaptureView();
      let _context: DataCaptureContext = await DataCaptureContext.create();
      //Set Camera Settings
      const _camera: Camera = Camera.default;
      const cameraSettings = BarcodeTracking.recommendedCameraSettings;
      await _camera.applySettings(cameraSettings);
      await _context.setFrameSource(_camera);
      // Bar Code Settings
      const settings: BarcodeTrackingSettings =
        BarcodeTrackingSettings.forScenario(BarcodeTrackingScenario.A);
      settings.enableSymbologies([
        Symbology.EAN13UPCA,
        Symbology.EAN8,
        Symbology.UPCE,
        Symbology.Code39,
        Symbology.Code128,
      ]);
      const symbologyCode39Settings: SymbologySettings =
        settings.settingsForSymbology(Symbology.Code39);
      symbologyCode39Settings.activeSymbolCounts = [
        7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];
      const symbologyCode128Settings: SymbologySettings =
        settings.settingsForSymbology(Symbology.Code128);
      symbologyCode128Settings.activeSymbolCounts = [
        7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];
      const symbologyUPCASettings: SymbologySettings =
        settings.settingsForSymbology(Symbology.EAN13UPCA);
      symbologyUPCASettings.setExtensionEnabled(
        "remove_leading_upca_zero",
        true
      );
      // Bind bar code dependencies
      const _barcodeTracking = await BarcodeTracking.forContext(
        _context,
        settings
      );
      await _barcodeTracking.setEnabled(false);
      const _barcodeTrackingOverlay: BarcodeTrackingBasicOverlay =
        await BarcodeTrackingBasicOverlay.withBarcodeTrackingForView(
          _barcodeTracking,
          _view
        );
      setScanditContext(_context);
      setScanditView(_view);
      setCamera(_camera);
      setBarcodeTracking(_barcodeTracking);
      setBarCodeOverlay(_barcodeTrackingOverlay);
      setLoadingScanner(false);
    } catch (err: any) {
      console.error("Scandit Error", err);
      setLoadingScanner(false);
    }
  };

  const showPickDetailErrorCommon = async (msg: string) => {
    setIsError(true);
    setErrorMsg(msg);
    setErrorReportMsg(msg);
    openErrorPopup();
  };

  const showGeneralErrorModel = async (msg: any, action: any) => {
    setOpenGeneralError(true);
    setErrorGeneralMsg(msg);
    setErrorReportMsg(msg);
    setReportErrorAction(action);
  };

  const closeGeneralErrorModel = () => {
    setOpenGeneralError(false);
    setErrorGeneralMsg("");
  };

  const closeErrorPopup = () => {
    setOpenError(false);
  };
  const openErrorPopup = () => {
    setOpenError(true);
  };
  const popUpBack = () => {
    navigate(-1);
  };

  const closeErrorReportPopup = () => {
    setOpenReportError(false);
  };

  // Show Pop ups If Error is already reported
  const handleErrorAlreadyReported = () => {
    // setOpenAlreadyReportedError(true);
    setOpenReportError(false);
  };
  // Report Error
  const reportConfirmErrorPopup = () => {
    setOpenConfirmReportError(true);
    setOpenGeneralError(false);
    setOpenError(false);
  };
  // const getReportErrorPopupData = async () => {

  //     // Save Report Error Object
  //     const reportErrorDataObj = {
  //         reportErrorDescription,
  //         reportErrorShortDescription,
  //         reportErrorPriority,
  //         reportErrorImpact,
  //         reportErrorUrgency
  //     }
  //     // Check If Error Is already Reported
  //     try {
  //         let reportErrorHistory: any = []
  //         let isErrorAlreadyReported: boolean = false;
  //         await ApiService.get("/pf/getReportErrorHistory?shipmentKey=" + shipmentkey + "&orderNo=" + orderFromUrl + "&action=" + pathname + "&upn=" + IdentityManager.getUserProperty(Constants.Preferred_UserName)).then((responseReportErrorHistory: any) => {
  //             reportErrorHistory = responseReportErrorHistory.data.hits.hits;
  //             return reportErrorHistory;
  //         });

  //         if (reportErrorHistory.length > 0) {
  //             for (const item of reportErrorHistory) {
  //                 if (
  //                     item._source.shipmentKey === shipmentkey &&
  //                     item._source.upn === IdentityManager.getUserProperty(Constants.Preferred_UserName) &&
  //                     item._source.orderno === orderFromUrl &&
  //                     item._source.message === errorReportMsg &&
  //                     item._source.userId === IdentityManager.getUserProperty(Constants.EmployeeID)
  //                 ) {
  //                     isErrorAlreadyReported = true;
  //                 }
  //             }
  //             if (isErrorAlreadyReported) {
  //                 setOpenAlreadyReportedError(true);
  //                 setOpenReportError(false);

  //             } else {
  //                 // Report Error Service Now (Call API)

  //                 // Save History
  //                 try {
  //                     const orderHistoryResponse = await OrderManager.saveOrderHistory({
  //                         shipmentKey: shipmentkey,
  //                         orderNo: orderFromUrl,
  //                         logType: Constants.reportErrorHistory,
  //                         message: errorReportMsg,
  //                         action: pathname,
  //                         transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
  //                     });

  //                     if (orderHistoryResponse?.success) {
  //                         setSuccess(true)
  //                     }

  //                 } catch (error) {
  //                     setOpenError(true)
  //                 }

  //             }
  //         } else {
  //             // Report Error Service Now (Call API)

  //             // Object ForSave Order history
  //             // const orderHistoryResponse = await OrderManager.saveOrderHistory({
  //             //     shipmentKey: shipmentkey,
  //             //     orderNo: orderFromUrl,
  //             //     logType: "reportErrorHistory",
  //             //     transactionType: "",
  //             //     message: errorReportMsg,
  //             //     action: pathname,
  //             //     transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
  //             // });

  //         }

  //     } catch (error) {
  //         setOpenGeneralError(true);
  //         setErrorGeneralMsg(error as string);
  //         setOpenReportError(false);

  //     }

  // }
  let errorPopupButtons: IButton[] = [
    {
      text: "Report Error",
      icon: <ReportProblemIcon />,
      action: reportConfirmErrorPopup,
      color: Constants.Colors.orange,
    },
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeErrorPopup,
      // color: Constants.Colors.red,
      color: Constants.Colors.white,
      border: Constants.Colors.red,
      textColor: Constants.Colors.red,
    },
    {
      text: "Back",
      icon: <ArrowBackIcon />,
      action: popUpBack,
      color: Constants.Colors.red,
    },
  ];
  let Error: INotificationPopupSettings = {
    open: openError,
    type: Constants.NotificationPopupType.Error,
    title: "Error",
    msg: errorMsg,
    draggable: false,
    handleClose: closeErrorPopup,
    actions: errorPopupButtons,
  };

  let errorGeneralPopupCloseButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeGeneralErrorModel,
      // color: Constants.Colors.red,
      color: Constants.Colors.white,
      border: Constants.Colors.red,
      textColor: Constants.Colors.red,
    },
  ];

  let errorGeneralPopupButtons: IButton[] = [
    {
      text: "Report Error",
      icon: <ReportProblemIcon />,
      action: reportConfirmErrorPopup,
      color: Constants.Colors.red,
    },
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeGeneralErrorModel,
      // color: Constants.Colors.red,
      color: Constants.Colors.white,
      border: Constants.Colors.red,
      textColor: Constants.Colors.red,
    },
  ];

  if (
    Utils.getConfigurationWithKey(
      Constants.Configurations.ReportErrorSNFlag
    ) === false
  ) {
    errorGeneralPopupButtons.shift();
  }

  let GeneralError: INotificationPopupSettings = {
    open: openGeneralError,
    type: Constants.NotificationPopupType.Error,
    title: "Error",
    msg: errorGeneralMsg,
    draggable: false,
    handleClose: closeGeneralErrorModel,
    actions: reportErrorAction
      ? errorGeneralPopupButtons
      : errorGeneralPopupCloseButtons,
  };

  // Report Error Pop up
  let ReportError: INotificationPopupSettings = {
    open: openReportError,
    type: Constants.NotificationPopupType.Conformation,
    title: "Report Error",
    msg: "",
    isCustomJSX: true,
    customJSX: (
      <ReportErrorPopup
        handleErrorAlreadyReported={handleErrorAlreadyReported}
        errorMessage={errorReportMsg}
        closeErrorReportPopup={closeErrorReportPopup}
        urlPathname={pathname}
        reportErrorAction={reportErrorAction}
      ></ReportErrorPopup>
    ),
    draggable: false,
    handleClose: closeErrorReportPopup,
    actions: [],
    fullWidth: true,
    maxWidth: "lg",
  };

  // Confirm Report Error
  const closeConfirmReportError = () => {
    setOpenConfirmReportError(false);
  };

  const reportErrorPopup = () => {
    setOpenReportError(true);
    setOpenConfirmReportError(false);
  };

  let confirmReportErrorPopupButtons: IButton[] = [
    {
      text: "Continue",
      icon: <ArrowForwardIcon />,
      action: reportErrorPopup,
      color: Constants.Colors.red,
    },
  ];
  let confirmReportError: INotificationPopupSettings = {
    open: openConfirmReportError,
    type: Constants.NotificationPopupType.Conformation,
    title: Constants.MESSAGES.SN_CNFRM_TITLE,
    msg: "",
    customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
    isCustomJSX: true,
    draggable: false,
    handleClose: closeConfirmReportError,
    actions: confirmReportErrorPopupButtons,
  };

  //Remove Unneccassary Fileds from ShipmentLine
  const removeFields = (shipmentLine: any) => {
    const fieldsToKeep = ["ItemID", "ItemDesc", "OrderLine", "ImageUrl"];

    return shipmentLine?.map((obj: any) => {
      const newObj: any = {};
      fieldsToKeep.forEach((field) => {
        if (
          field === "OrderLine" &&
          typeof obj[field] === "object" &&
          obj[field] !== null
        ) {
          // Extract FulfillmentType from OrderLine object
          newObj["FulfillmentType"] = obj[field]?.FulfillmentType;
          newObj["ExtnPOSItemID"] =
            obj[field]?.ItemDetails?.Extn?.ExtnPOSItemID;
        } else {
          newObj[field] = obj[field];
        }
      });
      return newObj;
    });
  };

  //Open Box Feature Dialog
  const closeBoxFeature = () => {
    setOpenBoxFeature(false);
  };
  let boxFeaturePopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeBoxFeature,
      // color: Constants.Colors.red,
      color: Constants.Colors.white,
      border: Constants.Colors.red,
      textColor: Constants.Colors.red,
    },
  ];
  let boxFeaturePopup: INotificationPopupSettings = {
    open: openBoxFeature,
    type: Constants.NotificationPopupType.Info,
    title: LabelText.PickOrder.BoxTitle,
    msg: "",
    handleClose: closeBoxFeature,
    isCustomJSX: true,
    customJSX: (
      <PickBoxPopup
        GCPickConfirm={GCPickConfirm}
        shipmentKey={shipmentkey}
        ShipmentLine={removeFields(orderDetail?.ShipmentLines?.ShipmentLine)}
        orderNumber={orderFromUrl}
      ></PickBoxPopup>
    ),
    actions: [],
    fullWidth: true,
    maxWidth: "lg",
  };

  const scanditCleanUp = async () => {
    await context?.frameSource?.switchToDesiredState(FrameSourceState.Off);
    context?.dispose();
    context?.removeAllModes();
    if (barCodeOverlay) {
      await scanditView?.removeOverlay(barCodeOverlay);
    }
  };

  React.useEffect(() => {
    async function getPickDetail() {
      if (Utils.isMobile) {
        initScandit();
      }
      await getPickDetailFromOms();
      await ApiService.get(
        `/pf/getOrderFromExStore?shipmentKey=` + shipmentkey
      ).then((response: any) => {
        let orderDetail =
          response.data.hits.hits.length > 0
            ? response.data.hits.hits[0]._source.Shipment
            : [];
        setOrderDetailsFromElastic(orderDetail);
        let navigationDecision = Utils.OrderFlow(orderDetail, false, null);
        setIsResourced(Utils.isResourcedOrder(orderDetail));
        setComissionAssociateIds(Utils.getCommissionEmployeeIds(orderDetail));
        setNavigateTo(navigationDecision);
      });
    }
    getPickDetail();
    return () => {
      if (Utils.isMobile) {
        scanditCleanUp();
      }
    };
  }, []);

  React.useEffect(() => {
    if (
      queryParamStore &&
      props.store?.length == 1 &&
      props.store[0].id != queryParamStore
    ) {
      openNavigationFulfillment();
    }
  }, [props.store]);

  const getEnableConformPick = () => {
    let nonScannedItems = [];
    let enable = false;
    if (orderDetail.Status == ShipmentStatusConstants.OnHold) {
      return true;
    }
    if (orderDetail.isAnyBulkOrder) {
      if (orderDetail && orderDetail?.ShipmentLines) {
        orderDetail.ShipmentLines.ShipmentLine.forEach((line: ShipmentLine) => {
          if (line.hasOwnProperty("CustomComponents")) {
            if (line.CustomComponents.ShipmentLine.length > 0) {
              line.CustomComponents.ShipmentLine.forEach(
                (customLine: ShipmentLine) => {
                  if (
                    customLine.IsScanned == undefined ||
                    customLine.IsScanned == false ||
                    Number(customLine.ScannedQty) != Number(customLine.Quantity)
                  ) {
                    nonScannedItems.push(true);
                  }
                }
              );
            }
          } else {
            if (
              line.IsScanned == undefined ||
              line.IsScanned == false ||
              Number(line.ScannedQty) != Number(line.Quantity)
            ) {
              nonScannedItems.push(true);
            }
          }
        });
        enable = nonScannedItems.length > 0 ? false : true;
      }
    } else {
      if (orderDetail && orderDetail?.ShipmentLines) {
        nonScannedItems = orderDetail?.ShipmentLines?.ShipmentLine?.filter(
          (a: ShipmentLine) => {
            return (
              !a.IsScanned || a.IsScanned == false || a.ScannedQty != a.Quantity
            );
          }
        );
        enable = nonScannedItems.length > 0 ? false : true;
      }
    }

    return enable;
  };

  const handleScan = (event: any) => {
    let barCode = event.target.value;
    setScannedQty("1");
    setSKUNumberForScan(barCode);
    setZebraScanner(true);
    setRandomInputValue(barCode);
    inputRef.current.value = "";
  };

  const openScandit = () => {
    setSKUNumberForScan("");
    setScan(true);
  };

  React.useEffect(() => {
    const handleZebraScan = async () => {
      if (Utils.isMobile && zebraScanner && skuToScan && !isScan) {
        await scanItemId();
        setZebraScanner(false);
      }
    };
    handleZebraScan();
  }, [zebraScanner, skuToScan]);

  useEffect(() => {
    if (!Utils.isMobile) return;

    const focusInput = () => {
      //Check if any input pop up is open or not
      if (
        inputRef.current &&
        !resource &&
        !storesPopUp &&
        !props.searchPopup &&
        !openBoxFeature &&
        !openReportError &&
        !openConfirmReportError &&
        !contactCustomer &&
        !notePopup &&
        !showModal &&
        !notesPopup &&
        !isScan
      ) {
        inputRef.current.focus();
      }
    };

    focusInput();

    // Refocus input every second
    const interval = setInterval(() => {
      focusInput();
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [
    resource,
    storesPopUp,
    props.searchPopup,
    openBoxFeature,
    openReportError,
    openConfirmReportError,
    contactCustomer,
    notePopup,
    showModal,
    notesPopup,
    isScan,
  ]);

  const mobileView = () => {
    let enableConfirmPick = getEnableConformPick();
    const height = Utils.displayHeight + "px";
    const loaderLeft = Utils.isMobile ? "42%" : "48%";
    return (
      <div className="landscape">
        {showLoader ? (
          <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
            <TailSpin color="#D93A2F" height={80} width={80} />
          </div>
        ) : (
          ""
        )}
        {
          <>
            {/* <div hidden={!isScan}>
                            <MobileBarCodeScannerScandit isScan={isScan} containerkey={Utils.generateRandom(
                        10,
                        Constants.CryptoRandomTypes.numeric
                    )} scannedQty={scannedQty} setScannedQty={setScannedQty} onScanned={onScannedComplete} setSKUNumberForScan={setSKUNumberForScan} skuToScan={skuToScan} />
                </div> */}
            {isScan ? (
              <MobileBarCodeMatrixScan
                isScan={isScan}
                scannedQty={scannedQty}
                setScannedQty={setScannedQty}
                onScanned={onScannedComplete}
                setSKUNumberForScan={setSKUNumberForScan}
                skuToScan={skuToScan}
                context={context}
                view={scanditView}
                camera={camera}
                barCodeTracking={barCodeTracking}
                barCodeOverlay={barCodeOverlay}
              />
            ) : (
              <div style={{ paddingBottom: "150px" }}>
                <Paper
                  style={{
                    marginTop: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                    background: "#F9F9F9",
                  }}
                >
                  <Grid
                    container
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    <Grid item xs={10} sm={10} className="textLeft">
                      <Grid container style={{ paddingLeft: "12px" }}>
                        <Grid item xs={3}>
                          <Button
                            onClick={() => navigateToFulfillment()}
                            className="back-button"
                            variant="contained"
                            startIcon={<ArrowBackIosNewOutlinedIcon />}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={9} style={{ paddingLeft: "10px" }}>
                          <div style={{ display: "flex" }}>
                            <Typography className="medium-heading">
                              Order #
                            </Typography>
                            <input
                              type="text"
                              name="zebraScanner"
                              inputMode="none"
                              ref={inputRef}
                              autoCorrect="off"
                              autoComplete="off"
                              autoCapitalize="off"
                              spellCheck={false}
                              autoFocus
                              onChange={handleScan}
                              style={{
                                opacity: 0,
                                top: 0,
                                left: 0,
                                width: "1px",
                                height: "1px",
                                border: "none",
                                outline: "none",
                              }}
                            />
                          </div>
                          <Typography
                            className="large-heading"
                            style={{ wordBreak: "break-all" }}
                          >
                            {orderNumber}
                          </Typography>
                          {orderDetail.Status ==
                            ShipmentStatusConstants.OnHold && (
                            <Chip
                              className="chipTracking"
                              label={Constants.FulfillOrdersDisplayNames.OnHold}
                              style={{
                                background: Constants.Colors.orange,
                                color: "white",
                                fontSize: "10px",
                                height: "24px",
                                borderRadius: "4px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                          {isResourced && (
                            <Chip
                              className="chipTracking"
                              label={
                                Constants.FulfillOrdersDisplayNames.Resourced
                              }
                              style={{
                                background: Constants.Colors.lightBlue,
                                color: "white",
                                fontSize: "10px",
                                height: "24px",
                                marginBottom: "5px",
                                borderRadius: "4px",
                                marginLeft:
                                  orderDetail.Status ==
                                  ShipmentStatusConstants.OnHold
                                    ? "5px"
                                    : "0px",
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      {Utils.disableUserAction(props.store) &&
                        !loadingOrderLines &&
                        orderDetail.Status !=
                          ShipmentStatusConstants.OnHold && (
                          <IconButton
                            onClick={() => {
                              openScandit();
                            }}
                            // onClick={() => { checkForCameraPersmission() }}
                            size="large"
                            color="inherit"
                            aria-label="menu"
                            disabled={loadingScanner}
                            style={{
                              color: loadingScanner
                                ? Constants.Colors.grey
                                : Constants.Colors.lightBlue,
                            }}
                          >
                            <DocumentScannerIcon
                              style={{
                                transform: "rotate(90deg)",
                                fontSize: "32px",
                              }}
                            />
                          </IconButton>
                        )}
                    </Grid>
                  </Grid>
                  {Utils.scannerTesting() && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ margin: 0, padding: 0 }}
                      >
                        <Card style={{ margin: 0, padding: 0 }}>
                          <CardContent style={{ margin: 0, padding: 0 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              style={{ margin: 0, padding: 0 }}
                            >
                              Zebra Scanner Input
                            </Typography>
                            <p
                              style={{
                                textAlign: "left",
                                margin: 0,
                                marginLeft: 5,
                                padding: 0,
                              }}
                            >
                              {randomInputValue}
                            </p>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                  {Utils.disableUserAction(props.store) && (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px", padding: "10px" }}
                    >
                      <Grid item xs={6} sm={6}>
                        <Button
                          onClick={() => contactCustomerClick()}
                          style={{ width: "100%" }}
                          className="header-button"
                          variant="contained"
                          startIcon={<EmailOutlinedIcon />}
                        >
                          Contact Cust.
                        </Button>
                        {/* {(Utils.onUnHold() && orderDetail.Status == ShipmentStatusConstants.OnHold) && <Button onClick={() => onUnHoldClick()} style={{ marginTop: "10px"}} className="btn" variant='contained' startIcon={<PlayArrowIcon />}>Un Hold</Button>} */}
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Button
                          onClick={() => cancelOrder()}
                          className="header-button underline"
                          style={{ width: "100%" }}
                          variant="contained"
                          startIcon={<CancelOutlinedIcon />}
                        >
                          Cancel Order
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {!loadingOrderLines ? (
                    <React.Fragment>
                      {isError == false ? (
                        <React.Fragment>
                          <React.Fragment>
                            {orderDetail.Status ==
                              ShipmentStatusConstants.OnHold && (
                              <Grid item xs={12} sm={12}></Grid>
                            )}
                          </React.Fragment>

                          <Accordion
                            defaultExpanded
                            style={{
                              margin: 5,
                              padding: 10,
                              borderRadius: 16,
                              boxShadow:
                                "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                color: Constants.Colors.darkGrey,
                                height: 80,
                              }}
                            >
                              <div className="topHeading">
                                <div
                                  className="gridHeading"
                                  style={{ display: "flex" }}
                                >
                                  <Typography> Order Lines</Typography>
                                </div>
                              </div>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails
                              style={{
                                display: "block",
                                padding: 0,
                                minHeight: "250px",
                                overflowY: "scroll",
                              }}
                            >
                              {orderDetail?.ShipmentLines?.ShipmentLine?.map(
                                (item: any, index: any) => {
                                  return (
                                    <>
                                      {/* MobileView Product Header */}
                                      <Grid
                                        container
                                        display={"flex"}
                                        style={{ marginTop: "10px" }}
                                        className="justifyBetween productHeader"
                                      >
                                        <div>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                          >
                                            <Grid
                                              item
                                              display={"flex"}
                                              style={{ marginTop: "8px" }}
                                            >
                                              <Typography
                                                sx={{ fontSize: 12 }}
                                                color="text.secondary"
                                              >
                                                Qty
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: 13,
                                                  fontWeight: 500,
                                                  marginLeft: 1,
                                                }}
                                              >
                                                {item.Quantity}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              display={"flex"}
                                              style={{ marginTop: "8px" }}
                                            >
                                              <Typography
                                                sx={{ fontSize: 12 }}
                                                color="text.secondary"
                                              >
                                                Remaining
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: 13,
                                                  fontWeight: 500,
                                                  marginLeft: 1,
                                                }}
                                                style={{}}
                                              >
                                                {item.KitCode == "" ? (
                                                  <React.Fragment>
                                                    {item.ScannedQty
                                                      ? Number(item.Quantity) -
                                                        Number(item.ScannedQty)
                                                      : item.Quantity}
                                                  </React.Fragment>
                                                ) : (
                                                  ""
                                                )}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              display={"flex"}
                                              className="textCenter"
                                              style={{ marginTop: "8px" }}
                                            >
                                              <Typography
                                                sx={{ fontSize: 12 }}
                                                color="text.secondary"
                                              >
                                                Status
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: 13,
                                                  fontWeight: 500,
                                                  marginLeft: 1,
                                                }}
                                              >
                                                {item.KitCode == "" ? (
                                                  <React.Fragment>
                                                    {item.IsScanned ? (
                                                      <p
                                                        style={{
                                                          color:
                                                            Number(
                                                              item.Quantity
                                                            ) -
                                                              Number(
                                                                item.ScannedQty
                                                              ) ==
                                                            0
                                                              ? "green"
                                                              : "#ADB301",
                                                          margin: 0,
                                                        }}
                                                      >
                                                        <b>
                                                          Scanned/In Picking
                                                        </b>
                                                      </p>
                                                    ) : (
                                                      <b>Created</b>
                                                    )}
                                                  </React.Fragment>
                                                ) : (
                                                  ""
                                                )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </div>

                                        <div>
                                          {Utils.disableUserAction(
                                            props.store
                                          ) && (
                                            <Grid
                                              item
                                              xs={1}
                                              sm={1}
                                              className="textCenter"
                                            >
                                              <ItemAction
                                                item={item}
                                                openResources={resourceClick}
                                                openCustomerContact={
                                                  contactCustomerClick
                                                }
                                                ShipmentLineKey={
                                                  item.ShipmentLineKey
                                                }
                                              />
                                            </Grid>
                                          )}
                                        </div>
                                      </Grid>

                                      {/* Mobile View Product Content */}

                                      <Grid
                                        spacing={2}
                                        container
                                        style={{ margin: "0px" }}
                                        sx={{ flexGrow: 1 }}
                                      >
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          style={{ padding: "8px" }}
                                        >
                                          <img
                                            style={{
                                              height: "100px",
                                              cursor: "zoom-in",
                                            }}
                                            alt=""
                                            src={Utils.convertToHttps(
                                              item.ImageUrl
                                            )}
                                            onClick={() => {
                                              imageViewer(item);
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          sm={6}
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {item.ItemDesc}
                                          </Typography>
                                          {renderItemCondition(item)}
                                          {renderExtendedDescription(item)}
                                          <ExtendedDescription
                                            key={index}
                                            itemDetails={item}
                                          ></ExtendedDescription>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        style={{
                                          marginBottom: "1rem",
                                          marginTop: "1rem",
                                          textAlign: "left",
                                          paddingLeft: 5,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item xs={5}>
                                            <Typography
                                              sx={{ fontSize: 12 }}
                                              color="text.secondary"
                                              gutterBottom
                                            >
                                              Item #
                                            </Typography>
                                            <Typography
                                              sx={{ fontSize: 13 }}
                                              gutterBottom
                                            >
                                              <b>
                                                {
                                                  item.OrderLine?.ItemDetails
                                                    ?.Extn?.ExtnPOSItemID
                                                }
                                              </b>
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography
                                              sx={{ fontSize: 12 }}
                                              color="text.secondary"
                                              gutterBottom
                                            >
                                              EID
                                            </Typography>
                                            <Typography
                                              sx={{ fontSize: 13 }}
                                              gutterBottom
                                            >
                                              <b>{item.ItemID}</b>
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            style={{ paddingLeft: 20 }}
                                          >
                                            <Typography
                                              sx={{ fontSize: 12 }}
                                              color="text.secondary"
                                              gutterBottom
                                            >
                                              Price
                                            </Typography>
                                            <Typography
                                              sx={{ fontSize: 13 }}
                                              gutterBottom
                                            >
                                              <b>
                                                $
                                                {Utils.nFormatter(
                                                  item.UnitPrice,
                                                  2
                                                )}
                                              </b>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        {orderDetail.Status !=
                                          ShipmentStatusConstants.OnHold && (
                                          <Grid container>
                                            <Grid item xs={12}>
                                              {!item.CustomComponents ? (
                                                <ItemLocations
                                                  itemDetails={item}
                                                ></ItemLocations>
                                              ) : (
                                                <></>
                                              )}
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Grid>

                                      <div
                                        style={{
                                          padding: "",
                                          background: "#F5F5F5",
                                          borderRadius: 8,
                                          marginTop: "",
                                          border: "",
                                          marginBottom: 2,
                                        }}
                                      >
                                        {item.CustomComponents &&
                                        item.CustomComponents.ShipmentLine
                                          .length > 0 ? (
                                          item.CustomComponents?.ShipmentLine?.map(
                                            (item: any) => {
                                              return (
                                                <React.Fragment>
                                                  {/* Product Header */}
                                                  <Grid
                                                    container
                                                    display={"flex"}
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                    className="justifyBetween productHeader"
                                                  >
                                                    <div>
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        spacing={2}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                      >
                                                        <Grid
                                                          item
                                                          display={"flex"}
                                                          style={{
                                                            marginTop: "8px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontSize: 12,
                                                            }}
                                                            color="text.secondary"
                                                          >
                                                            Qty
                                                          </Typography>
                                                          <Typography
                                                            variant="body2"
                                                            sx={{
                                                              fontSize: 13,
                                                              fontWeight: 500,
                                                              marginLeft: 1,
                                                            }}
                                                          >
                                                            {item.Quantity}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          display={"flex"}
                                                          style={{
                                                            marginTop: "8px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontSize: 12,
                                                            }}
                                                            color="text.secondary"
                                                          >
                                                            Remaining
                                                          </Typography>
                                                          <Typography
                                                            variant="body2"
                                                            sx={{
                                                              fontSize: 13,
                                                              fontWeight: 500,
                                                              marginLeft: 1,
                                                            }}
                                                            style={{}}
                                                          >
                                                            {item.KitCode ==
                                                            "" ? (
                                                              <React.Fragment>
                                                                {item.ScannedQty
                                                                  ? Number(
                                                                      item.Quantity
                                                                    ) -
                                                                    Number(
                                                                      item.ScannedQty
                                                                    )
                                                                  : item.Quantity}
                                                              </React.Fragment>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          display={"flex"}
                                                          className="textCenter"
                                                          style={{
                                                            marginTop: "8px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontSize: 12,
                                                            }}
                                                            color="text.secondary"
                                                          >
                                                            Status
                                                          </Typography>
                                                          <Typography
                                                            variant="body2"
                                                            sx={{
                                                              fontSize: 13,
                                                              fontWeight: 500,
                                                              marginLeft: 1,
                                                            }}
                                                          >
                                                            {item.KitCode ==
                                                            "" ? (
                                                              <React.Fragment>
                                                                {item.IsScanned ? (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        Number(
                                                                          item.Quantity
                                                                        ) -
                                                                          Number(
                                                                            item.ScannedQty
                                                                          ) ==
                                                                        0
                                                                          ? "green"
                                                                          : "#ADB301",
                                                                      margin: 0,
                                                                    }}
                                                                  >
                                                                    <b>
                                                                      Scanned/In
                                                                      Picking
                                                                    </b>
                                                                  </p>
                                                                ) : (
                                                                  <b>Created</b>
                                                                )}
                                                              </React.Fragment>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </Typography>
                                                        </Grid>
                                                      </Grid>
                                                    </div>

                                                    {/* <div>
                                                                                                            {Utils.disableUserAction(props.store) && <Grid item xs={1} sm={1} className='textCenter'>
                                                                                                                <ItemAction item={item} openResources={resourceClick} openCustomerContact={contactCustomerClick} ShipmentLineKey={item.ShipmentLineKey} />
                                                                                                            </Grid>}
                                                                                                        </div> */}
                                                  </Grid>

                                                  {/* Shipped Item Content */}

                                                  <Grid
                                                    spacing={2}
                                                    container
                                                    style={{ margin: "0px" }}
                                                    sx={{ flexGrow: 1 }}
                                                  >
                                                    <Grid
                                                      item
                                                      xs={4}
                                                      sm={4}
                                                      style={{ padding: "8px" }}
                                                    >
                                                      <img
                                                        style={{
                                                          height: "100px",
                                                          cursor: "zoom-in",
                                                        }}
                                                        alt=""
                                                        src={Utils.convertToHttps(
                                                          item.ImageUrl
                                                        )}
                                                        onClick={() => {
                                                          imageViewer(item);
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sm={6}
                                                      style={{
                                                        padding: "8px",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      <Typography
                                                        style={{
                                                          fontSize: "16px",
                                                          fontWeight: 400,
                                                        }}
                                                      >
                                                        {item.ItemDesc}
                                                      </Typography>
                                                      {renderItemCondition(
                                                        item
                                                      )}
                                                      {renderExtendedDescription(
                                                        item
                                                      )}
                                                      <ExtendedDescription
                                                        key={index}
                                                        itemDetails={item}
                                                      ></ExtendedDescription>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    container
                                                    style={{
                                                      marginBottom: "1rem",
                                                      marginTop: "1rem",
                                                      textAlign: "left",
                                                      paddingLeft: 5,
                                                    }}
                                                  >
                                                    <Grid container>
                                                      <Grid item xs={5}>
                                                        <Typography
                                                          sx={{ fontSize: 12 }}
                                                          color="text.secondary"
                                                          gutterBottom
                                                        >
                                                          Item #
                                                        </Typography>
                                                        <Typography
                                                          sx={{ fontSize: 13 }}
                                                          gutterBottom
                                                        >
                                                          <b>
                                                            {
                                                              item.OrderLine
                                                                ?.ItemDetails
                                                                ?.Extn
                                                                ?.ExtnPOSItemID
                                                            }
                                                          </b>
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={4}>
                                                        <Typography
                                                          sx={{ fontSize: 12 }}
                                                          color="text.secondary"
                                                          gutterBottom
                                                        >
                                                          EID
                                                        </Typography>
                                                        <Typography
                                                          sx={{ fontSize: 13 }}
                                                          gutterBottom
                                                        >
                                                          <b>{item.ItemID}</b>
                                                        </Typography>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                          paddingLeft: 20,
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{ fontSize: 12 }}
                                                          color="text.secondary"
                                                          gutterBottom
                                                        >
                                                          Price
                                                        </Typography>
                                                        <Typography
                                                          sx={{ fontSize: 13 }}
                                                          gutterBottom
                                                        >
                                                          <b>
                                                            $
                                                            {Utils.nFormatter(
                                                              item.UnitPrice,
                                                              2
                                                            )}
                                                          </b>
                                                        </Typography>
                                                      </Grid>
                                                    </Grid>
                                                    {orderDetail.Status !=
                                                      ShipmentStatusConstants.OnHold && (
                                                      <Grid container>
                                                        <Grid item xs={12}>
                                                          <ItemLocations
                                                            itemDetails={item}
                                                          ></ItemLocations>
                                                        </Grid>
                                                      </Grid>
                                                    )}
                                                  </Grid>
                                                  {/* <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                    Item #
                                                                                                                </Typography>
                                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                    <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID}</b>
                                                                                                                </Typography>
                                                                                                            </div>
                                                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                    EID
                                                                                                                </Typography>
                                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                    <b>{item.ItemID}</b>
                                                                                                                </Typography>
                                                                                                            </div>
                                                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                    Price
                                                                                                                </Typography>
                                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                    <b>${Utils.nFormatter(item.UnitPrice, 2)}</b>
                                                                                                                </Typography>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style={{ display: 'flex' }}>
                                                                                                            {orderDetail.Status !== ShipmentStatusConstants.OnHold && <POSInventory data={item} main="main" />}
                                                                                                        </div>
                                                                                                        <div style={{paddingBottom:3}}>
                                                                                                            <ItemLocations currentItemId={item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID} itemsLocations={itemsLocations} loadingItemsLocations={loadingItemsLocations} itemsLocationsError={itemsLocationsError} itemsLocationsErrorMsg={itemsLocationsErrorMsg} setLoadingItemsLocations={setLoadingItemsLocations} background={"white"}></ItemLocations>
                                                                                                        </div>
                                                                                                    </div> */}
                                                </React.Fragment>
                                              );
                                            }
                                          )
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </AccordionDetails>
                          </Accordion>

                          {/* {Utils.disableUserAction(props.store) && <Card style={{ margin: "6px" }}>
                                                        <CardContent style={{ padding: "0px" }}>
                                                            <Button onClick={() => pickCompleteClick()} style={{ width: "100%" }} variant='contained' className={enableConfirmPick ? "btn" : "btn disabledButton"} disabled={!enableConfirmPick} startIcon={<CheckIcon />}>Confirm Pick</Button>
                                                            {(Utils.onHold() && orderDetail.Status !== ShipmentStatusConstants.OnHold) && <Button onClick={() => onHoldClick()} style={{ width: "100%", marginTop: "5px" }} variant='contained' className={enableConfirmPick ? "btn" : "btn disabledButton"} disabled={!enableConfirmPick} startIcon={<StopIcon />}>On Hold</Button>}
                                                            {(Utils.onUnHold() && orderDetail.Status == ShipmentStatusConstants.OnHold) && <Button onClick={() => onUnHoldClick()} style={{ marginTop: "10px", width: "100%" }} className="btn" variant='contained' startIcon={<PlayArrowIcon />}>Un Hold</Button>}
                                                        </CardContent>
                                                    </Card>} */}
                          {Utils.disableUserAction(props.store) && (
                            <Grid
                              container
                              spacing={1}
                              style={{ marginTop: "5px", padding: "10px" }}
                            >
                              <Grid item xs={6} sm={6} md={6} xl={6}>
                                {Utils.onHold() &&
                                  orderDetail.Status !==
                                    ShipmentStatusConstants.OnHold && (
                                    <Button
                                      onClick={() => onHoldClick()}
                                      variant="contained"
                                      className={
                                        enableConfirmPick
                                          ? "full-button"
                                          : "full-button mobile-disabled"
                                      }
                                      disabled={!enableConfirmPick}
                                      startIcon={<StopCircleOutlinedIcon />}
                                    >
                                      On Hold
                                    </Button>
                                  )}
                              </Grid>
                              <Grid
                                item
                                xs={
                                  !Utils.onHold() ||
                                  orderDetail.Status ==
                                    ShipmentStatusConstants.OnHold
                                    ? 12
                                    : 6
                                }
                                sm={6}
                                md={6}
                                xl={6}
                              >
                                <Button
                                  onClick={() => pickCompleteClick()}
                                  variant="contained"
                                  className={
                                    enableConfirmPick
                                      ? "full-button"
                                      : "full-button mobile-disabled"
                                  }
                                  disabled={!enableConfirmPick}
                                  startIcon={<CheckIcon />}
                                >
                                  Confirm Pick
                                </Button>
                                {/* {(Utils.onUnHold() && orderDetail.Status == ShipmentStatusConstants.OnHold) && <Button onClick={() => onUnHoldClick()} style={{ marginTop: "10px"}} className="btn" variant='contained' startIcon={<PlayArrowIcon />}>Un Hold</Button>} */}
                              </Grid>
                            </Grid>
                          )}
                        </React.Fragment>
                      ) : (
                        <OrderLineShimmerMobile></OrderLineShimmerMobile>
                      )}
                    </React.Fragment>
                  ) : (
                    <OrderLineShimmerMobile></OrderLineShimmerMobile>
                  )}
                  <OrderDetailGrid
                    loading={loadingOrderLines}
                    data={orderDetails}
                  ></OrderDetailGrid>
                  {/* <div className="backdrop">
                        <div className='title'>
                            <Typography>Order Details</Typography>
                        </div>
                        {
                            !loadingOrderLines && !isError ?
                                <div className="mainContainer" style={{ padding: "20px" }}>
                                    <Grid container style={{ margin: "0px" }}>
                                        {pickUpStore &&
                                            <Grid item xs={12} sm={12}>
                                                <Typography style={{ fontSize: "11px" }}>Store Number: <b>{pickUpStore}</b></Typography>
                                            </Grid>}
                                        <Grid item xs={12} sm={12}>
                                            <Typography style={{ fontSize: "11px" }}>Level of Service: <b>{levelOfService}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography style={{ fontSize: "11px" }}>Fulfilment Type: <b>{Utils.changeFulFillmentType(fulfillmentType)}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                :
                                <div className="mainContainer" style={{ padding: "20px" }}>
                                    <OrderDetailMobileShimmer></OrderDetailMobileShimmer>
                                </div >
                        }
                    </div> */}
                  <div style={{ display: "none" }}>
                    <OrderHistory store={props.store}></OrderHistory>
                  </div>

                  {/* Customer Details for Mobile View */}

                  <Accordion
                    style={{
                      margin: 5,
                      padding: 10,
                      borderRadius: 16,
                      boxShadow:
                        "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ color: Constants.Colors.darkGrey, height: 80 }}
                    >
                      <div className="topHeading">
                        <div
                          className="gridHeading"
                          style={{ display: "flex" }}
                        >
                          <Typography>Customer Details</Typography>
                        </div>
                      </div>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails
                      style={{
                        display: "block",
                        padding: 0,
                        overflowY: "scroll",
                      }}
                    >
                      {
                        !loadingOrderLines ? (
                          <CustomerDetails
                            customerDetail={customerDetail}
                          ></CustomerDetails>
                        ) : (
                          // <div className="mainContainer" style={{ padding: "20px" }}>
                          <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                        )
                        // </div >
                      }
                    </AccordionDetails>
                  </Accordion>

                  <OrderHistory store={props.store}></OrderHistory>

                  {/* <Card className='customerDetailsBoxPickOrder'>
                                <CardContent style={{ padding: "1px", paddingBottom: "10px", background: "#F3F3F4" }}>
                                    <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                                    <Button onClick={() => { isHistoryLoading ? setHistoryDrawer(false) : setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                                </CardContent>
                            </Card> */}
                </Paper>
                <SwipeableDrawer
                  anchor={"bottom"}
                  open={openHistoryDrawer}
                  onClose={() => {}}
                  onOpen={() => {}}
                  ModalProps={{
                    onBackdropClick: () => {
                      setHistoryDrawer(false);
                    },
                  }}
                >
                  <OrderHistory store={props.store}></OrderHistory>
                </SwipeableDrawer>
                <Drawer
                  anchor={"bottom"}
                  open={openDrawer}
                  onClose={() => {}}
                  onClick={() => {
                    setDrawer(false);
                  }}
                >
                  <Card style={{ margin: "6px" }}>
                    {!loadingOrderLines ? (
                      <React.Fragment>
                        {isError == false ? (
                          <CustomerDetails
                            customerDetail={customerDetail}
                          ></CustomerDetails>
                        ) : (
                          <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                        )}
                      </React.Fragment>
                    ) : (
                      <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                    )}
                  </Card>
                </Drawer>
              </div>
            )}
          </>
        }
      </div>
    );
  };
  const openPickupSlip = () => {
    const printData = Utils.getPickupSlipDataFromPick(
      customerDetail,
      orderDetail
    );
    setPrintPickupSlipData(printData);
    setShowPickupSlip(showPickupSlip + 1);
  };
  const openPickSlip = () => {
    if (loadingOrderLines == false && isError == false) {
      SetShowPdf(true);
    }
    SetShowPdf(true);

    logger.info({ message: "", tag: Constants.LOG_TYPES.PICK_SLIP });
  };
  const cancelOrder = () => {
    handleMenuClose();
    dispatch(
      allActions.actionLoaderActions.setCancelReason({
        ...cancelReason,
        showModal: true,
        cancelItem: null,
        cancelCompleteOrder: true,
      })
    );
  };

  const logCancelOrderHistory = async () => {
    await OrderManager.saveOrderHistory({
      shipmentKey: shipmentkey,
      logType: "history",
      cancelAction: "Pick",
      transactionType: Constants.OrderHistoryTypes.Cancel_Order,
      transactionDate: moment
        .utc(moment.tz("America/Los_Angeles"))
        .toISOString(),
    });
  };

  const getCancelReasonValues = async (code: any, text: any) => {
    console.log(code, text);
    let cancelReasonObject = { ...cancelReason };

    let payLoad = OMSManager.GetCancelPayload(
      orderDetail,
      cancelReasonObject,
      code,
      text
    );

    dispatch(allActions.actionLoaderActions.showLoader());
    if (cancelReasonObject.cancelCompleteOrder) {
      logger.info({
        payLoad: payLoad,
        message: "",
        tag: Constants.LOG_TYPES.CANCEL_PICK_ORDER,
      });
    }

    await cancelLineCall(payLoad).then((response: any) => {
      if (payLoad?.Shipment?.Action == "cancelAll" && response.success) {
        logCancelOrderHistory();
      }
    });

    dispatch(
      allActions.actionLoaderActions.setCancelReason(
        Utils.initCanceReasonState()
      )
    );
    dispatch(allActions.actionLoaderActions.hideLoader());

    if (
      cancelReasonObject.cancelCompleteOrder ||
      (orderDetail.ShipmentLines &&
        orderDetail.ShipmentLines.ShipmentLine &&
        orderDetail.ShipmentLines.ShipmentLine.length == 1)
    ) {
      navigate("/FulfillOrders");
    } else {
      await getPickDetailFromOms();
    }
  };

  const cancelLineCall = async (payLoad: any) => {
    return await OMSManager.GCPickCancelLine(payLoad);
  };

  const imageViewer = (item: any) => {
    let imageData: IImageViewerDetail = {
      imageURL: item.ImageUrl,
      itemDesc: item.ItemDesc,
      itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID,
      skuID: item.ItemID,
      price: item.UnitPrice,
      qty: item.Quantity,
    };

    setImagePopUp(true);
    setImageData(imageData);
  };

  const renderExtendedDescription = (item: any) => {
    let extnDesc = "";
    if (item?.OrderLine?.ItemDetails?.Extn?.ExtnIsUsedOrVintageItem == "Y") {
      let description =
        item?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription;
      if (
        description
          ?.toLowerCase()
          ?.includes(Constants.MESSAGES.PRODUCT_CASE.toLowerCase())
      ) {
        extnDesc = Constants.MESSAGES.PRODUCT_CASE;
      } else if (
        description
          ?.toLowerCase()
          ?.includes(Constants.MESSAGES.PRODUCT_BAG.toLowerCase())
      ) {
        extnDesc = Constants.MESSAGES.PRODUCT_BAG;
      }
    }
    return extnDesc != "" ? (
      <Typography
        style={{ fontSize: Utils.isMobile ? "13px" : "15px", color: "#1976D2" }}
      >
        <b>{extnDesc}</b>
      </Typography>
    ) : (
      <></>
    );
  };

  const renderItemCondition = (item: any) => {
    let itemCondition = item?.ExtnItemCondition ?? "";
    return itemCondition != "" &&
      item?.OrderLine?.ItemDetails?.Extn?.ExtnIsUsedOrVintageItem == "Y" ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          style={{
            fontSize: Utils.isMobile ? "13px" : "15px",
            marginRight: "2px",
          }}
        >
          <b>Condition:</b>
        </Typography>
        <Typography style={{ fontSize: Utils.isMobile ? "13px" : "15px" }}>
          {itemCondition}
        </Typography>
      </div>
    ) : (
      <></>
    );
  };

  const webView = () => {
    let enableConfirmPick = getEnableConformPick();
    const loaderLeft = Utils.isMobile ? "42%" : "48%";

    return (
      <div>
        {showLoader ? (
          <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
            <TailSpin color="#D93A2F" height={80} width={80} />
          </div>
        ) : (
          ""
        )}
        {/* <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.Pick}></SiteBreadCrumbs> */}
        <div style={{ margin: "20px" }}>
          {/* <Paper className='paperToBottomPadding' style={{ padding: "10px" }}> */}
          <Grid container spacing={2} display={"flex"}>
            <Grid item xs={5} sm={5} className="textLeft">
              <Grid container display={"flex"}>
                <Grid item>
                  <Button
                    onClick={() => navigateToFulfillment()}
                    className="back-button"
                    variant="contained"
                    startIcon={<ArrowBackIosNewOutlinedIcon />}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: "30px" }}>
                  <Typography className="medium-heading">Order #</Typography>
                  <Typography className="large-heading">
                    {orderNumber}
                  </Typography>
                  {orderDetail.Status == ShipmentStatusConstants.OnHold && (
                    <Chip
                      className="chipTracking"
                      label={Constants.FulfillOrdersDisplayNames.OnHold}
                      style={{
                        background: Constants.Colors.orange,
                        color: "white",
                        fontSize: "10px",
                        height: "24px",
                        borderRadius: "4px",
                        marginBottom: "5px",
                      }}
                    />
                  )}
                  {isResourced && (
                    <Chip
                      className="chipTracking"
                      label={Constants.FulfillOrdersDisplayNames.Resourced}
                      style={{
                        background: Constants.Colors.lightBlue,
                        color: "white",
                        fontSize: "10px",
                        height: "24px",
                        marginBottom: "5px",
                        borderRadius: "4px",
                        marginLeft:
                          orderDetail.Status == ShipmentStatusConstants.OnHold
                            ? "5px"
                            : "0px",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {Utils.disableUserAction(props.store) && (
              <Grid item xs={7} sm={7} className="textRight">
                <Button
                  disabled={loadingOrderLines}
                  onClick={() => openPickSlip()}
                  className="header-button"
                  variant="contained"
                  startIcon={<LocalPrintshopOutlinedIcon />}
                >
                  Pick Slip
                </Button>
                <Button
                  onClick={() => contactCustomerClick()}
                  style={{ marginLeft: 10 }}
                  className="header-button underline"
                  variant="contained"
                  startIcon={<EmailOutlinedIcon />}
                >
                  Contact Cust.
                </Button>
                <Button
                  className="options-button"
                  onClick={handleMenuOpen}
                  style={{ marginLeft: 10 }}
                >
                  <MoreVertIcon />
                </Button>

                <Menu
                  PaperProps={{
                    style: {
                      width: "228px",
                      borderRadius: "16px",
                    },
                  }}
                  key={Utils.generateRandom(
                    10,
                    Constants.CryptoRandomTypes.numeric
                  )}
                  open={openMenu}
                  keepMounted
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={handleMenuClose}
                >
                  {(navigateTo == Constants.OrderFlowConstants.PICK_PICKUP ||
                    navigateTo ==
                      Constants.OrderFlowConstants
                        .PICK_PACK_SHIP_RECEIVE_PICKUP) && (
                    <>
                      <MenuItem
                        className="dropdownMenuItem"
                        key={Utils.generateRandom(
                          10,
                          Constants.CryptoRandomTypes.numeric
                        )}
                        onClick={() => openPickupSlip()}
                      >
                        Print Pickup Slip
                        <BallotOutlinedIcon style={{ fontSize: "20px" }} />
                      </MenuItem>
                      <Divider />
                    </>
                  )}
                  <MenuItem
                    className="dropdownMenuItem cancel"
                    key={Utils.generateRandom(
                      10,
                      Constants.CryptoRandomTypes.numeric
                    )}
                    onClick={() => cancelOrder()}
                  >
                    Cancel Order
                    <CancelOutlinedIcon style={{ fontSize: "20px" }} />
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
          {/* </Paper> */}
          <div style={{ marginTop: "26px" }}>
            <Grid
              container
              spacing={2}
              display={"flex"}
              style={{ padding: "0px" }}
            >
              <Grid
                item
                xs={9}
                sm={9}
                style={{
                  paddingTop: "0px",
                  paddingRight: "4px",
                  paddingLeft: "0px",
                }}
                className="textLeft"
              >
                <OrderDetailGrid
                  loading={loadingOrderLines}
                  data={orderDetails}
                ></OrderDetailGrid>
                <Card className="dCard" style={{ margin: 16 }}>
                  <CardContent className="dCardContent">
                    {/* <div className="topHeading"> */}
                    {/* <div className="gridHeading"> */}
                    <Typography className="card-heading">
                      {" "}
                      Order Lines
                    </Typography>
                    {/* </div> */}
                    <Divider className="card-divider" />
                    <div style={{ marginTop: 20, padding: "20px" }}>
                      <Grid container spacing={2} display={"flex"}></Grid>
                      <div>
                        {!loadingOrderLines ? (
                          <React.Fragment>
                            {isError == false ? (
                              <React.Fragment>
                                {orderDetail?.ShipmentLines?.ShipmentLine?.map(
                                  (item: any, index: any) => {
                                    return (
                                      <React.Fragment>
                                        {/* Order Line Product Header */}
                                        <Grid
                                          container
                                          spacing={1}
                                          display={"flex"}
                                          className="justifyBetween productHeader"
                                        >
                                          <div>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              spacing={2}
                                              container
                                              alignItems="center"
                                              justifyContent="space-between"
                                            >
                                              <Grid
                                                item
                                                display={"flex"}
                                                style={{ marginTop: "8px" }}
                                              >
                                                <Typography
                                                  sx={{ fontSize: 12 }}
                                                  color="text.secondary"
                                                >
                                                  Qty
                                                </Typography>
                                                <Typography
                                                  variant="body2"
                                                  sx={{
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                    marginLeft: 1,
                                                  }}
                                                >
                                                  {item.Quantity}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                display={"flex"}
                                                style={{ marginTop: "8px" }}
                                              >
                                                <Typography
                                                  sx={{ fontSize: 12 }}
                                                  color="text.secondary"
                                                >
                                                  Remaining
                                                </Typography>
                                                <Typography
                                                  variant="body2"
                                                  sx={{
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                    marginLeft: 1,
                                                  }}
                                                  style={{}}
                                                >
                                                  {item.KitCode == "" ? (
                                                    <React.Fragment>
                                                      {item.ScannedQty
                                                        ? Number(
                                                            item.Quantity
                                                          ) -
                                                          Number(
                                                            item.ScannedQty
                                                          )
                                                        : item.Quantity}
                                                    </React.Fragment>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                display={"flex"}
                                                className="textCenter"
                                                style={{ marginTop: "8px" }}
                                              >
                                                <Typography
                                                  sx={{ fontSize: 12 }}
                                                  color="text.secondary"
                                                >
                                                  Status
                                                </Typography>
                                                <Typography
                                                  variant="body2"
                                                  sx={{
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                    marginLeft: 1,
                                                  }}
                                                >
                                                  {item.KitCode == "" ? (
                                                    <React.Fragment>
                                                      {item.IsScanned ? (
                                                        <p
                                                          style={{
                                                            color:
                                                              Number(
                                                                item.Quantity
                                                              ) -
                                                                Number(
                                                                  item.ScannedQty
                                                                ) ==
                                                              0
                                                                ? "green"
                                                                : "#ADB301",
                                                            margin: 0,
                                                          }}
                                                        >
                                                          <b>
                                                            Scanned/In Picking
                                                          </b>
                                                        </p>
                                                      ) : (
                                                        <b>Created</b>
                                                      )}
                                                    </React.Fragment>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </div>

                                          <div>
                                            {Utils.disableUserAction(
                                              props.store
                                            ) && (
                                              <Grid
                                                item
                                                xs={1}
                                                sm={1}
                                                className="textCenter"
                                              >
                                                <ItemAction
                                                  item={item}
                                                  openResources={resourceClick}
                                                  openCustomerContact={
                                                    contactCustomerClick
                                                  }
                                                  ShipmentLineKey={
                                                    item.ShipmentLineKey
                                                  }
                                                />
                                              </Grid>
                                            )}
                                          </div>
                                        </Grid>

                                        {/* Order Line Product Content */}
                                        <Grid
                                          container
                                          spacing={1}
                                          display={"flex"}
                                          key={index}
                                          style={{ margin: 0 }}
                                        >
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            className="textLeft"
                                          >
                                            <Grid
                                              container
                                              spacing={1}
                                              style={{ margin: "0px" }}
                                              sx={{ flexGrow: 1 }}
                                            >
                                              <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                style={{
                                                  padding: "8px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <div
                                                  className="image-zoom"
                                                  onClick={() =>
                                                    imageViewer(item)
                                                  }
                                                >
                                                  <img
                                                    style={{
                                                      height: "auto",
                                                      width: "100%",
                                                    }}
                                                    alt=""
                                                    src={Utils.convertToHttps(
                                                      item.ImageUrl
                                                    )}
                                                  />
                                                </div>
                                              </Grid>

                                              {/* Chip */}
                                              <Grid
                                                item
                                                xs={9}
                                                sm={9}
                                                style={{
                                                  padding: "8px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {item.KitCode != "" ? (
                                                  <Chip
                                                    className="chipTracking"
                                                    label={
                                                      item.KitCode == "BUNDLE"
                                                        ? "SET"
                                                        : item.KitCode
                                                    }
                                                    style={{
                                                      background: "#A1D899",
                                                      color: "#006100",
                                                      fontSize: "12px",
                                                      fontWeight: 700,
                                                      height: "20px",
                                                      borderRadius: "4px",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                                {item.WarrantyComponents ? (
                                                  <Chip
                                                    className="chipTracking"
                                                    label={"PRO COV"}
                                                    style={{
                                                      background: "#A1D899",
                                                      color: "#006100",
                                                      fontSize: "12px",
                                                      fontWeight: 700,
                                                      height: "20px",
                                                      borderRadius: "4px",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}

                                                {/* Product Item Description */}
                                                <Typography
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {item.ItemDesc}
                                                </Typography>
                                                {renderItemCondition(item)}
                                                {renderExtendedDescription(
                                                  item
                                                )}
                                                <ExtendedDescription
                                                  key={index}
                                                  itemDetails={item}
                                                ></ExtendedDescription>
                                                <Grid
                                                  container
                                                  style={{
                                                    marginBottom: "1rem",
                                                    marginTop: "1rem",
                                                  }}
                                                >
                                                  <Grid container>
                                                    <Grid item sm={4}>
                                                      <Typography
                                                        sx={{ fontSize: 12 }}
                                                        color="text.secondary"
                                                        gutterBottom
                                                      >
                                                        Item #
                                                      </Typography>
                                                      <Typography
                                                        sx={{ fontSize: 13 }}
                                                        gutterBottom
                                                      >
                                                        <b>
                                                          {
                                                            item.OrderLine
                                                              ?.ItemDetails
                                                              ?.Extn
                                                              ?.ExtnPOSItemID
                                                          }
                                                        </b>
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                      <Typography
                                                        sx={{ fontSize: 12 }}
                                                        color="text.secondary"
                                                        gutterBottom
                                                      >
                                                        EID
                                                      </Typography>
                                                      <Typography
                                                        sx={{ fontSize: 13 }}
                                                        gutterBottom
                                                      >
                                                        <b>{item.ItemID}</b>
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                      <Typography
                                                        sx={{ fontSize: 12 }}
                                                        color="text.secondary"
                                                        gutterBottom
                                                      >
                                                        Price
                                                      </Typography>
                                                      <Typography
                                                        sx={{ fontSize: 13 }}
                                                        gutterBottom
                                                      >
                                                        <b>
                                                          $
                                                          {Utils.nFormatter(
                                                            item.UnitPrice,
                                                            2
                                                          )}
                                                        </b>
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  {orderDetail.Status !=
                                                    ShipmentStatusConstants.OnHold && (
                                                    <Grid container>
                                                      <Grid item sm={12}>
                                                        {!item.CustomComponents ? (
                                                          <ItemLocations
                                                            itemDetails={item}
                                                          ></ItemLocations>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                </Grid>

                                                {/* <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                        Item #
                                                                                                                    </Typography>
                                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                        <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID}</b>
                                                                                                                    </Typography>
                                                                                                                </div>
                                                                                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                        EID
                                                                                                                    </Typography>
                                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                        <b>{item.ItemID}</b>
                                                                                                                    </Typography>
                                                                                                                </div>
                                                                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                        Price
                                                                                                                    </Typography>
                                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                        <b>${Utils.nFormatter(item.UnitPrice, 2)}</b>
                                                                                                                    </Typography>
                                                                                                                </div>
                                                                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                                    {orderDetail.Status !== ShipmentStatusConstants.OnHold && <POSInventory data={item} main="main" />}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    !(item.CustomComponents) ?
                                                                                                                        <ItemLocations currentItemId={item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID} itemsLocations={itemsLocations} loadingItemsLocations={loadingItemsLocations} itemsLocationsError={itemsLocationsError} setLoadingItemsLocations={setLoadingItemsLocations} background={"#F5F5F5"}></ItemLocations> :
                                                                                                                        <></>

                                                                                                                }

                                                                                                            </div>
                                                                                                        </div> */}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        <div
                                          style={{
                                            padding: "",
                                            background: "#F5F5F5",
                                            borderRadius: 8,
                                            marginTop: "",
                                            border: "",
                                            marginBottom: 2,
                                          }}
                                        >
                                          {item.CustomComponents &&
                                          item.CustomComponents.ShipmentLine
                                            .length > 0 ? (
                                            item.CustomComponents?.ShipmentLine?.map(
                                              (item: any) => {
                                                return (
                                                  <React.Fragment>
                                                    {/* Product Header */}
                                                    <Grid
                                                      container
                                                      display={"flex"}
                                                      className="justifyBetween productHeader"
                                                    >
                                                      <div>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          spacing={2}
                                                          container
                                                          alignItems="center"
                                                          justifyContent="space-between"
                                                        >
                                                          <Grid
                                                            item
                                                            display={"flex"}
                                                            style={{
                                                              marginTop: "8px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: 12,
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Qty
                                                            </Typography>
                                                            <Typography
                                                              variant="body2"
                                                              sx={{
                                                                fontSize: 13,
                                                                fontWeight: 500,
                                                                marginLeft: 1,
                                                              }}
                                                            >
                                                              {item.Quantity}
                                                            </Typography>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            display={"flex"}
                                                            style={{
                                                              marginTop: "8px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: 12,
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Remaining
                                                            </Typography>
                                                            <Typography
                                                              variant="body2"
                                                              sx={{
                                                                fontSize: 13,
                                                                fontWeight: 500,
                                                                marginLeft: 1,
                                                              }}
                                                              style={{}}
                                                            >
                                                              {item.KitCode ==
                                                              "" ? (
                                                                <React.Fragment>
                                                                  {item.ScannedQty
                                                                    ? Number(
                                                                        item.Quantity
                                                                      ) -
                                                                      Number(
                                                                        item.ScannedQty
                                                                      )
                                                                    : item.Quantity}
                                                                </React.Fragment>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </Typography>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            display={"flex"}
                                                            className="textCenter"
                                                            style={{
                                                              marginTop: "8px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: 12,
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Status
                                                            </Typography>
                                                            <Typography
                                                              variant="body2"
                                                              sx={{
                                                                fontSize: 13,
                                                                fontWeight: 500,
                                                                marginLeft: 1,
                                                              }}
                                                            >
                                                              {item.KitCode ==
                                                              "" ? (
                                                                <React.Fragment>
                                                                  {item.IsScanned ? (
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          Number(
                                                                            item.Quantity
                                                                          ) -
                                                                            Number(
                                                                              item.ScannedQty
                                                                            ) ==
                                                                          0
                                                                            ? "green"
                                                                            : "#ADB301",
                                                                        margin: 0,
                                                                      }}
                                                                    >
                                                                      <b>
                                                                        Scanned/In
                                                                        Picking
                                                                      </b>
                                                                    </p>
                                                                  ) : (
                                                                    <b>
                                                                      Created
                                                                    </b>
                                                                  )}
                                                                </React.Fragment>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </div>

                                                      <div>
                                                        {Utils.disableUserAction(
                                                          props.store
                                                        ) && (
                                                          <Grid
                                                            item
                                                            xs={1}
                                                            sm={1}
                                                            className="textCenter"
                                                          >
                                                            <ItemAction
                                                              item={item}
                                                              openResources={
                                                                resourceClick
                                                              }
                                                              openCustomerContact={
                                                                contactCustomerClick
                                                              }
                                                              ShipmentLineKey={
                                                                item.ShipmentLineKey
                                                              }
                                                            />
                                                          </Grid>
                                                        )}
                                                      </div>
                                                    </Grid>
                                                    {/* Shipped Item Content */}
                                                    <Grid
                                                      container
                                                      spacing={1}
                                                      display={"flex"}
                                                      key={index}
                                                      style={{ margin: 0 }}
                                                    >
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        className="textLeft"
                                                      >
                                                        <Grid
                                                          container
                                                          spacing={1}
                                                          sx={{ flexGrow: 1 }}
                                                        >
                                                          <Grid
                                                            item
                                                            xs={3}
                                                            sm={3}
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                            }}
                                                          >
                                                            <div
                                                              className="image-zoom"
                                                              onClick={() =>
                                                                imageViewer(
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                style={{
                                                                  height:
                                                                    "auto",
                                                                  width: "100%",
                                                                }}
                                                                alt=""
                                                                src={Utils.convertToHttps(
                                                                  item.ImageUrl
                                                                )}
                                                              />
                                                            </div>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={9}
                                                            sm={9}
                                                            style={{
                                                              padding: "8px",
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            {item.WarrantyComponents ? (
                                                              <Chip
                                                                className="chipTracking"
                                                                label={
                                                                  "PRO COV"
                                                                }
                                                                style={{
                                                                  background:
                                                                    "#A1D899",
                                                                  color:
                                                                    "#006100",
                                                                  fontSize:
                                                                    "12px",
                                                                  fontWeight: 700,
                                                                  height:
                                                                    "20px",
                                                                  borderRadius:
                                                                    "4px",
                                                                }}
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* Item Description */}
                                                            <Typography
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                                fontWeight: 400,
                                                              }}
                                                            >
                                                              {item.ItemDesc}
                                                            </Typography>

                                                            <Grid
                                                              container
                                                              style={{
                                                                marginBottom:
                                                                  "1rem",
                                                                marginTop:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              <Grid container>
                                                                <Grid
                                                                  item
                                                                  sm={4}
                                                                >
                                                                  <Typography
                                                                    sx={{
                                                                      fontSize: 12,
                                                                    }}
                                                                    color="text.secondary"
                                                                    gutterBottom
                                                                  >
                                                                    Item #
                                                                  </Typography>
                                                                  <Typography
                                                                    sx={{
                                                                      fontSize: 13,
                                                                    }}
                                                                    gutterBottom
                                                                  >
                                                                    <b>
                                                                      {
                                                                        item
                                                                          .OrderLine
                                                                          ?.ItemDetails
                                                                          ?.Extn
                                                                          ?.ExtnPOSItemID
                                                                      }
                                                                    </b>
                                                                  </Typography>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  sm={4}
                                                                >
                                                                  <Typography
                                                                    sx={{
                                                                      fontSize: 12,
                                                                    }}
                                                                    color="text.secondary"
                                                                    gutterBottom
                                                                  >
                                                                    EID
                                                                  </Typography>
                                                                  <Typography
                                                                    sx={{
                                                                      fontSize: 13,
                                                                    }}
                                                                    gutterBottom
                                                                  >
                                                                    <b>
                                                                      {
                                                                        item.ItemID
                                                                      }
                                                                    </b>
                                                                  </Typography>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  sm={4}
                                                                >
                                                                  <Typography
                                                                    sx={{
                                                                      fontSize: 12,
                                                                    }}
                                                                    color="text.secondary"
                                                                    gutterBottom
                                                                  >
                                                                    Price
                                                                  </Typography>
                                                                  <Typography
                                                                    sx={{
                                                                      fontSize: 13,
                                                                    }}
                                                                    gutterBottom
                                                                  >
                                                                    <b>
                                                                      $
                                                                      {Utils.nFormatter(
                                                                        item.UnitPrice,
                                                                        2
                                                                      )}
                                                                    </b>
                                                                  </Typography>
                                                                </Grid>
                                                              </Grid>
                                                              {orderDetail.Status !=
                                                                ShipmentStatusConstants.OnHold && (
                                                                <Grid container>
                                                                  <Grid
                                                                    item
                                                                    sm={12}
                                                                  >
                                                                    <ItemLocations
                                                                      itemDetails={
                                                                        item
                                                                      }
                                                                    ></ItemLocations>
                                                                  </Grid>
                                                                </Grid>
                                                              )}
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </React.Fragment>
                                                );
                                              }
                                            )
                                          ) : (
                                            <div></div>
                                          )}
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            ) : (
                              <OrderLineShimmer></OrderLineShimmer>
                            )}
                          </React.Fragment>
                        ) : (
                          <OrderLineShimmer></OrderLineShimmer>
                        )}
                      </div>
                    </div>

                    <Divider className="card-divider" />
                    {/* </div> */}
                  </CardContent>
                </Card>
                {/* <div className="backdrop">
                                    <div className='title'>
                                        <Typography>Order Details</Typography>
                                    </div>
                                    {
                                        !loadingOrderDetails && !isError ?

                                            <div className="mainContainer" style={{ padding: "20px" }}>
                                                <Grid container display={"flex"} style={{ justifyContent: "space-around" }}>
                                                    {storeNumber && <Grid style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                                            Store Number:
                                                        </Typography>
                                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                                            {storeNumber}
                                                        </Typography>
                                                    </Grid>}
                                                    <Grid item style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                                            Level of Service:
                                                        </Typography>
                                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                                            {levelOfService}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item style={{ display: "flex" }}>
                                                        <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                                                            Fulfilment Type:
                                                        </Typography>
                                                        <Typography style={{ fontSize: "15px", marginLeft: "2px" }}>
                                                            {Utils.changeFulFillmentType(fulfillmentType)}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </div>
                                            :
                                            <div className="mainContainer" style={{ padding: "20px" }}>
                                                <OrderDetailShimmer></OrderDetailShimmer>
                                            </div >
                                    }


                                </div> */}
                <Card className="dCard" style={{ margin: 16 }}>
                  <CardContent className="dCardContent">
                    {/* <div className="topHeading"> */}
                    {/* <div className="gridHeading"> */}
                    <Typography className="card-heading">
                      {" "}
                      Customer Details
                    </Typography>
                    {/* </div> */}
                    <Divider className="card-divider" />
                    {
                      !loadingOrderLines ? (
                        <CustomerDetails
                          customerDetail={customerDetail}
                        ></CustomerDetails>
                      ) : (
                        // <div className="mainContainer" style={{ padding: "20px" }}>
                        <CustomerDetailShimmer></CustomerDetailShimmer>
                      )
                      // </div >
                    }
                    {/* </div > */}
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={3}
                sm={3}
                style={{
                  paddingTop: "0px",
                  paddingRight: "0px",
                  paddingLeft: "6px",
                  marginTop: "16px",
                }}
              >
                <Card className="dCard">
                  <CardContent className="dCardContent">
                    <Typography
                      className="card-heading"
                      style={{ textAlign: "left" }}
                    >
                      Scan/Enter Bar Codes
                    </Typography>
                    <Divider className="card-divider" />
                    <Grid
                      container
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      {Utils.disableUserAction(props.store) && (
                        <Grid item xs={12} sm={12} className="inputField">
                          <TextField
                            value={skuToScan}
                            onKeyDown={keyPress}
                            disabled={
                              orderDetail.Status ==
                              ShipmentStatusConstants.OnHold
                            }
                            onChange={(event) =>
                              setSKUNumberForScan(event.target.value)
                            }
                            variant={"outlined"}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            label=""
                            placeholder="SKU # / UPC /Serial #"
                            id="fullWidth"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => scanItemId()}
                                    aria-label="Enter"
                                    disabled={
                                      orderDetail.Status ==
                                        ShipmentStatusConstants.OnHold ||
                                      !skuToScan.trim()
                                    }
                                  >
                                    <KeyboardReturnIcon
                                      style={{
                                        color:
                                          orderDetail.Status ==
                                            ShipmentStatusConstants.OnHold ||
                                          !skuToScan.trim()
                                            ? Constants.Colors.grey
                                            : Constants.Colors.red,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className="inputField"
                        style={{ marginTop: "5px" }}
                      >
                        <TextField
                          value={scannedQty}
                          onChange={(event) =>
                            setScannedQty(event.target.value)
                          }
                          disabled={
                            orderDetail.Status == ShipmentStatusConstants.OnHold
                          }
                          variant={"outlined"}
                          type={"number"}
                          InputProps={{ inputProps: { min: 1 } }}
                          defaultValue={1}
                          InputLabelProps={{ shrink: false }}
                          fullWidth
                          label=""
                          placeholder="Quantity"
                          id="fullWidth"
                        />
                      </Grid>
                    </Grid>
                    <Divider className="card-divider" />
                    <Grid container style={{ marginTop: "10px" }}>
                      {orderDetail.Status != ShipmentStatusConstants.OnHold && (
                        <Grid item xs={12} md={12}>
                          <List>
                            {pickedItems.length == 0 ? (
                              <Typography style={{ textAlign: "center" }}>
                                Scan Item ID To Continue
                              </Typography>
                            ) : (
                              <></>
                            )}
                            {pickedItems.map((pickedItem: any, index: any) => {
                              return (
                                <Card
                                  style={{
                                    background: "#F5F5F5",
                                    borderRadius: "8px",
                                    boxShadow: "0px 0px 0px 0px",
                                    marginTop: index > 0 ? "5px" : "0px",
                                  }}
                                >
                                  <CardContent style={{ padding: 5 }}>
                                    <ListItem
                                      style={{
                                        paddingLeft: 8,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                      }}
                                      secondaryAction={
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                        >
                                          <CancelOutlinedIcon
                                            onClick={() =>
                                              cancelPickScan(
                                                pickedItem.shipmentLineKey
                                              )
                                            }
                                            style={{ color: "#676767" }}
                                          />
                                        </IconButton>
                                      }
                                    >
                                      <ListItemText
                                        primary={
                                          <React.Fragment>
                                            <Typography className="scan-desc">
                                              {pickedItem.ItemDesc}
                                            </Typography>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography className="scan-heading">
                                                {"Item ID"}
                                              </Typography>
                                              <Typography
                                                className="scan-value"
                                                style={{ marginLeft: "5px" }}
                                              >
                                                {pickedItem.posItemId}
                                              </Typography>
                                            </div>
                                            {/* <p cla>Item ID </p>{pickedItem.posItemId} */}
                                          </React.Fragment>
                                        }
                                        secondary={
                                          <React.Fragment>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography className="scan-heading">
                                                {"SKU"}
                                              </Typography>
                                              <Typography
                                                className="scan-value"
                                                style={{ marginLeft: "5px" }}
                                              >
                                                {pickedItem.SKUNumber}
                                              </Typography>
                                              <Typography
                                                className="scan-heading"
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {"QTY"}
                                              </Typography>
                                              <Typography
                                                className="scan-value"
                                                style={{ marginLeft: "5px" }}
                                              >
                                                {pickedItem.Quantity}
                                              </Typography>
                                            </div>
                                            {/* <Typography
                                                                                        sx={{ display: "inline" }}
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        <b>{"SKU: "}</b>{pickedItem.SKUNumber}{" "}<b>{"QTY: "}</b>{pickedItem.Quantity}
                                                                                    </Typography> */}
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItem>
                                  </CardContent>
                                </Card>
                              );
                            })}
                          </List>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: "5px" }}>
                      <Grid item xs={12} sm={12} md={12} xl={6}>
                        {Utils.onHold() &&
                          orderDetail.Status !==
                            ShipmentStatusConstants.OnHold && (
                            <Button
                              onClick={() => onHoldClick()}
                              variant="contained"
                              className={
                                enableConfirmPick
                                  ? "full-button"
                                  : "full-button disabled"
                              }
                              disabled={!enableConfirmPick}
                              startIcon={<StopCircleOutlinedIcon />}
                            >
                              On Hold
                            </Button>
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={
                          !Utils.onHold() ||
                          orderDetail.Status == ShipmentStatusConstants.OnHold
                            ? 12
                            : 6
                        }
                      >
                        <Button
                          onClick={() => pickCompleteClick()}
                          variant="contained"
                          className={
                            enableConfirmPick
                              ? "full-button"
                              : "full-button disabled"
                          }
                          disabled={!enableConfirmPick}
                          startIcon={<CheckIcon />}
                        >
                          Confirm Pick
                        </Button>
                        {/* {(Utils.onUnHold() && orderDetail.Status == ShipmentStatusConstants.OnHold) && <Button onClick={() => onUnHoldClick()} style={{ marginTop: "10px"}} className="btn" variant='contained' startIcon={<PlayArrowIcon />}>Un Hold</Button>} */}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {/* <div className="backdrop">
                                    <div className="title">
                                        <Typography>Scan/Enter Bar Codes</Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "10px" }}>
                                        <Grid container>
                                            {Utils.disableUserAction(props.store) && <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography>
                                                    SKU # / UPC /Serial #
                                                </Typography>

                                                <TextField value={skuToScan} onKeyDown={keyPress} disabled={orderDetail.Status == ShipmentStatusConstants.OnHold} onChange={(event) => setSKUNumberForScan(event.target.value)} variant={"outlined"} InputLabelProps={{ shrink: false }} fullWidth label="" placeholder='SKU # / UPC /Serial #' id="fullWidth" InputProps={{
                                                    endAdornment: (<InputAdornment position="end"><IconButton onClick={() => {

                                                        scanItemId();

                                                    }} aria-label="Enter" disabled={orderDetail.Status == ShipmentStatusConstants.OnHold}> <KeyboardReturnIcon style={{ color: orderDetail.Status == ShipmentStatusConstants.OnHold ? Constants.Colors.grey : Constants.Colors.red, cursor: "pointer" }} /> </IconButton></InputAdornment>)
                                                }} />
                                            </Grid>}
                                            <Grid item xs={12} sm={12} className='inputField'>
                                                <Typography>
                                                    Quantity
                                                </Typography>

                                                <TextField value={scannedQty} onChange={(event) => setScannedQty(event.target.value)} disabled={orderDetail.Status == ShipmentStatusConstants.OnHold} variant={"outlined"} type={"number"} InputProps={{ inputProps: { min: 1 } }} defaultValue={1} InputLabelProps={{ shrink: false }} fullWidth label="" placeholder='Quantity' id="fullWidth" />
                                            </Grid>
                                        </Grid>
                                    </div >
                                </div> */}
                {/* <div className="backdrop">
                                    <div className="title">
                                        <Typography>
                                            Picked Items
                                        </Typography>
                                    </div>
                                    <div className="mainContainer" style={{ padding: "10px" }}>
                                        {orderDetail.Status != ShipmentStatusConstants.OnHold && <Grid item xs={12} md={12}>
                                            <List >
                                                {
                                                    pickedItems.length == 0 ?
                                                        <Typography style={{ textAlign: "center" }}>Scan Item ID To Continue</Typography>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    pickedItems.map((pickedItem: any) => {
                                                        return (
                                                            <Card style={{ marginBottom: 3 }}>
                                                                <CardContent style={{ padding: 0 }}>
                                                                    <ListItem
                                                                        style={{ paddingLeft: 8, paddingTop: 0, paddingBottom: 0 }}
                                                                        secondaryAction={
                                                                            <IconButton edge="end" aria-label="delete">
                                                                                <CloseIcon onClick={() => cancelPickScan(pickedItem.shipmentLineKey)} color='error' />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemText
                                                                            primary={
                                                                                <React.Fragment>
                                                                                    <Typography style={{ color: "#D93A2F", fontSize: "12px" }}>
                                                                                        {pickedItem.ItemDesc}
                                                                                    </Typography>
                                                                                    <React.Fragment>
                                                                                        <b>Item ID: </b>{pickedItem.posItemId}
                                                                                    </React.Fragment>
                                                                                </React.Fragment>
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        sx={{ display: "inline" }}
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        <b>{"SKU: "}</b>{pickedItem.SKUNumber}{" "}<b>{"QTY: "}</b>{pickedItem.Quantity}
                                                                                    </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </CardContent>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </List>

                                        </Grid>}
                                        <Button onClick={() => pickCompleteClick()} style={{ marginTop: "20px", width: "100%" }} variant='contained' className={enableConfirmPick ? "btn" : "btn disabledButton"} disabled={!enableConfirmPick} startIcon={<CheckIcon />}>Confirm Pick</Button>
                                        {(Utils.onUnHold() && orderDetail.Status == ShipmentStatusConstants.OnHold) && <Button onClick={() => onUnHoldClick()} style={{ marginTop: "10px", width: "100%" }} className="btn" variant='contained' startIcon={<PlayArrowIcon />}>Un Hold</Button>}

                                        {(Utils.onHold() && orderDetail.Status !== ShipmentStatusConstants.OnHold) && <Button onClick={() => onHoldClick()} style={{ marginTop: "10px", width: "100%" }} variant='contained' className={enableConfirmPick ? "btn" : "btn disabledButton"} disabled={!enableConfirmPick} startIcon={<StopIcon />}>On Hold</Button>}
                                    </div >
                                </div > */}
                <div>
                  <OrderHistory store={props.store}></OrderHistory>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <NotificationPopup {...boxFeaturePopup}></NotificationPopup>
      <NotificationPopup {...success}></NotificationPopup>
      <NotificationPopup {...ReportError}></NotificationPopup>
      <NotificationPopup {...confirmReportError}></NotificationPopup>
      <NotificationPopup {...pickCompleteDialog}></NotificationPopup>
      <NotificationPopup {...resourcePopup}></NotificationPopup>
      <NotificationPopup {...contactCustomerPopup}></NotificationPopup>
      <NotificationPopup {...notesCustomerPopup}></NotificationPopup>
      <NotificationPopup {...Error}></NotificationPopup>
      <NotificationPopup {...navigateToFulfillOrders}></NotificationPopup>
      <NotificationPopup {...onHoldDialog}></NotificationPopup>
      <ImageViewerPopup {...imageViewerSettings}></ImageViewerPopup>
      {Utils.isMobile ? mobileView() : webView()}
      {showPdf ? (
        <React.Fragment>
          <PickSlip shipmentKey={shipmentkey} close={closePdfiframe}></PickSlip>
        </React.Fragment>
      ) : (
        ""
      )}

      <NotificationPopup {...GeneralError}></NotificationPopup>
      {cancelReason.showModal ? (
        <CancelLine
          getCancelReasonValues={getCancelReasonValues}
          isExternal={false}
        ></CancelLine>
      ) : (
        ""
      )}
      {dynamicHtml != "" ? (
        <Dialog
          fullScreen={Utils.isMobile ? true : false}
          disableEnforceFocus
          fullWidth={true}
          maxWidth="lg"
          open={openEmailTemplate}
          TransitionComponent={Transition}
          scroll={"body"}
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setOpenEmailTemplate(false)}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                color: Constants.Colors.grey,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent id="">
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: dynamicHtml }} />
            </React.Fragment>
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
      {showPickupSlip > 0 ? (
        <PickupPrintSlip
          loadingOrderLines={loadingOrderLines}
          showPickupSlip={showPickupSlip}
          saveType={"print"}
          CustomIPickupSlip={printPickupSlipData}
          navigateAfterSuccess={false}
        ></PickupPrintSlip>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default PickOrder;
