import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, InputAdornment, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import '../Stepper/VerticalLinearStepper.scss';
import { IStepOption, IVerticalStepper } from '../../Models/IVerticalStepper';
import { NotificationPopup } from '../../Subcomponent/Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import Constants from '../../Common/Constants'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import {
    SpeakerNotesOutlinedIcon,
    MessageIcon, MarkEmailReadIcon, DirectionsCar, LocalShippingIcon, ShoppingCart, ApartmentIcon,
    DoneIcon, CloseIcon, SendIcon, AddCommentIcon, ArrowBackIcon, DocumentScannerIcon, CommentIcon,
    HighlightOffIcon, PersonIcon, AddLocationAltIcon, KeyboardReturnIcon, EmailIcon, LocalPhoneIcon, CheckIcon, PlaylistAddCheck, ExpandMoreIcon
} from "../Shared/SharedIcons"
import Utils from '../../Common/Utils';
import LabelText from '../../Common/LabelText';
import { Note } from '../Shared/Note';
import allActions from '../../Store/Actions/AllActions';
import { VerticalLinearStepper } from '../Stepper/VerticalLinearStepper';
import ApiService from '../../Common/ApiService';
import OrderManager from '../../Managers/Orders.Manager';
import ShipmentStatusConstants from '../../Common/ShipmentStatusConstants';
import { LinesShimmer } from './LinesShimmer';

//import { useLocation } from "react-router-dom";
import BulletShimmer from './BulletShimmer';
import { TailSpin } from 'react-loader-spinner';
import "./OrderHistory.scss";
var moment = require('moment');

const OrderHistory = (props: any) => {
    //const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const shipmentkey = urlParams.get('shipmentkey');
    const orderno = urlParams.get('orderno');
    const orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    const notesPopup = useSelector((state: any) => state.ActionLoaderReducersState.notesPopup);
    const stepsOrder =
        [
            Constants.OrderHistoryTypes.Order_Release,
            Constants.OrderHistoryTypes.Order_Queue,
            Constants.OrderHistoryTypes.Picking,
            Constants.OrderHistoryTypes.Packing,
            Constants.OrderHistoryTypes.Shipped
        ];

    const PickupUpStoreSteps =
        [
            Constants.OrderHistoryTypes.Order_Release,
            Constants.OrderHistoryTypes.Order_Queue,
            Constants.OrderHistoryTypes.Picking,
            Constants.OrderHistoryTypes.Customer_Pickup
        ];
    const ShipToStoreSteps =
        [
            Constants.OrderHistoryTypes.Order_Release,
            Constants.OrderHistoryTypes.Order_Queue,
            Constants.OrderHistoryTypes.Picking,
            Constants.OrderHistoryTypes.Packing,
            Constants.OrderHistoryTypes.Shipped,
            Constants.OrderHistoryTypes.Pending_Receive,
            Constants.OrderHistoryTypes.Customer_Pickup
        ];
    const history = useSelector((state: any) => state.OrderHistoryReducerState.history);
    const keyRandom = useSelector((state: any) => state.OrderHistoryReducerState.key);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);
    const [notes, setNotes] = useState("");
    //const [notePopup, setNotesPopup] = useState(false);

    const [saveNoteLoader, setSaveNoteLoader] = useState(false);


    const getActiveStep = (step: any[], inpSteps: any[]) => {

        let activeStep = "";
        for (let v = step.length - 1; v >= 0; v--) {
            let filterLabel = inpSteps.indexOf(step[v].label);
            if (filterLabel > -1) {
                filterLabel = filterLabel == inpSteps.length ? inpSteps.length : filterLabel + 1;
                activeStep = inpSteps[filterLabel];
                break;

            }
        }

        return activeStep;

    }

    const getActiveStepNew = (step: any[], orderHistoryStatus: string) => {
        let activeStep = "";
        if (orderHistoryStatus == ShipmentStatusConstants.ReadyForPick) {
            activeStep = Constants.OrderHistoryTypes.Picking
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.ReadyForPack || orderHistoryStatus == ShipmentStatusConstants.ShipmentBeingPacked) {
            activeStep = Constants.OrderHistoryTypes.Packing
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.ShipmentShipped || orderHistoryStatus == ShipmentStatusConstants.Shipped || orderHistoryStatus == ShipmentStatusConstants.ShipmentPacked) {
            activeStep = Constants.OrderHistoryTypes.Shipped
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.PendingReceipts) {
            activeStep = Constants.OrderHistoryTypes.Pending_Receive
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.ReadyForCustomerPickup || orderHistoryStatus == ShipmentStatusConstants.CustomerPickedup) {
            activeStep = Constants.OrderHistoryTypes.Customer_Pickup
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.PartialPendingReceive) {
            activeStep = Constants.OrderHistoryTypes.Customer_Pickup
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.Cancelled) {
            let cancelSteps = step.filter(a => a.label == Constants.OrderHistoryTypes.Cancel_Order
                || a.label == Constants.OrderHistoryTypes.Customer_Contacted
                || a.label == Constants.OrderHistoryTypes.Resource_Item);
            if (cancelSteps.length > 0) {
                activeStep = cancelSteps.slice(-1)[0].label;
            }
        }
        else if (orderHistoryStatus == ShipmentStatusConstants.OnHold) {
            activeStep = Constants.OrderHistoryTypes.OnHold
        }
        return activeStep
    }

    // We need to make sure that Picking is always coming before packing, shipping and Customer Pikcup
    const adjustDateForMissingStep = (steps: IStepOption[], flow: any) => {
        let returnSteps: IStepOption[] = [];
        if (flow == Constants.OrderFlowConstants.PICK_PACK_SHIP) {
            steps = setStepsOrder(stepsOrder, steps);
        }
        else if (flow == Constants.OrderFlowConstants.PICK_PICKUP) {
            steps = setStepsOrder(PickupUpStoreSteps, steps);
        }
        else {
            steps = setStepsOrder(ShipToStoreSteps, steps);
        }


        return steps;
    }

    const setStepsOrder = (stepsInOrder: any[], steps: any[]) => {

        for (let i = 0; i < stepsInOrder.length; i++) {


            let currentStepIndex = steps.findIndex(a => a.label == stepsInOrder[i]);
            let j = i + 1;
            let toIndex: any = null;
            while (j < stepsInOrder.length) {

                let nextStepIndex = steps.findIndex(a => a.label == stepsInOrder[j]);
                if (currentStepIndex > nextStepIndex) {
                    toIndex = nextStepIndex;
                }

                if (toIndex != null && toIndex > -1)
                    break;

                j++;

            }

            if (toIndex != null && toIndex > -1)
                steps = arraymove(steps, currentStepIndex, toIndex);

        }

        return steps;

    }

    const arraymove = (arr: any[], fromIndex: any, toIndex: any) => {
        if (arr[fromIndex] && arr[toIndex]) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        }

        return arr;
    }
    const setParentCustomerContact = (data: any) => {
        let parentCCGuid = "";
        let parentCustomerContact = data.filter((x: any) => x.label == Constants.OrderHistoryTypes.Customer_Contacted && (x.data?.isFollowup == false || x.data?.isFollowup == undefined));
        if (parentCustomerContact.length > 0) {
            parentCCGuid = parentCustomerContact.slice(-1)[0].data.guid;
        }
        dispatch(allActions.contactCustomerActions.changeParentContactCustomer(parentCCGuid));
    }


    const getOrderHistory = async () => {

        dispatch(allActions.orderHistoryActions.setHistoryLoading(true));
        let orderHistory: any = [];
        let orderDetail: any = {};
        let specialCaseResponse: any = {};
        let isSpecialCase = false;
        let isCustomerPickupPage = window.location.href.toLowerCase().indexOf("customerpickup") != -1;
        if (shipmentkey && shipmentkey.length > 0) {
            await Promise.all([
                await ApiService.get("/pf/getOrderHistory?shipmentKey=" + shipmentkey + "&orderNo=" + orderno + "&isCustomerPickupPage=" + isCustomerPickupPage).then((responseOrderHistory: any) => {
                    orderHistory = responseOrderHistory.data.hits.hits;

                }),
                await ApiService.get(`/pf/getOrderFromExStore?shipmentKey=` + shipmentkey).then((response: any) => {

                    orderDetail = response.data.hits.hits.length > 0 ? response.data.hits.hits[0]._source.Shipment : {};
                }),
                await ApiService.get("/pf/checkOrderHistorySpecialCase?shipmentKey=" + shipmentkey + "&orderNo=" + orderno).then((response: any) => {
                    console.log(response, "checkOrderHistorySpecialCase");
                    if (isCustomerPickupPage && response?.success) {

                        specialCaseResponse = response.data;
                        isSpecialCase = specialCaseResponse.isSpecialCase;
                    }
                })
            ]).then(async (data: any) => {
                //if(props.store?.length==1)
                //{
                if (orderHistory.length == 0) {
                    var tDate = moment(orderDetail.OrderDate).tz("America/Los_Angeles");
                    var tDateOrderQueue = moment(orderDetail.StatusDate).tz("America/Los_Angeles");
                    if (orderDetail.Status == ShipmentStatusConstants.ReadyForPick) {//check if status is Picking
                        await Promise.all([
                            await OrderManager.saveOrderHistory({
                                shipmentKey: shipmentkey,
                                logType: "history",
                                transactionType: Constants.OrderHistoryTypes.Order_Release,
                                message: "",
                                transactionDate: moment.utc(tDate).toISOString()
                            }),
                            await OrderManager.saveOrderHistory({
                                shipmentKey: shipmentkey,
                                logType: "history",
                                transactionType: Constants.OrderHistoryTypes.Order_Queue,
                                message: "",
                                shipNode: orderDetail.ShipNode,
                                transactionDate: moment.utc(tDateOrderQueue).toISOString() // what should be here 
                            }),
                        ])

                        getOrderHistory();
                        return;
                    }
                    else {
                        await OrderManager.saveOrderHistory({
                            shipmentKey: shipmentkey,
                            logType: "history",
                            transactionType: Constants.OrderHistoryTypes.Order_Release,
                            message: "",
                            transactionDate: moment.utc(tDate).toISOString()
                        });
                        getOrderHistory();
                        return;
                    }


                }

                // check if Order_Queue exists then add it elase not add it 
                if (orderDetail.Status == ShipmentStatusConstants.ReadyForPick) {
                    const isOrderQueueExists = orderHistory.filter((x: any) => x._source.transactionType == Constants.OrderHistoryTypes.Order_Queue)
                    if (isOrderQueueExists.length == 0) {
                        var OrderQueueDate = moment(orderDetail.StatusDate).tz("America/Los_Angeles");
                        await OrderManager.saveOrderHistory({
                            shipmentKey: shipmentkey,
                            logType: "history",
                            transactionType: Constants.OrderHistoryTypes.Order_Queue,
                            message: "",
                            shipNode: orderDetail.ShipNode,
                            transactionDate: moment.utc(OrderQueueDate).toISOString() // what should be here 
                        })
                        getOrderHistory();
                        return;
                    }
                }
                //}

                let step: IStepOption[] = []
                let activeStep: any = "";

                for (let i = 0; i < orderHistory.length; i++) {
                    let push = true;
                    let item = orderHistory[i]._source ? orderHistory[i]._source : orderHistory[i];
                    // check duplicates for order release
                    if (item.transactionType == Constants.OrderHistoryTypes.Order_Release || item.transactionType == Constants.OrderHistoryTypes.Picking
                        || item.transactionType == Constants.OrderHistoryTypes.Packing || item.transactionType == Constants.OrderHistoryTypes.Shipped
                        || item.transactionType == Constants.OrderHistoryTypes.Customer_Pickup || item.transactionType == Constants.OrderHistoryTypes.Order_Queue) {
                        let filter = step.filter((e: IStepOption) => e.label == item.transactionType);
                        if (filter && filter.length == 0) {
                            push = true;
                        }
                        else {
                            push = false;
                        }
                    }

                    if (push) {
                        step.push({
                            label: item.transactionType,
                            description: item?.message,
                            time: item?.transactionDate,
                            jsonDateTime: moment(item?.transactionDate).valueOf(),
                            data: item,
                            mergedData: item.consolidatedData
                        });
                    }

                }


                let orderHistoryStatus = "";
                if (orderDetail.ShipmentLines.ShipmentLine.length > 0) {

                    orderHistoryStatus = orderDetail.Status
                }




                let ordersFlows = Utils.OrderFlow(orderDetail, isSpecialCase, specialCaseResponse);
                if (ordersFlows == Constants.OrderFlowConstants.PICK_PACK_SHIP) {
                    if (step.filter((a: any) => a.label == Constants.OrderHistoryTypes.Picking).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Picking, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(1)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Packing).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Packing, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(2)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Shipped).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Shipped, description: ``, time: '', jsonDateTime: moment(Utils.getNextDayDate(3)).valueOf() })
                    }
                }
                else if (ordersFlows == Constants.OrderFlowConstants.PICK_PICKUP) {
                    if (step.filter((a: any) => a.label == Constants.OrderHistoryTypes.Picking).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Picking, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(1)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Customer_Pickup).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Customer_Pickup, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(2)).valueOf() })
                    }
                }
                else if (ordersFlows == Constants.OrderFlowConstants.PICK_PACK_SHIP_RECEIVE_PICKUP) {
                    if (step.filter((a: any) => a.label == Constants.OrderHistoryTypes.Picking).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Picking, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(1)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Packing).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Packing, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(2)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Shipped).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Shipped, description: ``, time: '', jsonDateTime: moment(Utils.getNextDayDate(3)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Pending_Receive).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Pending_Receive, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(4)).valueOf() })
                    }
                    if (step.filter((a: IStepOption) => a.label == Constants.OrderHistoryTypes.Customer_Pickup).length == 0) {
                        step.push({ label: Constants.OrderHistoryTypes.Customer_Pickup, description: "", time: '', jsonDateTime: moment(Utils.getNextDayDate(5)).valueOf() })
                    }

                }


                step.sort((a: any, b: any) => { return a.jsonDateTime - b.jsonDateTime })
                step = adjustDateForMissingStep(step, ordersFlows);
                let verticalStepperOptions: IVerticalStepper = {
                    activeStep: getActiveStepNew(step, orderHistoryStatus),
                    stepsOptions: step
                }
                setParentCustomerContact(verticalStepperOptions.stepsOptions);
                verticalStepperOptions = Utils.clubContactCustomerandFollowup(verticalStepperOptions)
                dispatch(allActions.orderHistoryActions.setHistory(verticalStepperOptions));
                console.log(verticalStepperOptions, "verticalStepperOptions");
                dispatch(allActions.orderHistoryActions.setHistoryLoading(false));

            });
        }
    }




    const openNotes = (e: any) => {
        e.stopPropagation()
        dispatch(allActions.actionLoaderActions.setNotesPopup(true));
        //setNotesPopup(true)
        setNotes("")
    }
    const closeNotes = () => {
        //setNotesPopup(false)
        dispatch(allActions.actionLoaderActions.setNotesPopup(false));
    }

    const addNotes = async () => {
        if (notes != "") {
            setSaveNoteLoader(true)

            await OrderManager.saveOrderHistory({
                shipmentKey: shipmentkey,
                logType: "history",
                transactionType: Constants.OrderHistoryTypes.Note,
                message: notes,
                transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
            });
            await getOrderHistory();
            setSaveNoteLoader(false)

            dispatch(allActions.actionLoaderActions.setNotesPopup(false));
            //setNotesPopup(false)
        }
    }

    const changeNote = (event: any) => {
        setNotes(event.target.value);
    }

    let notesPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeNotes,
            // color: Constants.Colors.grey,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        }, {
            text: "Apply",
            icon: <AddCommentIcon />,
            action: addNotes,
            color: notes.length > 1 ? Constants.Colors.red : Constants.Colors.white,
            border: notes.length > 1 ? Constants.Colors.red : Constants.Colors.grey,
            textColor: notes.length > 1 ? Constants.Colors.white : Constants.Colors.grey,

        }
    ];
    let notesCustomerPopup: INotificationPopupSettings = {
        open: notesPopup,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.Note,
        msg: "",
        handleClose: closeNotes,
        isCustomJSX: true,
        customJSX: <Note note={notes} changeNote={changeNote}></Note>,
        actions: notesPopupButtons,
    };
    const renderDetailDrawer = () => {
        return (

            <React.Fragment>

                <Card className="dCard" style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "0px !important", borderBottomRightRadius: "0px !important" }}>
                    <CardContent className={Utils.isMobile ? "" : "dCardContent"} >
                        <div className="topHeading">
                            <div className="gridHeading">
                                <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography className="card-heading"> Add Notes</Typography>
                                    <div
                                        onClick={() => {
                                            // setNotesPopup(false);
                                            dispatch(allActions.actionLoaderActions.setNotesPopup(false));
                                        }}

                                    >
                                        <CloseIcon className="card-heading" />
                                    </div>
                                </Typography>
                            </div>
                        </div>



                        <Stack direction={"row"} style={{ display: "flex", flexWrap: "wrap" }}>
                            <Stack direction={"column"} style={{ flex: "45" }}>

                                <div style={{ height: 150, overflowY: 'auto' }}>
                                    <TextField
                                        onChange={(event) => changeNote(event)}
                                        style={{ textAlign: "left", backgroundColor: "#ffff" }}
                                        fullWidth
                                        InputLabelProps={{ shrink: false }}
                                        id="filled-basic"
                                        placeholder='Note'
                                        variant="outlined"
                                        value={notes}

                                        multiline
                                    />
                                </div>
                            </Stack>

                        </Stack>

                        <Stack spacing={2} direction={"row"} style={{ display: "flex", justifyContent: "space-around", marginTop: "8px" }}>

                            <Button
                                disabled={notes === ""}
                                style={{ width: "30%" }}
                                variant="contained"
                                color="primary"
                                className="red-outline-btn"
                                onClick={() => {
                                    closeNotes()
                                }}
                            >
                                Cancel
                            </Button>

                            {saveNoteLoader ? <TailSpin color="#D93A2F" height={25} width={25} /> : <Button
                                style={{ width: "30%" }}
                                disabled={notes === ""}
                                variant="contained"
                                color="primary"
                                className={notes === "" ? "header-button" : "red-filled-btn"}
                                onClick={() => {
                                    addNotes()
                                }}
                            >
                                Apply
                            </Button>}

                        </Stack>


                    </CardContent>
                </Card>
            </React.Fragment>


        );
    };






    const webView = () => {

        if (isHistoryLoading) {
            return <BulletShimmer />
        }
        else {
            return (
                <Card className='dCard' style={{ marginTop: 16 }}>
                    <CardContent className='history-card-Content'>
                        <Grid container display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 10 }}>
                            <Grid item xs={10} sm={10} style={{ textAlign: "left" }}>
                                <Typography className='history-heading'>Fulfillment History</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                {Utils.disableUserAction(props.store) && <React.Fragment>
                                    {
                                        !props.orderDetail &&
                                        <Tooltip title="Add Note">
                                            <IconButton onClick={(e) => openNotes(e)} style={{ padding: 0, float: "right", height: 36, width: 36, borderRadius: 8, background: "#96CBF6" }}>
                                                <SpeakerNotesOutlinedIcon style={{ color: "#24499A", fontSize: 20 }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </React.Fragment>}
                            </Grid>
                        </Grid>
                        <Divider />
                        <div style={{ padding: "10px" }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} className='inputField'>
                                    <Box style={{ maxHeight: 300, overflowY: "scroll" }}>
                                        <VerticalLinearStepper {...history}></VerticalLinearStepper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div >
                    </CardContent>
                </Card>);
        }


    }

    const mobileView = () => {

        if (isHistoryLoading) {
            return <BulletShimmer />
        }
        else {
            return (
                <Accordion style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: Constants.Colors.darkGrey, height: 80 }}
                    >
                        <div className="topHeading">
                            <div className="gridHeading" style={{ display: "flex", justifyContent: 'space-between' }}>
                                <Typography>Fulfillment History</Typography>
                                {
                                    !props.orderDetail &&
                                    <Tooltip title="Add Note">
                                        <IconButton onClick={(e) => openNotes(e)} style={{ padding: 0, float: "right", height: 36, width: 36, borderRadius: 8, background: "#96CBF6" }}>
                                            <SpeakerNotesOutlinedIcon style={{ color: "#24499A" }} />
                                        </IconButton>
                                    </Tooltip>

                                }
                            </div>

                        </div>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>
                        <div style={{ margin: "6px" }}>
                            <div style={{ padding: "10px", maxHeight: Utils.getMobileDrawerHeight, overflowY: "scroll" }}>

                                <VerticalLinearStepper {...history}></VerticalLinearStepper>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            )
        }

    }

    React.useEffect(() => {


        async function Init() {
            setTimeout(() => getOrderHistory(), 500);
        }

        Init();

        return () => {

        }
    }, [keyRandom]);

    return (
        <div>


            {Utils.isMobile && <Drawer
                anchor={"bottom"}
                open={notesPopup}
                onClose={() => { }}


                PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
            >
                {renderDetailDrawer()}
            </Drawer>}
            {!Utils.isMobile && <NotificationPopup {...notesCustomerPopup}></NotificationPopup>}
            {Utils.isMobile ? mobileView() : webView()}
        </div>

    )
}

export default OrderHistory;