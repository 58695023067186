import React, { useEffect, useRef, useState } from "react";
import "./Routes.scss";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Utils from "../Common/Utils";
import Box from "@material-ui/core/Box";
import {
  Card,
  CardContent,
  Chip,
  Divider,
  InputBase,
  Link,
  TextField,
  Tooltip,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import DataTable from "react-data-table-component";
import { IconButton, Paper, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SiteBreadCrumbs from "../Subcomponent/Shared/SiteBreadCrumbs";
import {
  ReceiptLongIcon,
  FilterAltIcon,
  DocumentScannerIcon,
  SearchIcon,
  CloseIcon,
  ReportProblemIcon,
  ArrowForwardIcon,
  UnfoldMoreSharpIcon,
  ArrowCircleDownOutlinedIcon,
} from "../Subcomponent/Shared/SharedIcons";
import MobileBarCodeScannerTrackingNumberScandit from "../Subcomponent/ReceiveOrders/MobileBarCodeScannerTrackingNumberScandit";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import {
  INotificationPopupSettings,
  IButton,
} from "../Models/INotificationPopupSettings";
import DummyDataManager from "../Managers/DummyDataManager";
import allActions from "../Store/Actions/AllActions";
import Constants from "../Common/Constants";
import Drawer from "@mui/material/Drawer";
import OrderManager from "../Managers/Orders.Manager";
import StoreManager from "../Managers/Store.manager";
import IdentityManager from "../Managers/Identity.manager";
import { OrderDetailNotificationPopup } from "../Subcomponent/Shared/OrderDetailNotificationPopup";
import { ReportErrorPopup } from "../Subcomponent/Shared/ReportErrorPopup";
import "./ReceiveOrders.scss";
import { ServiceNowCnfrmText } from "../Subcomponent/Shared/ServiceNowCnfrmText";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import LabelText from "../Common/LabelText";

const ReceiveOrders = (props: any) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [status, setStatus] = useState(0);
  const pathname = window.location.pathname;
  const [openCarrierMsg, setCarrierMsg] = useState(false);
  const scannerInputRef: any = useRef(null);
  const trackingInputRef: any = useRef(null);
  // generate random Data fro faker js
  //const defaultPageSize = Utils.isMobile ? 30 : 25
  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  };
  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  };
  var startProductBarPos = -1;
  window.onscroll = function () {
    var bar = document.getElementById("paginationWebId") as any;
    var dataTable = document.getElementById("containerDataTable") as any;
    if (bar && dataTable) {
      var dataTableWidth = dataTable.offsetWidth;
      if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
      if (
        window.pageYOffset > startProductBarPos &&
        window.pageYOffset - startProductBarPos > 50
      ) {
        bar.style.width = dataTableWidth - (0.8 * dataTableWidth) / 100 + "px";
        bar.classList.add("fixedPagination");
        bar.style.marginTop = Utils.getWebbarheight + "px";
      } else {
        bar.classList.remove("fixedPagination");
        bar.style.width = "";
        bar.style.marginTop = "";
      }
    }
    // Mobile View Fix the Tabs and FILTER
    if (Utils.isMobile) {
      console.log("scrolled");
      bar = document.getElementById("tabFilterContaoner") as any;
      var mblBar = document.getElementById("mblBar") as any;
      if (bar) {
        if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
        if (window.pageYOffset > startProductBarPos) {
          bar.classList.add("scrolledTopFix");
        } else {
          bar.classList.remove("scrolledTopFix");
        }
      }
    }
  };
  function findPosY(obj: any) {
    var curtop = 0;
    if (obj && typeof obj.offsetParent != "undefined" && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    } else if (obj && obj.y) curtop += obj.y;
    return curtop;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [rowDetail, setRowDetail] = useState({} as any);
  let orderRows = useSelector(
    (state: any) => state.ReceiveOrdersReducersState.receiveOrders
  );
  let allOrderRows = useSelector(
    (state: any) => state.ReceiveOrdersReducersState.allReceiveOrders
  );
  let userSettings = useSelector(
    (state: any) => state.ActionLoaderReducersState.userSettings
  );
  // Drawer Code
  const anchor = "right";
  const [openDrawer, setDrawer] = useState(false);
  const [isScan, setScan] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openError, setOpenError] = useState(false);
  const [searchTracking, setSearchTracking] = useState("");
  const [data, setData] = useState([] as any);

  const toggleDrawer = (op: any) => {
    setDrawer(op);
  };
  // Pagination
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    userSettings?.pagination ?? Constants.UserSettingsDefault.Pagination
  );

  //Report Error Action Use State
  const [reportErrorAction, setReportErrorAction] = useState("");

  // Report Error Pop Up Use States
  const [openReportError, setOpenReportError] = useState(false);
  const [openAlreadyReportedError, setOpenAlreadyReportedError] =
    useState(false);
  const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
  const [errorReportMsg, setErrorReportMsg] = useState("");
  const storesPopUp = useSelector(
    (state: any) => state.ActionLoaderReducersState.openDrawerDropdowns
  );

  const openDetailDrawerClick = (id: any) => {
    let selectedOrder = orderRows.filter((order: any) => {
      return order._id == id;
    });
    if (selectedOrder && selectedOrder.length > 0) {
      setRowDetail(selectedOrder[0]);
      setOpenDetailDrawer(true);
    }
    setOpenDetailDrawer(true);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(+parseInt(event.target.value));
    setPage(0);
    updatePaginationSettings(parseInt(event.target.value));
  };

  const updatePaginationSettings = (pageSize: number) => {
    const updatedSettings = {
      ...userSettings,
      pagination: pageSize,
    };
    dispatch(allActions.actionLoaderActions.setUserSettings(updatedSettings));
    //Update User Settings in Dynamo
    const payload = {
      attributeName: Constants.UserSettingsFields.Pagination,
      value: pageSize,
      userName: userSettings.userName,
    };
    Utils.updateUserSettings(payload);
  };

  const updateStatusState = () => {
    setStatus(status + 1);
  };

  const openInfoPopup = () => {
    setOpenInfo(true);
    updateStatusState();
  };

  const closeInfoPopup = () => {
    setOpenInfo(false);
  };

  let Info = {
    open: openInfo,
    msg: "The Order Status has been changed. Your screen will automatically refresh now.",
    handleClose: closeInfoPopup,
    zIndex: 10000000,
  };

  const handleChangeOrder = async (row: any) => {
    showActionLoader();
    let detailStatus = true;
    await Utils.checkOrderDetailStatus({
      shipmentKey: row._source?.Shipment?.ShipmentKey,
      status: row._source?.Shipment?.Status,
    })
      .then((res: any) => {
        if (res?.success) {
          detailStatus = res.data;
        }
      })
      .catch((err: any) => {
        console.log("Order Detail Status Error", err);
      });
    if (detailStatus) {
      hideActionLoader();
      if (
        row._source.Shipment.ExtnPickupStoreID &&
        row._source.Shipment.ExtnPickupStoreID > 0
      ) {
        let extnPickupStoreID = row._source.Shipment.ExtnPickupStoreID;
        navigate(
          "/PendingReceipt?orderno=" +
            row._source?.Shipment?.OrderNo +
            "&shipmentkey=" +
            row._source?.Shipment?.ShipmentKey +
            "&shipmentcontainerkey=" +
            row._source?.Shipment?.Containers?.Container[0]
              ?.ShipmentContainerKey +
            "&store=" +
            extnPickupStoreID
        );
      } else {
        navigate(
          "/PendingReceipt?orderno=" +
            row._source?.Shipment?.OrderNo +
            "&shipmentkey=" +
            row._source?.Shipment?.ShipmentKey +
            "&shipmentcontainerkey=" +
            row._source?.Shipment?.Containers?.Container[0]
              ?.ShipmentContainerKey
        );
      }
    } else {
      hideActionLoader();
      openInfoPopup();
    }
  };
  const WebGridButton = (row: any) => {
    return (
      <React.Fragment>
        <Button
          className="red-outline-btn"
          startIcon={<ArrowCircleDownOutlinedIcon className="fTabIcons" />}
          variant="contained"
          color="primary"
          onClick={() => {
            handleChangeOrder(row);
          }}
        >
          Receive
        </Button>
      </React.Fragment>
    );
  };
  const getItemDescription = (row: any) => {
    let { ItemDesc } = row._source?.Shipment?.ShipmentLines?.ShipmentLine[0];
    return (
      <Tooltip title={ItemDesc}>
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {ItemDesc}
        </p>
      </Tooltip>
    );
  };

  const redirectToCarrier = (carrier: any, trackNo: any) => {
    const carrierURL = Utils.getCarrierURL(carrier);
    if (carrierURL) {
      const carrierService = carrierURL + trackNo;
      window.open(carrierService, "_blank");
    } else {
      setCarrierMsg(true);
    }
  };

  const getCarrier = async (trackNo: any) => {
    try {
      showActionLoader();
      let carrierResult = "";
      let carrier = await PFManager.getCarrierFromShippingCenter(trackNo);
      hideActionLoader();
      if (carrier?.success) {
        carrierResult = carrier?.data
          ? carrier?.data
          : Utils.findCarrier(trackNo);
      } else {
        carrierResult = Utils.findCarrier(trackNo);
      }
      redirectToCarrier(carrierResult, trackNo);
    } catch (error: any) {
      hideActionLoader();
      console.log("Get Carrier Error", error);
      const msg = LabelText.errorMessage;
      setErrorMsg(msg);
      setOpenError(true);
    }
  };

  const getTrackingNo = (row: any) => {
    let trackingNo = "";
    if (row._source?.Shipment?.Containers?.Container?.length > 0) {
      trackingNo = row._source?.Shipment?.Containers?.Container[0]?.TrackingNo;
      return (
        <span
          className="clickable-copyable-link"
          onClick={() => getCarrier(trackingNo)}
        >
          {trackingNo}
        </span>
      );
    }
    return <></>;
  };

  const getOrderNumberWithType = (row: any) => {
    return (
      <div>
        <span>{row._source.Shipment.OrderNo}</span>
        {getOrderType(row)}
      </div>
    );
  };

  const getIsProCov = (row: any) => {
    let isProCov = false;
    let container = row?._source?.Shipment?.Containers;
    if (
      container?.Container?.length > 0 &&
      container?.Container[0]?.ContainerDetails?.ContainerDetail?.length > 0
    ) {
      let orderLineKeys =
        container?.Container[0]?.ContainerDetails?.ContainerDetail?.map(
          (detail: any) => detail.OrderLineKey
        );
      for (const lineKey of orderLineKeys || []) {
        let shipmentLine =
          row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.find(
            (order: any) => order.OrderLineKey == lineKey
          );
        if (shipmentLine?.ExtnIsWarrantyItem == "Y") {
          isProCov = true;
          break;
        }
      }
    }
    return isProCov;
  };

  const getOrderType = (row: any) => {
    const proCov = getIsProCov(row);
    let title = "PRO COV";
    let cellValue = "PC";

    return (
      <div>
        <Tooltip title={title}>
          {proCov ? (
            <Chip
              className="chipTracking"
              label={cellValue}
              style={{
                background: "#A1D899",
                color: "#006100",
                fontSize: "10px",
                height: "16px",
                borderRadius: "4px",
              }}
            />
          ) : (
            <></>
          )}
        </Tooltip>
      </div>
    );
  };

  const getMobileOrderType = (row: any) => {
    const proCov = getIsProCov(row);
    let cellValue = "PC";

    return (
      <React.Fragment>
        {proCov ? (
          <Chip
            className="chipTracking"
            label={cellValue}
            style={{
              background: "#A1D899",
              color: "#006100",
              fontSize: "10px",
              height: "15px",
              borderRadius: "4px",
              marginLeft: 4,
            }}
          />
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const getColumns = () => {
    const columns = [
      {
        name: "",
        cell: (row: any) => WebGridButton(row),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "200px",
        width: "200px",
      },
      {
        name: "Tracking #",
        cell: (row: any) => getTrackingNo(row),
        sortable: true,
        minWidth: "180px",
        width: "180px",
      },
      {
        name: "Order #",
        cell: (row: any) => getOrderNumberWithType(row),
        selector: (row: any) => row._source?.Shipment?.OrderNo,
        sortable: true,
        minWidth: "160px",
        width: "160px",
      },
      // {
      //     name: 'Status',
      //     selector: () => "Pending Receipt",
      //     //selector: (row: any) => row._source?.Shipment?.StatusName == "PENDING_RECEIPT" ? "Pending Receipt" : row._source?.Shipment?.StatusName,
      //     sortable: true,
      //     minWidth: "170px",
      //     width: "170px"
      // },
      {
        name: "Age (days)",
        selector: (row: any) =>
          row._source?.Shipment?.Age <= 0
            ? "Today"
            : row._source?.Shipment?.Age,
        sortable: true,
        minWidth: "120px",
        width: "120px",
      },
      {
        id: "Store",
        name: "Store",
        selector: (row: any) => row._source.Shipment?.ExtnPickupStoreID,
        sortable: true,
        minWidth: "85px",
        width: "85px",
        omit: props.store?.length <= 1,
      },
      {
        name: "Item Description",
        cell: (row: any) => getItemDescription(row),
        sortable: true,
        width: Utils.getDescriptionWidthReceiveOrders("") + "px",
      },
    ];
    return columns;
  };

  const renderMobileFilters = () => {
    return (
      <Box
        sx={{ p: 3 }}
        style={{
          textAlign: "left",
          padding: "5px",
          paddingLeft: "5px",
          paddingTop: "5px",
        }}
      >
        {searchBar()}
      </Box>
    );
  };
  const renderWebDataTable = () => {
    return (
      <div className="pendingReceiptsDataTable">
        <DataTable
          className="pendingReceiptsDataTable"
          title=""
          data={orderRows}
          columns={getColumns()}
        />
      </div>
    );
  };
  const renderMobileData = () => {
    // return <div className='mobileContainer'>
    //     <div className='mobileFulfilmentDataTable'>
    //         {orderRows.map((row: any, index: number) => {
    //             return (
    //                 <div style={{ background: index % 2 === 0 ? 'white' : '#F9F9F9' }} className='mobileRow'>
    //                     <div className='mobileCellLeft' style={{height:props.store?.length > 1 ? "222px":"100%"}}>
    //                         <Typography className='cellMeta'>
    //                             Order #
    //                         </Typography>
    //                         <Typography className='cellMeta'>
    //                             Tracking #
    //                         </Typography>
    //                         <Typography className='cellMeta'>
    //                             Age
    //                         </Typography>
    //                         {
    //                             props.store?.length > 1 ?
    //                                 <Typography className='cellMeta'>
    //                                     Store
    //                                 </Typography>
    //                                 :
    //                                 <></>
    //                         }
    //                         <Typography className='cellMeta'>
    //                             Desc
    //                         </Typography>
    //                     </div>
    //                     <div onClick={() => { props.detailDrawerOpen(row._id) }} className='mobileCellRight'>
    //                         <Typography style={{ fontFamily: "Roboto Mono" }} className='orderIdLabel' >
    //                             <Typography className='cellInfoMain' style={{paddingTop:"2px"}}>
    //                                 {row._source?.Shipment?.OrderNo}
    //                             </Typography>
    //                             <div style={{ display: "flex", justifyContent: "flex-end", gap: "2px" }}>
    //                                 <div
    //                                     className="red-outline-btn" onClick={() => { handleChangeOrder(row) }}>
    //                                     {<ArrowCircleDownOutlinedIcon style={{ fontSize: 17 }} />}
    //                                     <Typography style={{ fontSize: "12px" }}>
    //                                         Receive
    //                                     </Typography>

    //                                 </div>
    //                                 {<UnfoldMoreSharpIcon onClick={() => { openDetailDrawerClick(row._id) }} style={{ transform: "rotate(30deg)" }}></UnfoldMoreSharpIcon>}
    //                             </div>
    //                         </Typography>

    //                         <Typography className='cellInfoMain'>
    //                             {row._source?.Shipment?.Containers?.Container?.length> 0 ?<div style={{color:"#1976d2",textDecoration:"underline"}} onClick={()=>{getCarrier(row._source?.Shipment?.Containers?.Container[0]?.TrackingNo)}}>
    //                                 {row._source?.Shipment?.Containers?.Container[0]?.TrackingNo}
    //                             </div>:<></>}
    //                             {getMobileOrderType(row)}
    //                         </Typography>

    //                         <Typography className='cellInfo' style={{paddingTop:"3px"}}>
    //                             {row._source?.Shipment?.Age <= 0 ? "Today" : row._source?.Shipment?.Age} days
    //                         </Typography>
    //                         {
    //                             props.store?.length > 1 ?
    //                                 <Typography className='cellInfo' style={{paddingTop:"1px"}}>
    //                                     {row._source.Shipment?.ExtnPickupStoreID}
    //                                 </Typography>
    //                                 :
    //                                 <></>
    //                         }
    //                         <Typography className='cellInfo'>
    //                             {row._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.ItemDesc}
    //                         </Typography>
    //                     </div>
    //                 </div>
    //             );
    //         })
    //         }
    //     </div ></div>
    return (
      <React.Fragment>
        {orderRows?.length > 0 ? (
          orderRows.map((row: any, index: number) => {
            return (
              <Card style={{ margin: 4 }}>
                <CardContent
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <Grid container style={{ margin: "0px" }}>
                    <Grid
                      onClick={() => {
                        openDetailDrawerClick(row._id);
                      }}
                      xs={3}
                      sm={3}
                      style={{
                        paddingTop: 4,
                        paddingLeft: 0,
                        color: Constants.Colors.black,
                        background: "#ddd",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "11px",
                          fontWeight: "425",
                          marginLeft: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {row._source?.Shipment?.OrderNo}
                      </Typography>
                      {
                        <UnfoldMoreSharpIcon
                          onClick={() => {
                            openDetailDrawerClick(row._id);
                          }}
                          style={{
                            transform: "rotate(30deg)",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            fontSize: "20px",
                            color: "#e7131a",
                          }}
                        ></UnfoldMoreSharpIcon>
                      }
                    </Grid>
                    <Grid
                      onClick={() => {
                        openDetailDrawerClick(row._id);
                      }}
                      item
                      xs={7}
                      sm={7}
                      style={{
                        paddingTop: 3,
                        paddingLeft: 3,
                        textAlign: "left",
                        verticalAlign: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          color: Constants.Colors.black,
                          fontSize: "12px",
                          fontWeight: "425",
                        }}
                      >
                        {
                          row._source?.Shipment?.ShipmentLines?.ShipmentLine[0]
                            ?.ItemDesc
                        }
                      </Typography>
                      <Typography style={{ fontSize: "12px" }}>
                        <span
                          className="clickable-copyable-link"
                          onClick={(event) => {
                            event?.stopPropagation();
                            getCarrier(
                              row._source?.Shipment?.Containers?.Container[0]
                                ?.TrackingNo
                            );
                          }}
                        >
                          {
                            row._source?.Shipment?.Containers?.Container[0]
                              ?.TrackingNo
                          }
                        </span>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "11px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {props.store?.length > 1 && (
                            <>
                              <span style={{ color: "black", fontWeight: 500 }}>
                                Store:
                              </span>
                              <span
                                className="receiveMobileLabel"
                                style={{ marginLeft: "2px", marginRight: 4 }}
                              >
                                {row._source.Shipment?.ExtnPickupStoreID}
                              </span>
                            </>
                          )}
                          <span style={{ color: "black", fontWeight: 500 }}>
                            Age:
                          </span>
                          <span
                            className="receiveMobileLabel"
                            style={{ marginLeft: "2px" }}
                          >
                            {row._source?.Shipment?.Age <= 0
                              ? "Today"
                              : row._source?.Shipment?.Age}{" "}
                            days
                          </span>
                          {getMobileOrderType(row)}
                        </div>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingRight: "4px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleChangeOrder(row);
                        }}
                        className="mobile-list-btn"
                      >
                        {
                          <ArrowCircleDownOutlinedIcon
                            style={{ fontSize: 18, marginRight: 2 }}
                          />
                        }
                        <Typography style={{ fontSize: "11px" }}>
                          Receive
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <p>No Data Found</p>
        )}
      </React.Fragment>
    );
  };

  const closeErrorPopup = () => {
    setOpenError(false);
  };

  const openErrorPopup = () => {
    setOpenError(true);
  };

  // Popups

  // Error Popup

  // Buttons When there is no Action
  let errorPopupCloseButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeErrorPopup,
      color: Constants.Colors.red,
    },
  ];

  let Error: INotificationPopupSettings = {
    open: openError,
    type: Constants.NotificationPopupType.Error,
    title: "Error",
    msg: errorMsg,
    draggable: false,
    handleClose: closeErrorPopup,
    actions: errorPopupCloseButtons,
  };

  const closeCarrierMsg = () => {
    setCarrierMsg(false);
  };

  let carrierMsgButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeCarrierMsg,
      color: Constants.Colors.blue,
    },
  ];

  let carrierMsg: INotificationPopupSettings = {
    open: openCarrierMsg,
    type: Constants.NotificationPopupType.Info,
    title: "Info",
    msg: Utils.getCarrierMsg(),
    draggable: false,
    handleClose: closeCarrierMsg,
    actions: carrierMsgButtons,
  };

  const changeSearchTracking = (e: any) => {
    setSearchTracking(e.target.value);
    trackingInputRef.current.value = "";
  };

  const handleScan = (e: any) => {
    setSearchTracking(e.target.value);
    scannerInputRef.current.value = "";
  };

  const onScannedComplete = (data: any, isError: any) => {
    setSearchTracking(data);
    setScan(false);
  };

  const renderPaginationAndSearch = () => {
    if (!Utils.isMobile) {
      return <React.Fragment>{pagination()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const renderDetailDrawer = () => {
    return (
      <React.Fragment>
        <Card className="dCard" style={{ backgroundColor: "#F5F5F5" }}>
          <CardContent className={Utils.isMobile ? "" : "dCardContent"}>
            <div className="topHeading">
              <div className="gridHeading">
                <Typography
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography className="card-heading">
                    {" "}
                    Order Details
                  </Typography>
                  <div
                    onClick={() => {
                      setOpenDetailDrawer(false);
                    }}
                  >
                    <CloseIcon className="card-heading" />
                  </div>
                </Typography>
              </div>
            </div>
            <Stack
              direction={"row"}
              style={{ display: "flex", gap: "20", flexWrap: "wrap" }}
            >
              <Stack spacing={2} direction={"column"} style={{ flex: 1 }}>
                <Typography className="itemHeading">
                  {
                    rowDetail._source?.Shipment?.ShipmentLines?.ShipmentLine[0]
                      ?.ItemDesc
                  }
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "20",
                flexWrap: "wrap",
              }}
            >
              <Stack spacing={2} direction={"column"} style={{ flex: "60" }}>
                <Typography className="itemHeading">Tracking #</Typography>
                <Typography className="itemElement">
                  {
                    rowDetail._source?.Shipment?.Containers?.Container[0]
                      ?.TrackingNo
                  }
                </Typography>
              </Stack>
              <Stack spacing={2} direction={"column"} style={{ flex: "40" }}>
                <Typography className="itemHeading">Order Number</Typography>
                <Typography className="itemElement">
                  {rowDetail._source?.Shipment?.OrderNo}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "20",
                flexWrap: "wrap",
              }}
            >
              <Stack spacing={2} direction={"column"} style={{ flex: "45" }}>
                <Typography className="itemHeading">Status</Typography>
                <Typography className="itemElement">
                  {rowDetail._source?.Shipment?.StatusName == "PENDING_RECEIPT"
                    ? "Pending Receipt"
                    : rowDetail._source?.Shipment?.StatusName}
                </Typography>
              </Stack>
              {props.store?.length > 1 ? (
                <Stack spacing={2} direction={"column"} style={{ flex: "45" }}>
                  <Typography className="itemHeading">Store</Typography>
                  <Typography className="itemElement">
                    {rowDetail._source.Shipment?.ExtnPickupStoreID}
                  </Typography>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const pagination = () => {
    return (
      <div id="paginationWebId" className="pendingReceiptsPaginationWeb">
        <div style={{ margin: "auto" }}>
          <Stack spacing={1} style={{ paddingTop: 7, marginLeft: 281 }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </div>
        <div>{searchBar()}</div>
      </div>
    );
  };

  const searchBar = () => {
    return (
      <Paper
        elevation={0}
        style={{
          width: "auto",
          marginRight: Utils.isMobile ? 0 : 10,
          marginBottom: Utils.isMobile ? 0 : 9,
        }}
        className={!Utils.isMobile ? "searchWeb" : ""}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          background: "white",
          border: "1px solid #bbbbbb",
          borderRadius: "6px",
        }}
      >
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          value={searchTracking}
          inputRef={trackingInputRef}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Tracking #"
          inputProps={{ "aria-label": "search google maps" }}
          style={{ marginLeft: 10 }}
          onChange={(e: any) => changeSearchTracking(e)}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          {/* <div onClick={() => { setSearchTracking("") }}> */}
          <CloseIcon
            onClick={() => {
              setSearchTracking("");
            }}
          ></CloseIcon>
          {/* </div> */}
        </IconButton>
        {Utils.isMobile ? (
          <IconButton
            style={{ color: Constants.Colors.red }}
            type="submit"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => setScan(true)}
          >
            <DocumentScannerIcon style={{ transform: "rotate(90deg)" }} />
          </IconButton>
        ) : (
          <></>
        )}
      </Paper>
    );
  };
  const getDataFromElastic = async () => {
    //
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (!storeId) {
      return;
    }

    showActionLoader();
    let storeID = storeId; // "110"
    let resultResponse = {} as any;
    try {
      resultResponse = await OrderManager.getReceiveOrders(storeID);
      if (resultResponse.success) {
        let result = resultResponse.data;
        let total = result.hits.total.value;
        let dataRows = result.hits.hits;
        dataRows.forEach((element: any) => {
          element._source.Shipment["Age"] = Utils.getOrderAge(element);
        });

        dataRows.sort((a: any, b: any) => {
          return b._source.Shipment.Age - a._source.Shipment.Age;
        });

        if (dataRows.length > 0) {
          let from = page * rowsPerPage;
          let to = from + rowsPerPage;
          let paginated = dataRows?.slice(from, to);
          dispatch(allActions.receiveOrdersActions.allReceiveOrders(dataRows));
          dispatch(allActions.receiveOrdersActions.receiveOrders(paginated));
          setTotalRecords(total);
        } else {
          dispatch(allActions.receiveOrdersActions.allReceiveOrders([]));
          dispatch(allActions.receiveOrdersActions.receiveOrders([]));
        }
      } else {
        hideActionLoader();
        let message =
          "Something went wrong please contact the IT Help Desk for assistance";
        console.log(resultResponse.msg);
        setErrorMsg(message);
        setErrorReportMsg(message);
        setReportErrorAction(
          Constants.REPORT_ERROR_ACTIONS.RECEIVE_ORDER_FRM_ELASTIC
        );
        openErrorPopup();
      }
    } catch (e) {
      hideActionLoader();
      let message =
        "Something went wrong please contact the IT Help Desk for assistance";
      console.log(resultResponse.msg);
      setErrorMsg(message);
      setErrorReportMsg(message);
      setReportErrorAction(
        Constants.REPORT_ERROR_ACTIONS.RECEIVE_ORDER_FRM_ELASTIC
      );
      openErrorPopup();
    }

    hideActionLoader();
  };
  const searchInStore = () => {
    let filterd = [] as any;
    if (searchTracking == "") {
      let from = page * rowsPerPage;
      let to = from + rowsPerPage;
      let paginated = allOrderRows?.slice(from, to);
      dispatch(allActions.receiveOrdersActions.receiveOrders(paginated));
      setTotalRecords(allOrderRows.length);
    } else {
      allOrderRows.forEach((order: any) => {
        order._source?.Shipment?.Containers?.Container?.forEach(
          (container: any) => {
            //if(container.TrackingNo.toLowerCase().indexOf(searchTracking.toLowerCase()) > -1 )
            //************OLD CODE ************** */
            // if (
            //     container.TrackingNo.toLowerCase() == searchTracking.toLowerCase()
            //   ) {
            //     filterd.push(order);
            //   }
            //************New CODE ************** */
            if (
              container.TrackingNo.toLowerCase().startsWith(
                searchTracking.toLowerCase()
              )
            ) {
              filterd.push(order);
            }
          }
        );
      });
      if (filterd.length > 0) {
        //
        let from = page * rowsPerPage;
        let to = from + rowsPerPage;
        let paginated = filterd?.slice(from, to);
        dispatch(allActions.receiveOrdersActions.receiveOrders(paginated));
        setTotalRecords(filterd.length);
      } else {
        dispatch(allActions.receiveOrdersActions.receiveOrders([]));
        setTotalRecords(filterd.length);
      }
    }
  };

  useEffect(() => {
    if (!Utils.isMobile) return;

    const focusInput = () => {
      //Check if any input pop up is open or not
      if (
        scannerInputRef.current &&
        document.activeElement !== trackingInputRef.current &&
        !storesPopUp &&
        !props.searchPopup &&
        !isScan &&
        !openDetailDrawer
      ) {
        scannerInputRef.current.focus();
      }
    };

    focusInput();

    // Refocus input every second
    const interval = setInterval(() => {
      focusInput();
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [isScan, storesPopUp, props.searchPopup, openDetailDrawer]);

  React.useEffect(() => {
    async function search() {
      await searchInStore();
    }
    search();
  }, [searchTracking]);

  React.useEffect(() => {
    async function sendRequest() {
      await getDataFromElastic();
    }
    sendRequest();
    return () => {};
  }, [props.store, page, rowsPerPage, status]);
  return (
    <div>
      {isScan ? (
        <MobileBarCodeScannerTrackingNumberScandit
          onScanned={onScannedComplete}
        />
      ) : (
        <div>
          <NotificationPopup {...Error}></NotificationPopup>
          <OrderDetailNotificationPopup
            {...Info}
          ></OrderDetailNotificationPopup>
          <OrderDetailNotificationPopup
            {...carrierMsg}
          ></OrderDetailNotificationPopup>
          <Box
            sx={{ p: 4, display: "flex", justifyContent: "space-between" }}
            style={{ padding: Utils.isMobile ? "0px" : "10px" }}
          >
            {/* {Utils.isMobile && <Typography style={{ fontWeight: 700, fontSize: "32px" }} variant="h5">
                            Pending Receipt
                        </Typography>} */}
          </Box>
          <div
            style={{ marginTop: 0 }}
            className={
              Utils.isMobile ? "innerContianerMobile" : "innerContianer"
            }
          >
            {Utils.isMobile && (
              <input
                type="text"
                name="zebraScanner"
                inputMode="none"
                ref={scannerInputRef}
                onChange={handleScan}
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                spellCheck={false}
                autoFocus
                style={{
                  opacity: 0,
                  top: 0,
                  left: 0,
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  border: "none",
                  outline: "none",
                }}
              />
            )}
            <div id="tabFilterContaoner">
              {!Utils.isMobile ? <div></div> : renderMobileFilters()}
            </div>
            {renderPaginationAndSearch()}
            {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}
          </div>
        </div>
      )}
      {openDetailDrawer ? (
        <Drawer
          anchor={"bottom"}
          open={openDetailDrawer}
          onClose={() => {}}
          onClick={() => {
            setOpenDetailDrawer(false);
          }}
          style={{ zIndex: 9999999 }}
          PaperProps={{
            elevation: 0,
            style: { backgroundColor: "transparent" },
          }}
        >
          {renderDetailDrawer()}
        </Drawer>
      ) : (
        <></>
      )}
    </div>
  );
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        style={{ marginTop: Utils.isMobile ? "-14px" : "" }}
      >
        <Box sx={{ p: 3 }} style={{ padding: "5px" }} id="containerDataTable">
          <Typography component="div">{children}</Typography>
        </Box>
      </div>
    );
  }
};
export default ReceiveOrders;
