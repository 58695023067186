import Constants from "./Constants";
//import moment from "moment";

import { IVerticalStepper, IStepOption } from "../Models/IVerticalStepper"
import StoreManager from "../Managers/Store.manager";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import IdentityManager from "../Managers/Identity.manager";
import logger, { ILogger } from "./logger";
import cryptoRandomString from 'crypto-random-string';
import OMSManager from "../Managers/Oms.manager";
import ExpandedRow from "../Subcomponent/FulfillOrders/ExpandedRow";
import { ICustomerPickupDetail, ContainerDetail, Component } from "../Models/ICustomerPickupDetail"
import { OmsPickOrderDetail, ShipmentLine } from '../Models/OmsPickOrderDetail';
import { ICustomerDetail } from '../Models/IPickOrder'
import ShipmentStatusConstants from "./ShipmentStatusConstants";
import IStoreInfo from "../Models/IStoreInfo";
import { ICustomerContactReport, IDistrictCCData, IStoreCCData } from "../Models/ICustomerContactReport";
import { IDistrictROData, IResourceOrderReport, IStoreROData } from "../Models/IResourceOrderReport";

import { IDistrictShippingData, IShippingReport, IStoreShippingData } from "../Models/IShippingReport";
import { IDistrictGridData } from "../Models/IDashboardGridData";
import { IDistrictFulfilledData, IFulfilledReport, IStoreFulfilledData } from "../Models/IFulfilledOrders";
import { IDistrictOrderTimeData, IOrderTimeReport, IStoreOrderTimeData } from "../Models/IOrderTimeReport";
import { log } from "console";
import IUserSettings from "../Models/IUserSettings";
import UserManager from "../Managers/User.Manager";
import LabelText from "./LabelText";


var momenttz = require('moment-timezone');
var moment = require('moment');
const { v4: uuidv4 } = require('uuid');

export default class Utils {

    public static startDate = moment().toDate();

    public static get currentDate(): Date {
        return moment().toDate();
    }

    public static get ShipDate(): Date {
        let _date = moment().toDate()
        if (_date.getDay() === 0) {
            _date.setHours(_date.getHours() + 24)
        } else if (_date.getDay() === 6) {
            _date.setHours(_date.getHours() + 48)
        }
        return _date;
    }


    public static get dateReducedByDay(): Date {
        return moment().subtract(1, 'days').toDate();
    }
    public static get displayHeight(): number {
        return window.innerHeight - 64;
    }

    public static get displayWidth(): number {
        return window.innerWidth - 240;
    }

    public static get isDesktop(): boolean {
        return window.innerWidth >= 1023;
    }

    public static get isTab(): boolean {
        return window.innerWidth < 1023;
    }

    public static get isMobile(): boolean {

        //return window.innerWidth < 640;
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
        return false;
    }

    public static get getWebbarheight(): Number {
        let height = 64;
        let el = document.getElementById("topWebNav") as any;
        if (el) {
            height = el.offsetHeight;
        }

        return height;
    }
    public static get getMobilebarheight(): any {
        let height = 52;
        let el = document.getElementById("mblBar") as any;
        if (el) {
            height = el.offsetHeight;
        }

        return height;
    }
    public static get getWebbarWidth(): any {
        let width = window.innerWidth;
        let el = document.getElementById("topWebNav") as any;
        if (el) {
            width = el.offsetWidth;
        }

        return width - 350;
    }

    public static isEnterPressed = (event: any | Event) => {
        return event.keyCode === 13
    }

    public static getDesktopDetailWidth = () => {
        return window.innerWidth <= 1024 ? 470 : 520;
    }

    public static checkUserProfile_LocalStorage = () => {
        return localStorage.getItem(Constants.USER_PROFILE) != null &&
            localStorage.getItem(Constants.USER_PROFILE) != undefined &&
            localStorage.getItem(Constants.USER_PROFILE) != '';
    }

    public static onlyUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }

    public static getLogType = (name: string) => {
        switch (name) {
            case 'All':
                return '';

            case 'Create':
                return Constants.LOG_TYPES.CREATE_LABEL;

            case 'Return':
                return Constants.LOG_TYPES.RETURN_LABEL;

            case 'Reprint':
                return Constants.LOG_TYPES.REPRINT;

            case 'Print Config':
                return Constants.LOG_TYPES.PRINT_CONFIG;

            case 'Void':
                return Constants.LOG_TYPES.VOID_LABEL;

            case 'Store Print':
                return Constants.LOG_TYPES.STORE_PRINT;

            case 'Error':
                return Constants.LOG_TYPES.ERROR;

            case 'Login':
                return Constants.LOG_TYPES.LOGIN;

            case 'Logout':
                return Constants.LOG_TYPES.LOGOUT;

            default:
                return '';
        }
    }

    public static getInitialData = async (mainErrorCallBack: any) => {
        //
        let stores: any = [];

        await Promise.all([
            await StoreManager.getConfigs(),
            await StoreManager.getAllSCStores().then((data: any) => {
                stores = data;
            })
        ]);

        // Set User Current Store
        if (IdentityManager.storeNumber !== '') {
            let res = StoreManager.allStoredata.find(r => r.StoreId.toString() === IdentityManager.storeNumber)
            if (res !== undefined && res !== null) {
                StoreManager.currentStore = res;
            }
        }
        // Store the UserId from OMS in local storage here to pass it to further ops

        OMSManager.GCGetUserLoginID(mainErrorCallBack);
        return stores;
    }

    public static getInitialDataExternalLink = async () => {
        // TODO document why this static async method 'getInitialDataExternalLink' is empty
        await StoreManager.getConfigs();
        OMSManager.GCGetUserLoginID(null);


    }

    public static mapAdditioanlLoggerProps = (log: any, obj: any) => {
        let returnObject = {};
        if (typeof obj === 'object' && obj !== null) {
            const thirdObject = {
                ...log,
                ...obj
            }
            return returnObject;
        }
        else {
            return log;
        }
    }

    public static commonLoggerProps = (log: ILogger) => {
        const queryString = window.location.search;
        log.store = StoreManager.currentStore?.StoreId && StoreManager.currentStore?.StoreId?.toString().length > 0 ? StoreManager.currentStore?.StoreId?.toString() : IdentityManager.storeNumber;
        log.userId = IdentityManager.getUserProperty(Constants.EmployeeID);
        log.upn = IdentityManager.getUserProperty(Constants.Preferred_UserName);
        const urlParams = new URLSearchParams(queryString);
        log.shipmentkey = urlParams.get('shipmentkey');
        log.orderno = urlParams.get('orderno');
        let isExternal = urlParams.get('external');
        if (isExternal != null && isExternal != undefined) {
            log.external = isExternal.toString();
        }

        log.logType = "log";
        return log;
    }
    public static getStepperIndex(steps: IVerticalStepper, toFind: string) {
        let index = steps.stepsOptions.findIndex(x => x.label === toFind);
        return index
    }

    public static isExternalRoute = () => {
        let isExternalRoute = false;
        let path = window.location.pathname.replace("/", "");
        if (path && path.length > 0) {
            const drawerData = PFManager.navigationData.filter(n => n.IsExternal == true)
            let filterData = drawerData.filter(e => e.Route.toLowerCase() == path.toLowerCase());
            if (filterData && filterData.length > 0) {
                isExternalRoute = true;
            }
        }
        return isExternalRoute;
    }

    public static GetStartEndDate(selectedFilter: string) {
        let result = { start: "", end: "" }
        if (selectedFilter == Constants.OrderAgeFilters[0].name) {
            result = Utils.getDateswithDaysDiff(1)
        }
        if (selectedFilter == Constants.OrderAgeFilters[1].name) {
            result = Utils.getDateswithDaysDiff(2)
        }
        if (selectedFilter == Constants.OrderAgeFilters[2].name) {
            result = Utils.getDateswithDaysDiff(3)
        }
        if (selectedFilter == Constants.OrderAgeFilters[3].name) {
            result = Utils.getdaysBackDate(3)
        }
        return result
    }
    public static getDateswithDaysDiff(diff: any) {
        var end = moment.tz("America/Los_Angeles"); // get current date
        var start = moment.tz("America/Los_Angeles"); //// get current date
        end = diff <= 1 ? end : end.subtract({ days: (diff - 1) });
        end = end.utcOffset(0);
        end.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
        //start = moment(end).subtract({days:1});
        start = moment(end);
        start = start.utcOffset(0);
        start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        console.log(diff);
        console.log(moment.utc(end).toISOString(), "end");
        console.log(moment.utc(start).toISOString(), "start");
        return {
            start: moment.utc(start).toISOString(),
            end: moment.utc(end).toISOString()
        }
    }
    public static getdaysBackDate(days: any) {
        var end = momenttz.tz("America/Los_Angeles");
        end = end.subtract({ days: days });
        end = end.utcOffset(0);
        end.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
        return {
            start: "",
            end: momenttz.utc(end).toISOString()
        }
    }

    public static localToGMTStingTime(localTime: Date) {
        return new Date(localTime.getTime() + (localTime.getTimezoneOffset() * 60000)).toISOString();
    }

    public static getDates(days: number) {
        let startDateTime = Utils.currentDate
        let endDateTime = Utils.currentDate
        startDateTime.setHours(0);
        startDateTime.setMinutes(0);
        startDateTime.setSeconds(0);
        startDateTime.setMilliseconds(0);

        endDateTime.setHours(23);
        endDateTime.setMinutes(59);
        endDateTime.setSeconds(59);
        endDateTime.setMilliseconds(999);

        var start = moment(startDateTime).format();
        var end = moment(endDateTime).format();

        //var start = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T00:00:00.000Z';
        //var end = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T23:59:59.999Z';

        switch (days) {
            //For Yesterday
            case -1:
                let startReducedByDay = Utils.dateReducedByDay
                let ReducedByDay = Utils.dateReducedByDay
                startReducedByDay.setHours(0);
                startReducedByDay.setMinutes(0);
                startReducedByDay.setSeconds(0);
                startReducedByDay.setMilliseconds(0);

                ReducedByDay.setHours(23);
                ReducedByDay.setMinutes(59);
                ReducedByDay.setSeconds(59);
                ReducedByDay.setMilliseconds(999);
                start = moment(startReducedByDay).format();
                end = moment(ReducedByDay).format();
                break;
            //For  Month to Date
            case 0:
                start = moment(startDateTime).startOf('month').format();
                break;
            //Rest of the days
            default:
                start = moment(startDateTime).subtract(days - 1, "days").format();
                break;
        }
        // return {
        //     start: Utils.localToGMTStingTime(moment(start).toDate()),
        //     end: Utils.localToGMTStingTime(moment(end).toDate())
        // };
        return {
            start, end
        };
    }

    public static formatCurrency(price: any) {
        const currencyFormatter = new Intl.NumberFormat(undefined, {
            currency: "USD",
            style: "currency",
        });
        return currencyFormatter.format(price);
    }

    public static timeConversion = (value: any) => {
        switch (value?.split(":")[0]) {
            case '00':
            case '0':
                return '12am'
            case '24':
                return '12am'
            case '12':
                return '12pm'
            default:
                let time = Number(value?.split(":")[0])
                if (time) {
                    let convertedTime = time > 12 ? time % 12 + 'pm' : time + 'am'
                    return convertedTime
                }
                return value;
        }
    }

    public static getDescriptionWidth(pick: string) {
        let width = window.innerWidth;
        let calculatedWidth = 0
        if (pick == "pick") {
            calculatedWidth = width - 240 - 150 - 150 - 190 - 80 - 370 - 40 - 150 - 30;
        }
        else {
            calculatedWidth = width - 240 - 150 - 150 - 190 - 80 - 370 - 40 - 150;
        }
        return calculatedWidth

    }
    public static getOrderAge(row: any) {


        let age: any = 0;
        const start = moment(row._source.Shipment.StatusDate, "YYYY-MM-DD");
        const end = moment(new Date(), "YYYY-MM-DD");
        age = Math.ceil(moment.duration(end.diff(start)).asDays());
        age = age < 1 ? 0 : age;
        return age;

    }

    public static getRowCollaprsedItemDistinctBase(row: any) {
        let expaned = true;
        let validShipLines = Utils.getValidShipLinesWithOrderStatus(row._source?.Shipment?.ShipmentLines?.ShipmentLine, row._source?.Shipment?.Status);
        const unique = validShipLines.map((item: any) => item.ItemID)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

        if (unique && unique.length == 1) {

            expaned = false;
        }

        return expaned;
    }

    public static getCount(row: any) {
        let count = 0
        row._source.Shipment.ShipmentLines.ShipmentLine.forEach((element: any) => {
            if (Utils.isValidShipLine(element, row._source.Shipment.Status)) {
                count = count + Number(element.OrderedQty)
            }
        });
        return count;
    }

    public static getContainerTrackingNumber(row: any) {
        let track = "";

        if (row._source?.Shipment?.Containers?.Container) {
            let containers = row._source?.Shipment?.Containers?.Container;
            if (containers && containers.length > 0) {
                track = containers[0].TrackingNo;
            }
        }

        return track;
    }

    public static getValidShipLines = (lines: any[], skipCancelLine = false) => {
        //skipCancelLine a flag to not check some ValidShipLine conditions
        //Where status is 9000 or cancelled if it's true.
        let returnLines: any[] = [];
        if (lines && lines.length > 0) {
            lines.forEach((element: any) => {
                if (Utils.isValidShipLine(element, element.OrderStatus, skipCancelLine)) {
                    returnLines.push(element);
                }
            });
        }

        return returnLines

    }
    public static getValidShipLinesWithOrderStatus = (lines: any[], orderStatus: any) => {
        let returnLines: any[] = [];
        if (lines && lines.length > 0) {
            lines.forEach((element: any) => {
                if (Utils.isValidShipLine(element, orderStatus)) {
                    returnLines.push(element);
                }
            });
        }

        return returnLines

    }
    public static isValidShipLine = (line: any, orderStatus: string, skipCancelLine = false) => {        
        let isValid = false
        if (orderStatus != ShipmentStatusConstants.ReadyForCustomerPickup) {
            if (line.MaxLineStatusDesc != "Backordered" && line.ExtnIsWarrantyItem == "N" && (line.MaxLineStatusDesc != "Cancelled" || skipCancelLine) &&
                line.BundleParentOrderLineKey == undefined && line.MaxLineStatusDesc != "Scheduled" && line.MaxLineStatus != "1500" &&
                (line.MaxLineStatus != "9000" || skipCancelLine) && line.MaxLineStatus != "1300" && line.MaxLineStatus != ShipmentStatusConstants.BackOrderedOMS) {
                isValid = true
            }
        }
        else {
            if (line.MaxLineStatus == ShipmentStatusConstants.SentToFulfillmentCenter) {
                isValid = false
            }
            else if (line.MaxLineStatus == ShipmentStatusConstants.Shipped) {
                isValid = true
            }
            else {
                if (line.MaxLineStatusDesc != "Backordered" && line.ExtnIsWarrantyItem == "N" && (line.MaxLineStatusDesc != "Cancelled" || skipCancelLine) &&
                    line.BundleParentOrderLineKey == undefined && line.MaxLineStatusDesc != "Scheduled" && line.MaxLineStatus != "1500" &&
                    (line.MaxLineStatus != "9000" || skipCancelLine) && line.MaxLineStatus != ShipmentStatusConstants.BackOrderedOMS) {
                    isValid = true
                }
            }

        }

        return isValid
    }

    public static getDescriptionWidthReceiveOrders(pick: string) {
        let width = window.innerWidth;
        let calculatedWidth = 0
        calculatedWidth = width - 240 - 200 - 130 - 170 - 250 - 120;
        return calculatedWidth

    }
    public static getMessageWidthTecAudits() {
        let width = window.innerWidth;
        let calculatedWidth = 0
        calculatedWidth = width - 240 - 120 - 200 - 200 - 90 - 150 - 200 - 40;
        return calculatedWidth

    }
    public static getDateDiffFromToday(input: any) {
        if (!input)
            return "";

        var start = momenttz(input).tz("America/Los_Angeles");
        var end = momenttz.tz("America/Los_Angeles");
        let difference = end.diff(start)
        let days = 0
        if (difference == 0) {
            days = 1
        }
        else {
            days = Math.round(difference / 86400000)
        }
        if (days == 0) {
            days = 1
        }
        return days;
    }

    public static getNextDayDate(days: any) {
        var end = momenttz.tz("America/Los_Angeles");
        end = end.add({ days: days });
        return momenttz(end).toISOString();

    }

    public static getCurrentDate() {
        var start = moment.tz("America/Los_Angeles");
        return moment(start).toISOString();
    }

    public static initCanceReasonState() {
        return {
            code: "",
            reasonText: "",
            showModal: false,
            errorMessage: "",
            cancelItem: null,
            cancelCompleteOrder: false
        }
    }

    public static initUserSettings(): IUserSettings {
        const userSettings: IUserSettings = {
            userName: "",
            pagination: 50,
            empID: "",
            expandFilters: false
        }
        return userSettings;
    }

    public static updateUserSettings(payload: any) {
        if (payload.userName) {
            UserManager.updateUserSettings(payload).then((res) => {
                console.log("Pagination Settings Updated", res);
            }).catch((err) => {
                console.log("Pagination Settings Error", err);
            })
        }
    }

    public static convertElasticDateToPst(input: any) {
        var momenttz = require('moment-timezone');
        const today = momenttz(input).tz("America/Los_Angeles").format(Constants.DateFormat.Format);
        return today;
    }

    public static writeSendRequestLogs(response: any, type: any) {
        //
        if (response && response.success) {
            for (let i = 0; i < response.data.length; i++) {
                let result = response.data[i].response
                let payLoad = response.data[i].payLoad;

                if (result.success) {
                    logger.info({
                        payLoad: payLoad,
                        tag: type,
                        message: ""
                    });
                }
                else {
                    logger.error({
                        payLoad: payLoad,
                        tag: type,
                        message: result?.msg
                    });
                }

            }
        }
        else {
            logger.error({
                tag: type,
                message: response?.msg
            });
        }


    }

    public static generateRandom(len: any, type: any) {

        let random = "";
        len = len < 0 ? 5 : len;
        if (type && type.length > 0) {
            random = cryptoRandomString({ length: len, type });
        }
        else {
            random = cryptoRandomString({ length: len });
        }

        return random;

    }

    public static getDataForBulk() {
        return [
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Gibson",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Gibson",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Gibson",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Fender",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "10",
                Brand: "Fender",
                Description: "Orange County Drum & Percussion Maple Snare ",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "6",
                Brand: "Jackson",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "5",
                Brand: "Jackson",
                Description: "Orange County Drum & Percussion Maple Snare ",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "3",
                Brand: "Fender",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Fender",
                Description: "Martin M1200 Marquis 80/20 Bronze Medium A",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Gibson",
                Description: "Orange County Drum & Percussion Maple Snare ",

            },
            {
                OrderNumber: Utils.generateRandom(8, Constants.CryptoRandomTypes.numeric),
                randomQty: "2",
                Brand: "Gibson",
                Description: "Orange County Drum & Percussion Maple Snare ",

            }
        ];
    }

    public static groupBy(list: any[], keyGetter: any) {
        return list.reduce(function (rv, x) {
            (rv[x[keyGetter]] = rv[x[keyGetter]] || []).push(x);
            return rv;
        }, {});
    }

    public static changeFulFillmentType(name?: string) {
        if (name == "PICKUP_IN_STORE") {
            return "PICKUP IN STORE";
        }
        else if (name == "SHIP_2_CUSTOMER") {
            return "SHIP TO CUSTOMER";
        }
        else {
            return name;
        }
    }

    public static convertToHttps(url?: string) {
        if (!url || url.includes("https://")) {
            return url;
        } else {
            return url.replace("http://", "https://");
        }
    }

    public static setImageResolution(url?: string) {
        let height, width;
        if (this.isMobile) {
            height = Math.round(window.innerHeight * 0.3);
            width = Math.round(window.innerWidth * 0.5);
        }
        else {
            height = Math.round(window.innerHeight * 0.64);
            width = Math.round(window.innerWidth * 0.6);
        }

        url = url?.replace("180x180", `${width}x${height}`);
        return url;
    }

    public static get getMobileDrawerHeight(): number {
        return Math.round(window.innerHeight * 0.75);
    }

    public static getPickupSlipDataFromPick(customerDetail: ICustomerDetail, orderDetails: OmsPickOrderDetail) {
        let customICustomerDetail = {
            name: customerDetail.name ? customerDetail.name : "-",
            address: customerDetail.address ? customerDetail.address : "-",
            city: customerDetail.city ? customerDetail.city : "-",
            state: customerDetail.state ? customerDetail.state : "-",
            zipCode: customerDetail.zipCode ? customerDetail.zipCode : "-",
            email: customerDetail.email ? customerDetail.email : "-"
        }
        let iPricingSummary = {
            SubTotal: (orderDetails.ShipmentLines.PriceInfo.SubTotal) ?? "0",
            Adjustments: (orderDetails.ShipmentLines.PriceInfo.Adjustments) ?? "0",
            ShippingCharges: (orderDetails.ShipmentLines.PriceInfo.Shipping) ?? "0",
            Taxes: (orderDetails.ShipmentLines.PriceInfo.Taxes) ?? "0",
            Total: (orderDetails.ShipmentLines.PriceInfo.Total) ?? "0"
        }
        let customIToAddress = {
            FirstName: orderDetails.ToAddress.FirstName ? orderDetails.ToAddress.FirstName : "",
            MiddleName: orderDetails.ToAddress.MiddleName ? orderDetails.ToAddress.MiddleName : "",
            LastName: orderDetails.ToAddress.LastName ? orderDetails.ToAddress.LastName : "",
            Company: orderDetails.ToAddress.Company ? orderDetails.ToAddress.Company : "",
            AddressLine1: orderDetails.ToAddress.AddressLine1 ? orderDetails.ToAddress.AddressLine1 : "",
            AddressLine2: orderDetails.ToAddress.AddressLine2 ? orderDetails.ToAddress.AddressLine2 : "",
            City: orderDetails.ToAddress.City ? orderDetails.ToAddress.City : "",
            State: orderDetails.ToAddress.State ? orderDetails.ToAddress.State : "",
            ShortZipCode: orderDetails.ToAddress.ShortZipCode ? orderDetails.ToAddress.ShortZipCode : "",
            Country: orderDetails.ToAddress.Country ? orderDetails.ToAddress.Country : ""
        }
        let customItem: any = []
        orderDetails.ShipmentLines.ShipmentLine.map((item: any, index: any) => {
            let citem = {
                Quantity: item.Quantity,
                ItemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID,
                ItemDesc: item.ItemDesc,
                SerialNumber: item.SerialNumber,
                ExtnPOSSalesTicketNo: item.ExtnPOSSalesTicketNo,
                UnitPrice: item.UnitPrice
            }
            customItem.push(citem);
        })

        let printPickupSlipData = {
            OrderNo: orderDetails.ShipmentLines.ShipmentLine[0].OrderNo,
            BillToId: orderDetails.BillToCustomerId,
            OrderDate: moment(orderDetails.ShipmentLines.ShipmentLine[0].Order.OrderDate).format("MM/DD/YYYY"),
            Status: "Pick",
            UserName: orderDetails.UserName ? orderDetails.UserName : "-",
            PaymentMethod: orderDetails.PaymentType,
            CustomICustomerDetail: customICustomerDetail,
            CustomIToAddress: customIToAddress,
            IPricingSummary: iPricingSummary,
            CustomItem: customItem
        }
        return printPickupSlipData;
    }

    public static async getPickupSlipDataFromPickup(orderLines: ContainerDetail[], customerDetail: ICustomerDetail, orderDetails: ICustomerPickupDetail, selectedItems: any, OrderDetailFromExStore: any, customerPickedUp?:boolean) {
        let customICustomerDetail = {
            name: customerDetail.name ? customerDetail.name : "-",
            address: customerDetail.address ? customerDetail.address : "-",
            city: customerDetail.city ? customerDetail.city : "-",
            state: customerDetail.state ? customerDetail.state : "-",
            zipCode: customerDetail.zipCode ? customerDetail.zipCode : "-",
            email: customerDetail.email ? customerDetail.email : "-"
        }
        let iPricingSummary = {
            SubTotal: "0",
            Adjustments: "0",
            ShippingCharges: "0",
            Taxes: "0",
            Total: "0"
        }
        let customIToAddress = {
            FirstName: orderDetails.ToAddress?.FirstName ? orderDetails.ToAddress.FirstName : "",
            MiddleName: orderDetails.ToAddress?.MiddleName ? orderDetails.ToAddress.MiddleName : "",
            LastName: orderDetails.ToAddress?.LastName ? orderDetails.ToAddress.LastName : "",
            Company: orderDetails.ToAddress?.Company ? orderDetails.ToAddress.Company : "",
            AddressLine1: orderDetails.ToAddress?.AddressLine1 ? orderDetails.ToAddress.AddressLine1 : "",
            AddressLine2: orderDetails.ToAddress?.AddressLine2 ? orderDetails.ToAddress.AddressLine2 : "",
            City: orderDetails.ToAddress?.City ? orderDetails.ToAddress.City : "",
            State: orderDetails.ToAddress?.State ? orderDetails.ToAddress.State : "",
            ShortZipCode: orderDetails.ToAddress?.ShortZipCode ? orderDetails.ToAddress.ShortZipCode : "",
            Country: orderDetails.ToAddress?.Country ? orderDetails.ToAddress.Country : ""
        }
        let customItem: any = []
        orderLines?.map((item: any, index: any) => {
            if (selectedItems?.indexOf(item.UniqueContainerKey) > -1) {
                let citem = {
                    Quantity: item.Quantity,
                    ItemID: item.ItemID,
                    ItemDesc: item.ItemDesc,
                    SerialNumber: item.SerialNumber,
                    ExtnPOSSalesTicketNo: item.ExtnPOSSalesTicketNo,
                    UnitPrice: item.UnitPrice
                }
                customItem.push(citem);
            }
        })
        const paymentMethod = orderDetails?.PaymentMethods?.PaymentMethod?.length > 0 ? orderDetails?.PaymentMethods?.PaymentMethod[0]?.CreditCardType : ""
        let printPickupSlipData = {
            OrderNo: orderDetails.OrderNo,
            BillToId: orderDetails.BillToID,
            OrderDate: moment(orderDetails.OrderDate).format("MM/DD/YYYY"),
            Status: customerPickedUp? Constants.ORDER_TYPE_LABEL.Customer_Picked_Up: orderDetails.Status,
            UserName: orderDetails.UserName ? orderDetails.UserName : "-",
            PaymentMethod: paymentMethod,
            CustomICustomerDetail: customICustomerDetail,
            CustomIToAddress: customIToAddress,
            IPricingSummary: iPricingSummary,
            CustomItem: customItem,
            chargesData: null
        };

        let containersToPassForCharges = { ...orderDetails };
        containersToPassForCharges.ContainerDetails.ContainerDetail = [];
        orderLines?.map((item: any, index: any) => {
            if (selectedItems?.indexOf(item.UniqueContainerKey) > -1) {
                containersToPassForCharges.ContainerDetails?.ContainerDetail?.push(item);
            }
        });

        let passPaylooad = {
            ContainerDetails: containersToPassForCharges.ContainerDetails
        }

        let data = await OMSManager.GCCustomerPickSlipDetails(passPaylooad);
        if (data && data.success) {
            printPickupSlipData.IPricingSummary = {
                SubTotal: (data?.data?.ShipmentLines?.PriceInfo?.SubTotal) ?? "0",
                Adjustments: (data?.data?.ShipmentLines?.PriceInfo?.Adjustments) ?? "0",
                ShippingCharges: (data?.data?.ShipmentLines?.PriceInfo?.Shipping) ?? "0",
                Taxes: (data?.data?.ShipmentLines?.PriceInfo?.Taxes) ?? "0",
                Total: (data?.data?.ShipmentLines?.PriceInfo?.Total) ?? "0",
            }
            //printPickupSlipData.chargesData = data?.data?.ShipmentLines?.PriceInfo;
        }


        return printPickupSlipData;
    }

    public static isCurrentUserEntered(email: string) {

        let check = false
        // let currentUserName = IdentityManager.getUserProperty(Constants.Preferred_UserName)
        // if (email && email.toLowerCase() == currentUserName.toLowerCase()) {
        //     check = true
        // }
        //
        return check

    }

    public static OrderFlow(orderDetail: any, isSpecialCase: any, specialCaseResponse: any) {
        let navigationDecision = ""

        if (isSpecialCase == "false" || isSpecialCase == false) {
            if (orderDetail.IsShipToCustomer && orderDetail.IsShipToCustomer == "Y") {
                navigationDecision = Constants.OrderFlowConstants.PICK_PACK_SHIP
            }
            else if (orderDetail.IsSameStorePickup && orderDetail.IsSameStorePickup == "Y") {
                navigationDecision = Constants.OrderFlowConstants.PICK_PICKUP
            }
            else if (orderDetail.IsShipToStore && orderDetail.ExtnPickupStoreID && orderDetail.IsShipToStore == "Y" && orderDetail.ExtnPickupStoreID != orderDetail.ShipNode) {
                navigationDecision = Constants.OrderFlowConstants.PICK_PACK_SHIP_RECEIVE_PICKUP
            }
        }
        else {
            if (specialCaseResponse.IsShipToStore) {
                navigationDecision = Constants.OrderFlowConstants.PICK_PACK_SHIP_RECEIVE_PICKUP
            }
            else {
                navigationDecision = Constants.OrderFlowConstants.PICK_PICKUP
            }

        }

        return navigationDecision;
    }
    public static retailOpsManagerEmail(storeId: any, storeName: any) {

        let returnedEmail = "";
        if (storeId != null && storeId != undefined) {

            let res = StoreManager.allStoredata.find(r => r.StoreId.toString() === storeId.toString());
            if (res != null && res != undefined) {

                if (res.RetailMgrEmail && res.RetailMgrEmail.length > 0) {
                    returnedEmail = res.RetailMgrEmail;
                }
                else {
                    let city = res.City && res.City.length > 0 ? res.City.replace(/\s/g, '') : "";
                    returnedEmail = `All-store-${storeId}-${city}-retail-managers@guitarcenter.com`;
                }

            }

        }
        return returnedEmail;
    }

    public static parseIntCustom(val: any) {
        let response: any = 0;
        if (!val || val == "") {
            return response;
        }

        response = parseInt(val, 10);
        return response;
    }

    public static checkOrderDetailStatus = (payload: any) => {
        let response = PFManager.checkOrderDetailStatus(payload);
        return response;
    }

    public static generateUUID = () => {
        return uuidv4();
    }

    public static getLabel = (name: string) => {
        let result = ""
        switch (name) {
            case "GCNotOriginalPackaging.txt":
                result = "Not Original Packaging"
                break;
            case "GCOutOfBox.txt":
                result = "Out Of Box"
                break;
            case "MFNotOriginalPackaging.txt":
                result = "Not Original Packaging"
                break;
            case "MFOutOfBox.txt":
                result = "Out Of Box"
                break;
            case Constants.ContactCustomerFileName.FollowUp:
                result = "Follow Up"
                break;
            case Constants.ContactCustomerFileName.Custom:
                result = "Custom Email"
                break;
            default:
                result = name

        }
        return result
    }

    public static getDescription = (row: any) => {
        let extendedDescription = row?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription;
        if (extendedDescription?.trim()) {
            return `${row.ItemDesc}. ${extendedDescription}`;
        }
        return row.ItemDesc;
    }

    public static clubContactCustomerandFollowup = (steps: any) => {
        let clubSteps: any = {}
        clubSteps.activeStep = steps.activeStep
        clubSteps.stepsOptions = []
        steps.stepsOptions.forEach((element: any) => {
            if (element.label == "Customer Contacted") {
                if (element.data.isFollowup == false) {
                    let contactCustomerFollowups = steps.stepsOptions.filter((followups: any) => followups.data && followups.data.isFollowup == true && followups.data.parentContactCustomerGuid == element.data.guid)
                    contactCustomerFollowups.sort((a: any, b: any) => { return a.jsonDateTime - b.jsonDateTime })
                    element.historyFollowupsSteps = contactCustomerFollowups
                    clubSteps.stepsOptions.push(element)

                }
                if (element.data.isFollowup == undefined) {
                    clubSteps.stepsOptions.push(element)
                }
            }
            else {
                clubSteps.stepsOptions.push(element)
            }
        });
        return clubSteps
    }

    public static sortContactCustomerHistory = (payload: any) => {

        let sortedData: any = []
        if (payload && payload.length > 0) {
            payload.forEach((element: any) => {
                let followupObj = {
                    data: element,
                    dateJson: moment(element?._source?.transactionDate).valueOf(),
                    isResponse: false,
                }
                sortedData.push(followupObj)
                if (element?._source?.responded == true || element?._source?.responded == "true") {
                    let contactCustomerResponseObj = {
                        data: element,
                        dateJson: moment(element?._source?.customerresponsedate).valueOf(),
                        isResponse: true,
                    }
                    sortedData.push(contactCustomerResponseObj)
                }
            });
        }
        sortedData.sort((a: any, b: any) => { return b.dateJson - a.dateJson })
        payload = sortedData
        return payload
    }

    public static disableUserAction = (store: any) => {
        if (store && store.length > 1) {
            return false;
        }
        else {
            return true;
        }
    }

    public static shipmentLineNeedScanning = (shipmentLine: any) => {
        let needScanning = false
        if (Number(shipmentLine.Quantity) - Number(shipmentLine.ScannedQty > 0)) {
            needScanning = true
        }
        if (shipmentLine.hasOwnProperty("CustomComponents")) {
            needScanning = false
            shipmentLine.CustomComponents.ShipmentLine.forEach((cShipmentLine: any) => {
                if (Number(cShipmentLine.Quantity) - Number(cShipmentLine.ScannedQty > 0)) {
                    needScanning = true
                }
            });
        }
        return needScanning
    }
    public static customComponentShipmentLineNeedScanning = (shipmentLine: any) => {
        let needScanning = false
        if (Number(shipmentLine.Quantity) - Number(shipmentLine.ScannedQty > 0)) {
            needScanning = true
        }
        return needScanning
    }
    public static isWarrantyItem = (posScanResult: any) => {
        let isWarranty = false
        posScanResult.forEach((scannedLine: any) => {
            if (scannedLine?.POSScanResult?.Success && scannedLine?.POSScanResult?.Success == "true" && scannedLine?.ExtnIsWarrantyItem == "Y") {
                isWarranty = true
            }
        })
        return isWarranty
    }
    public static filterWarrantyLines = (lines: any) => {
        return lines.filter((line: any) => line.ExtnIsWarrantyItem == "N")
    }
    public static getWarrantyPOSScanResult = (scanResult: any, shipmentLineKey: string) => {
        let posScanResult: any = {}
        let warrantyScanedLineFilter = scanResult.filter((warrentyLine: any) => warrentyLine.ShipmentLineKey == shipmentLineKey)
        if (warrantyScanedLineFilter.length > 0 && warrantyScanedLineFilter[0].POSScanResult && warrantyScanedLineFilter[0].POSScanResult.Success == "true") { // if we can get POSScanResult by ShipmentLineKey
            posScanResult = warrantyScanedLineFilter[0].POSScanResult
        }
        else { // use by ExtnPOSItemID change it
            if (warrantyScanedLineFilter.length > 0) {
                let warrantyByExtnPOSItemID = scanResult.filter((warrentyLine: any) => warrentyLine.ExtnPOSItemID == warrantyScanedLineFilter[0].ExtnPOSItemID && warrentyLine?.POSScanResult && warrentyLine?.POSScanResult?.Success == "true" && warrentyLine?.POSScanResult?.ErrorInfo == "")
                if (warrantyByExtnPOSItemID.length > 0 && warrantyByExtnPOSItemID[0].POSScanResult && warrantyByExtnPOSItemID[0].POSScanResult.Success == "true") {
                    posScanResult = warrantyByExtnPOSItemID[0].POSScanResult
                }
            }

        }
        return posScanResult
    }
    public static checkPosScanResult = (lines: any) => {
        let isSuccess = true
        let errorMessage = ""
        lines.forEach((line: any) => {
            if (line?.POSScanResult?.Success && (line?.POSScanResult?.Success == "false" || line?.POSScanResult?.ErrorInfo != "")) {
                isSuccess = false
                errorMessage = line?.POSScanResult?.ErrorInfo
            }
            //Handling Empty Sku Number Case
            else if (line?.POSScanResult?.Success && !line?.POSScanResult?.SKUNumber)
            {
                isSuccess = false;
                errorMessage = LabelText.noScanItem;
            }
        })
        return ({ isSuccess, errorMessage })
    }
    public static getPocScanResult = (lines: any, scanCurrentLine: any) => {
        let finalPosScanResult = {} as any
        // check if for current shipment line key resut have result then return as it is
        if (scanCurrentLine && scanCurrentLine.POSScanResult && scanCurrentLine?.POSScanResult?.Success && scanCurrentLine?.POSScanResult?.Success == "true" && scanCurrentLine?.POSScanResult?.ErrorInfo == "") {
            finalPosScanResult = scanCurrentLine.POSScanResult
        }
        else {
            // if not find by ExtnPOSItemID and send its result
            let posScanResultByExtnPOSItemID = lines.filter((posScanShipmentLine: any) => posScanShipmentLine.ExtnPOSItemID == scanCurrentLine.ExtnPOSItemID && posScanShipmentLine?.POSScanResult && posScanShipmentLine?.POSScanResult?.Success == "true" && posScanShipmentLine?.POSScanResult?.ErrorInfo == "")
            if (posScanResultByExtnPOSItemID.length > 0) {
                finalPosScanResult = posScanResultByExtnPOSItemID[0].POSScanResult
            }
        }
        return finalPosScanResult
    }
    public static currencyFormatter = (value: number) => {
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
        return formattedValue;
    }
    public static hoursTextFormatter = (hours: number) => {
        if (hours >= 24) {
            const days = Math.floor(hours / 24);
            const remainingHours = hours % 24;
            return `${days} day(s) ${Math.floor(remainingHours)} hr(s)`;
        } else {
            return `${Math.floor(hours)} hr(s)`;
        }
    }

    public static hoursTextFormatterHeading = (hours: number) => {
        if (hours >= 24) {
            const days = Math.floor(hours / 24);
            const remainingHours = hours % 24;
            return { days, remainingHours }
        } else {
            return { days: 0, remainingHours: hours };
        }
    }

    public static getRandomColors = () => {
        // const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        // var randomColor = colors[Math.floor(
        //    parseInt(Utils.generateRandom(3, Constants.CryptoRandomTypes.numeric))  * colors.length)];
        // return randomColor
        return "#0088FE"
    }
    public static getColor = (index: number) => {
        const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        return colors[index]
    }
    public static mapCCData = (data: any, stores: any, districts: any) => {
        let ccGridData: ICustomerContactReport = { districtCCData: [] };
        data.forEach((bucket: any) => {
            const storeObj = stores.find((s: any) => s.id == bucket.key);
            let storeCCData: IStoreCCData = {
                storeId: storeObj.id,
                storeName: `${storeObj.id}-${storeObj.name}`,
                storeEmailsSent: bucket.EmailsSent.doc_count ? bucket.EmailsSent.doc_count : 0,
                storeCustomerResponded: bucket.CustomerResponseReceived.doc_count ? bucket.CustomerResponseReceived.doc_count : 0,
                storeCancelOrderResponse: bucket.Cance_Order.doc_count ? bucket.Cance_Order.doc_count : 0,
                storeContactMeResponse: bucket.Contact_Me_To_Discuss.doc_count ? bucket.Contact_Me_To_Discuss.doc_count : 0,
                storeWantNewResponse: bucket.I_Want_It_New.doc_count ? bucket.I_Want_It_New.doc_count : 0,
                storeSendMeResponse: bucket.Yes_Send_Me_What_You_Got.doc_count ? bucket.Yes_Send_Me_What_You_Got.doc_count : 0
            };
            if (storeObj.districtId) {
                let districtObj = ccGridData.districtCCData.find((x: IDistrictCCData) => x.districtId == storeObj.districtId);
                if (districtObj) {
                    districtObj.emailsSent += storeCCData.storeEmailsSent;
                    districtObj.customerResponded += storeCCData.storeCustomerResponded;
                    districtObj.cancelOrderResponse += storeCCData.storeCancelOrderResponse;
                    districtObj.contactMeResponse += storeCCData.storeContactMeResponse;
                    districtObj.wantNewResponse += storeCCData.storeWantNewResponse;
                    districtObj.sendMeResponse += storeCCData.storeSendMeResponse;
                    districtObj.ccStoresData.push(storeCCData);
                }
                else {
                    const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;
                    let districtCCData: IDistrictCCData = {
                        districtId: storeObj.districtId,
                        district: districtName,
                        emailsSent: storeCCData.storeEmailsSent,
                        customerResponded: storeCCData.storeCustomerResponded,
                        cancelOrderResponse: storeCCData.storeCancelOrderResponse,
                        contactMeResponse: storeCCData.storeContactMeResponse,
                        wantNewResponse: storeCCData.storeWantNewResponse,
                        sendMeResponse: storeCCData.storeSendMeResponse,
                        ccStoresData: [storeCCData]
                    };
                    ccGridData.districtCCData.push(districtCCData);
                }
            }
        });
        return ccGridData;
    }
    // Map Resource Order
    public static mapROData = (data: any, stores: any, districts: any) => {
        let roGridData: IResourceOrderReport = { districtROData: [] };
        data.forEach((bucket: any) => {

            const storeObj = stores.find((s: any) => s.id == bucket.key);

            let storeROData: IStoreROData = {
                storeId: storeObj.id,
                storeName: `${storeObj.id}-${storeObj.name}`,
                storeFloorModelReasonCount: 0,
                storeBlemishedReasonCount: 0,
                storeOpenBoxReasonCount: 0,
                storeDamagedReasonCount: 0,
                storeMissingPartsReasonCount: 0,
                storeDemoReasonCount: 0,
                storeCustomerCommittedReasonCount: 0,
                storeCannotLocateReasonCount: 0,
                storeTotalResourceOrderCount: bucket.doc_count ? bucket.doc_count : 0
            };


            // Map keys with reasons
            // Todo : Make a seprate function
            for (let i = 0; i < bucket.resourceReasonCountByStore.buckets.length; i++) {

                if (bucket.resourceReasonCountByStore.buckets[i].key === "10") {
                    storeROData.storeFloorModelReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }
                if (bucket.resourceReasonCountByStore.buckets[i].key === "20") {
                    storeROData.storeBlemishedReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }
                if (bucket.resourceReasonCountByStore.buckets[i].key === "30") {
                    storeROData.storeOpenBoxReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }
                if (bucket.resourceReasonCountByStore.buckets[i].key === "40") {
                    storeROData.storeDamagedReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }
                if (bucket.resourceReasonCountByStore.buckets[i].key === "50") {
                    storeROData.storeMissingPartsReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }

                if (bucket.resourceReasonCountByStore.buckets[i].key === "60") {
                    storeROData.storeDemoReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }
                if (bucket.resourceReasonCountByStore.buckets[i].key === "70") {
                    storeROData.storeCustomerCommittedReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }
                if (bucket.resourceReasonCountByStore.buckets[i].key === "80") {
                    storeROData.storeCannotLocateReasonCount = bucket.resourceReasonCountByStore.buckets[i].doc_count
                }

            }
            // save data in districts and push store object in ditrict array
            if (storeObj.districtId) {
                let districtObj = roGridData.districtROData.find((x: IDistrictROData) => x.districtId == storeObj.districtId);
                if (districtObj) {
                    districtObj.floorModelReasonCount += storeROData.storeFloorModelReasonCount;
                    districtObj.blemishedReasonCount += storeROData.storeBlemishedReasonCount;
                    districtObj.openBoxReasonCount += storeROData.storeOpenBoxReasonCount;
                    districtObj.damagedReasonCount += storeROData.storeDamagedReasonCount;
                    districtObj.missingPartsReasonCount += storeROData.storeMissingPartsReasonCount;
                    districtObj.demoReasonCount += storeROData.storeDemoReasonCount;
                    districtObj.customerCommittedReasonCount += storeROData.storeCustomerCommittedReasonCount;
                    districtObj.cannotLocateReasonCount += storeROData.storeCannotLocateReasonCount;
                    districtObj.totalResourceOrderCount += storeROData.storeTotalResourceOrderCount
                    districtObj.roStoresData.push(storeROData);
                }
                else {
                    const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;

                    let districtROData: IDistrictROData = {
                        districtId: storeObj.districtId,
                        district: districtName,
                        floorModelReasonCount: storeROData.storeFloorModelReasonCount,
                        blemishedReasonCount: storeROData.storeBlemishedReasonCount,
                        openBoxReasonCount: storeROData.storeOpenBoxReasonCount,
                        damagedReasonCount: storeROData.storeDamagedReasonCount,
                        missingPartsReasonCount: storeROData.storeMissingPartsReasonCount,
                        demoReasonCount: storeROData.storeDemoReasonCount,
                        customerCommittedReasonCount: storeROData.storeCustomerCommittedReasonCount,
                        cannotLocateReasonCount: storeROData.storeCannotLocateReasonCount,
                        totalResourceOrderCount: storeROData.storeTotalResourceOrderCount,
                        roStoresData: [storeROData]
                    };
                    roGridData.districtROData.push(districtROData);
                }
            }
        });
        return roGridData;
    }

    public static mapShippingData = (data: any, stores: any, districts: any) => {
        let shippingReport: IShippingReport = { districtShippingData: [] };

        data?.aggregations?.StoresAggs?.buckets.forEach((bucket: any) => {

            const storeObj = stores.find((s: any) => s.id == bucket.key.replace("G", ""));

            const usps = bucket?.carriersByStore?.buckets.find((x: any) => x.key == "USPS");

            const ups = bucket?.carriersByStore?.buckets.find((x: any) => x.key == "UPS");

            const firstClass = bucket?.serviceByStore?.buckets.find((x: any) => x.key.replace(/[^\w ]/, '') == "FIRST CLASS");

            const ups2ndDay = bucket?.serviceByStore?.buckets.find((x: any) => x.key.replace(/[^\w ]/, '') == "UPS 2nd Day Air");

            const upsNextDay = bucket?.serviceByStore?.buckets.find((x: any) => x.key.replace(/[^\w ]/, '') == "UPS Next Day Air");

            const nextDayAirSaver = bucket?.serviceByStore?.buckets.find((x: any) => x.key.replace(/[^\w ]/, '') == "Next Day Air Saver");

            const economyGround = bucket?.serviceByStore?.buckets.find((x: any) => x.key.replace(/[^\w ]/, '') == "ECONOMY GROUND");

            let storeShippingData: IStoreShippingData = {
                storeId: storeObj.id,
                storeName: `${storeObj.id}-${storeObj.name}`,
                shippedNumber: bucket?.shippingCostSum?.doc_count,
                shippingCost: bucket?.shippingCostSum?.sum?.value,
                usps: usps?.carriersByStoreFilter?.doc_count ? usps?.carriersByStoreFilter?.doc_count : 0,
                ups: ups?.carriersByStoreFilter?.doc_count ? ups?.carriersByStoreFilter?.doc_count : 0,
                firstClass: firstClass?.doc_count ? firstClass?.doc_count : 0,
                totalFirstClassAmount: firstClass?.carriersByStoreFilter.ServiceAmount?.value ? firstClass?.carriersByStoreFilter.ServiceAmount?.value : 0,
                ups2ndDay: ups2ndDay?.doc_count ? ups2ndDay?.doc_count : 0,
                totalUps2ndDayAmount: ups2ndDay?.carriersByStoreFilter.ServiceAmount?.value ? ups2ndDay?.carriersByStoreFilter.ServiceAmount?.value : 0,
                upsNextDay: upsNextDay?.doc_count ? upsNextDay?.doc_count : 0,
                totalUpsNextDayAmount: upsNextDay?.carriersByStoreFilter.ServiceAmount?.value ? upsNextDay?.carriersByStoreFilter.ServiceAmount?.value : 0,
                nextDayAirSaver: nextDayAirSaver?.doc_count ? nextDayAirSaver?.doc_count : 0,
                totalNextDayAirSaverAmount: nextDayAirSaver?.carriersByStoreFilter.ServiceAmount?.value ? nextDayAirSaver?.carriersByStoreFilter.ServiceAmount?.value : 0,
                economyGround: economyGround?.doc_count ? economyGround?.doc_count : 0,
                totalEconomyGroundAmount: economyGround?.carriersByStoreFilter.ServiceAmount?.value ? economyGround?.carriersByStoreFilter.ServiceAmount?.value : 0,
                returnCost: bucket?.returnCostSum?.sum?.value,
                shipToStore: bucket?.shipToStore?.sum?.value,
                shipToCustomer: bucket?.shipToCustomer?.sum?.value,
                shipToStoreCount: bucket?.shipToStore?.doc_count,
                shipToCustomerCount: bucket?.shipToCustomer?.doc_count,
                returnNumbers: bucket?.returnCostSum.doc_count,

            };

            if (storeObj.districtId) {

                let districtObj = shippingReport.districtShippingData.find((x: IDistrictShippingData) => x.districtId == storeObj.districtId);
                if (districtObj) {
                    districtObj.shippedNumber += storeShippingData.shippedNumber;
                    districtObj.shippingCost += storeShippingData.shippingCost;
                    districtObj.usps += storeShippingData.usps;
                    districtObj.ups += storeShippingData.ups;
                    districtObj.firstClass += storeShippingData.firstClass;
                    districtObj.totalFirstClassAmount += storeShippingData.totalFirstClassAmount;
                    districtObj.ups2ndDay += storeShippingData.ups2ndDay;
                    districtObj.totalUps2ndDayAmount += storeShippingData.totalUps2ndDayAmount;
                    districtObj.upsNextDay += storeShippingData.upsNextDay;
                    districtObj.totalUpsNextDayAmount += storeShippingData.totalUpsNextDayAmount;
                    districtObj.nextDayAirSaver += storeShippingData.nextDayAirSaver;
                    districtObj.totalNextDayAirSaverAmount += storeShippingData.totalNextDayAirSaverAmount;
                    districtObj.economyGround += storeShippingData.economyGround;
                    districtObj.totalEconomyGroundAmount += storeShippingData.totalEconomyGroundAmount;
                    districtObj.returnCost += storeShippingData.returnCost;
                    districtObj.returnNumbers += storeShippingData.returnNumbers;
                    districtObj.shipToStore += storeShippingData.shipToStore;
                    districtObj.shipToCustomer += storeShippingData.shipToCustomer;
                    districtObj.shipToStoreCount += storeShippingData.shipToStoreCount;
                    districtObj.shipToCustomerCount += storeShippingData.shipToCustomerCount;
                    districtObj.storesShippingData.push(storeShippingData);


                }
                else {
                    const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;
                    let districtShippingData: IDistrictShippingData = {
                        districtId: storeObj.districtId,
                        district: districtName,
                        shippedNumber: storeShippingData.shippedNumber,
                        shippingCost: storeShippingData.shippingCost,
                        usps: storeShippingData.usps,
                        ups: storeShippingData.ups,
                        firstClass: storeShippingData.firstClass,
                        totalFirstClassAmount: storeShippingData?.totalFirstClassAmount,
                        ups2ndDay: storeShippingData.ups2ndDay,
                        totalUps2ndDayAmount: storeShippingData?.totalUps2ndDayAmount,
                        upsNextDay: storeShippingData.upsNextDay,
                        totalUpsNextDayAmount: storeShippingData?.totalUpsNextDayAmount,
                        nextDayAirSaver: storeShippingData.nextDayAirSaver,
                        totalNextDayAirSaverAmount: storeShippingData?.totalNextDayAirSaverAmount,
                        economyGround: storeShippingData.economyGround,
                        totalEconomyGroundAmount: storeShippingData?.totalEconomyGroundAmount,
                        returnCost: storeShippingData.returnCost,
                        returnNumbers: storeShippingData.returnNumbers,
                        shipToStore: storeShippingData.shipToStore,
                        shipToCustomer: storeShippingData.shipToCustomer,
                        shipToStoreCount: storeShippingData.shipToStoreCount,
                        shipToCustomerCount: storeShippingData.shipToCustomerCount,
                        storesShippingData: [storeShippingData]
                    };
                    shippingReport.districtShippingData.push(districtShippingData);
                }
            }
        });
        return shippingReport
    }

    public static mapFulfillData = (data: any, stores: any, districts: any) => {
        let fulfillReport: IFulfilledReport = { districtfulfillData: [] };
        let oneStoreDataList: any[] = []
        data?.aggregations?.sameStoreCustomerPickedup?.buckets.forEach((bucket: any) => {
            let store = {
                storeId: bucket.key,
                shippedNumbers: 0,
                shippedValue: 0,
                sameStorePickupedNumber: bucket?.pickedUpCount?.doc_count ? bucket?.pickedUpCount?.doc_count : 0,
                sameStorePickupedValue: bucket?.pickedUpDollarValue?.sum?.value ? bucket?.pickedUpDollarValue?.sum?.value : 0,
                shippedUsedItemsNumbers: 0,
                shippedUsedItemsValue: 0,
                inStoreUsedItemsNumbers: 0,
                inStoreUsedItemsValue: 0,
                STSNumbers: 0,
                STSValue: 0,
                STSUsedItemsNumbers: 0,
                STSUsedItemsValue: 0,


            }
            oneStoreDataList.push(store)
        })
        data?.aggregations?.shippedCount?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.shippedNumbers = bucket?.shippedCount?.doc_count ? bucket?.shippedCount?.doc_count : 0
                store.shippedValue = bucket?.shippedDollarValue?.sum?.value ? bucket?.shippedDollarValue?.sum?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: bucket?.shippedCount?.doc_count ? bucket?.shippedCount?.doc_count : 0,
                    shippedValue: bucket?.shippedDollarValue?.sum?.value ? bucket?.shippedDollarValue?.sum?.value : 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.shippedUsedItems?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.shippedUsedItemsNumbers = bucket?.shippedUsedItemsCount?.shippedUsedCount ? bucket?.shippedUsedItemsCount?.shippedUsedCount.value : 0
                store.shippedUsedItemsValue = bucket?.shippedUsedItemsDollarValue?.shippedUsedDollarValue?.value ? bucket?.shippedUsedItemsDollarValue?.shippedUsedDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: bucket?.shippedUsedItemsCount?.shippedUsedCount ? bucket?.shippedUsedItemsCount?.shippedUsedCount.value : 0,
                    shippedUsedItemsValue: bucket?.shippedUsedItemsDollarValue?.shippedUsedDollarValue?.value ? bucket?.shippedUsedItemsDollarValue?.shippedUsedDollarValue?.value : 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.inStoreUsedItems?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.inStoreUsedItemsNumbers = bucket?.inStoreUsedItemsCount?.inStoreUsedCount ? bucket?.inStoreUsedItemsCount?.inStoreUsedCount.value : 0
                store.inStoreUsedItemsValue = bucket?.inStoreUsedItemsDollarValue?.inStoreUsedDollarValue?.value ? bucket?.inStoreUsedItemsDollarValue?.inStoreUsedDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: bucket?.inStoreUsedItemsCount?.inStoreUsedCount ? bucket?.inStoreUsedItemsCount?.inStoreUsedCount.value : 0,
                    inStoreUsedItemsValue: bucket?.inStoreUsedItemsDollarValue?.inStoreUsedDollarValue?.value ? bucket?.inStoreUsedItemsDollarValue?.inStoreUsedDollarValue?.value : 0
                }
                oneStoreDataList.push(newStore)
            }
        })

        console.log("oneStoreDataList", oneStoreDataList);

        oneStoreDataList.forEach((store: any) => {
            const storeObj = stores.find((s: any) => s.id == store.storeId);
            if (storeObj) {
                let storeFulfilledData: IStoreFulfilledData = {
                    storeId: storeObj.id,
                    storeName: `${storeObj.id}-${storeObj.name}`,
                    shippedNumbers: store.shippedNumbers,
                    shippedValue: store.shippedValue,
                    // customerPickedupNumbers: store.sameStorePickupedNumber + store.shipToStorePickedUpNumber,
                    // customerPickedupValue: store.sameStorePickupedValue + store.shipToStorePickedUpValue,
                    customerPickedupNumbers: store.sameStorePickupedNumber,
                    customerPickedupValue: store.sameStorePickupedValue,
                    shippedUsedItemsNumbers: store.shippedUsedItemsNumbers,
                    shippedUsedItemsValue: store.shippedUsedItemsValue,
                    inStoreUsedItemsNumbers: store.inStoreUsedItemsNumbers,
                    inStoreUsedItemsValue: store.inStoreUsedItemsValue,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,

                };
                if (storeObj.districtId) {
                    let districtObj = fulfillReport.districtfulfillData.find((x: IDistrictFulfilledData) => x.districtId == storeObj.districtId);
                    if (districtObj) {
                        districtObj.shippedNumbers += storeFulfilledData.shippedNumbers;
                        districtObj.shippedValue += storeFulfilledData.shippedValue;
                        districtObj.customerPickedupNumbers += storeFulfilledData.customerPickedupNumbers;
                        districtObj.customerPickedupValue += storeFulfilledData.customerPickedupValue;
                        districtObj.shippedUsedItemsNumbers += storeFulfilledData.shippedUsedItemsNumbers;
                        districtObj.shippedUsedItemsValue += storeFulfilledData.shippedUsedItemsValue;
                        districtObj.inStoreUsedItemsNumbers += storeFulfilledData.inStoreUsedItemsNumbers;
                        districtObj.inStoreUsedItemsValue += storeFulfilledData.inStoreUsedItemsValue;
                        districtObj.storeFulfilledData.push(storeFulfilledData);
                    }
                    else {
                        const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;
                        let districtFulfillmentData: IDistrictFulfilledData = {
                            districtId: storeObj.districtId,
                            district: districtName,
                            shippedNumbers: storeFulfilledData.shippedNumbers,
                            shippedValue: storeFulfilledData.shippedValue,
                            customerPickedupNumbers: storeFulfilledData.customerPickedupNumbers,
                            customerPickedupValue: storeFulfilledData.customerPickedupValue,
                            shippedUsedItemsNumbers: storeFulfilledData.shippedUsedItemsNumbers,
                            shippedUsedItemsValue: storeFulfilledData.shippedUsedItemsValue,
                            inStoreUsedItemsNumbers: storeFulfilledData.inStoreUsedItemsNumbers,
                            inStoreUsedItemsValue: storeFulfilledData.inStoreUsedItemsValue,
                            STSNumbers: 0,
                            STSValue: 0,
                            STSUsedItemsNumbers: 0,
                            STSUsedItemsValue: 0,
                            storeFulfilledData: [storeFulfilledData]
                        };
                        fulfillReport.districtfulfillData.push(districtFulfillmentData);
                    }
                }
            }

        });
        return fulfillReport
    }
    public static mapFulfillDataHistory = (data: any, stores: any, districts: any) => {
        let fulfillReport: IFulfilledReport = { districtfulfillData: [] };
        let oneStoreDataList: any[] = []
        // debugger
        data?.aggregations?.sameStoreCustomerPickedup?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.sameStorePickupedNumber = bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0
                store.sameStorePickupedValue = bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value : 0
            } else {

                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0,
                    sameStorePickupedValue: bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value : 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,

                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.shippedCount?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.shippedNumbers = bucket?.shippedCount?.doc_count ? bucket?.shippedCount?.doc_count : 0
                store.shippedValue = bucket?.shippedCount?.shippedDollarValue?.value ? bucket?.shippedCount?.shippedDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: bucket?.shippedCount?.doc_count ? bucket?.shippedCount?.doc_count : 0,
                    shippedValue: bucket?.shippedCount?.shippedDollarValue?.value ? bucket?.shippedCount?.shippedDollarValue?.value : 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.shippedUsedItems?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.shippedUsedItemsNumbers = bucket?.shippedUsedItems.doc_count ? bucket?.shippedUsedItems.doc_count : 0
                store.shippedUsedItemsValue = bucket?.shippedUsedItems?.shippedUsedItemsDollarValue?.value ? bucket?.shippedUsedItems?.shippedUsedItemsDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: bucket?.shippedUsedItems.doc_count ? bucket?.shippedUsedItems.doc_count : 0,
                    shippedUsedItemsValue: bucket?.shippedUsedItems?.shippedUsedItemsDollarValue?.value ? bucket?.shippedUsedItems?.shippedUsedItemsDollarValue?.value : 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.inStoreUsedItems?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.inStoreUsedItemsNumbers = bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0
                store.inStoreUsedItemsValue = bucket?.sameStoreCustomerPickedup?.inStoreUsedItemsDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.inStoreUsedItemsDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0,
                    inStoreUsedItemsValue: bucket?.sameStoreCustomerPickedup?.inStoreUsedItemsDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.inStoreUsedItemsDollarValue?.value : 0
                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.STS?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            if (store) {
                store.STSNumbers = bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0
                store.STSValue = bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSUsedItemsNumbers: 0,
                    STSUsedItemsValue: 0,
                    STSNumbers: bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0,
                    STSValue: bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.pickedUpDollarValue?.value : 0,
                }
                oneStoreDataList.push(newStore)
            }
        })
        data?.aggregations?.STSUsedItems?.buckets.forEach((bucket: any) => {
            let store = oneStoreDataList.find((x: any) => x.storeId == bucket.key)
            // debugger
            if (store) {
                store.STSUsedItemsNumbers = bucket?.sameStoreCustomerPickedup?.doc_count ? bucket?.sameStoreCustomerPickedup?.doc_count : 0
                store.STSUsedItemsValue = bucket?.sameStoreCustomerPickedup?.inStoreUsedItemsDollarValue?.value ? bucket?.sameStoreCustomerPickedup?.inStoreUsedItemsDollarValue?.value : 0
            }
            else {
                let newStore = {
                    storeId: bucket.key,
                    shippedNumbers: 0,
                    shippedValue: 0,
                    sameStorePickupedNumber: 0,
                    sameStorePickupedValue: 0,
                    shippedUsedItemsNumbers: 0,
                    shippedUsedItemsValue: 0,
                    inStoreUsedItemsNumbers: 0,
                    inStoreUsedItemsValue: 0,
                    STSNumbers: 0,
                    STSValue: 0,
                    STSUsedItemsNumbers: bucket?.STSUsedItems?.doc_count ? bucket?.STSUsedItems?.doc_count : 0,
                    STSUsedItemsValue: bucket?.STSUsedItems?.inStoreUsedItemsDollarValue?.value ? bucket?.STSUsedItems?.inStoreUsedItemsDollarValue?.value : 0
                }
                oneStoreDataList.push(newStore)
            }
        })

        console.log("oneStoreDataList", oneStoreDataList);



        oneStoreDataList.forEach((store: any) => {
            const storeObj = stores.find((s: any) => s.id == store.storeId);
            if (storeObj) {
                let storeFulfilledData: IStoreFulfilledData = {
                    storeId: storeObj.id,
                    storeName: `${storeObj.id}-${storeObj.name}`,
                    shippedNumbers: store.shippedNumbers,
                    shippedValue: store.shippedValue,
                    // customerPickedupNumbers: store.sameStorePickupedNumber + store.shipToStorePickedUpNumber,
                    // customerPickedupValue: store.sameStorePickupedValue + store.shipToStorePickedUpValue,
                    customerPickedupNumbers: store.sameStorePickupedNumber,
                    customerPickedupValue: store.sameStorePickupedValue,
                    shippedUsedItemsNumbers: store.shippedUsedItemsNumbers,
                    shippedUsedItemsValue: store.shippedUsedItemsValue,
                    inStoreUsedItemsNumbers: store.inStoreUsedItemsNumbers,
                    inStoreUsedItemsValue: store.inStoreUsedItemsValue,
                    STSNumbers: store.STSNumbers,
                    STSValue: store.STSValue,
                    STSUsedItemsNumbers: store.STSUsedItemsNumbers,
                    STSUsedItemsValue: store.STSUsedItemsValue,
                };
                if (storeObj.districtId) {
                    let districtObj = fulfillReport.districtfulfillData.find((x: IDistrictFulfilledData) => x.districtId == storeObj.districtId);
                    if (districtObj) {
                        districtObj.shippedNumbers += storeFulfilledData.shippedNumbers;
                        districtObj.shippedValue += storeFulfilledData.shippedValue;
                        districtObj.customerPickedupNumbers += storeFulfilledData.customerPickedupNumbers;
                        districtObj.customerPickedupValue += storeFulfilledData.customerPickedupValue;
                        districtObj.shippedUsedItemsNumbers += storeFulfilledData.shippedUsedItemsNumbers;
                        districtObj.shippedUsedItemsValue += storeFulfilledData.shippedUsedItemsValue;
                        districtObj.inStoreUsedItemsNumbers += storeFulfilledData.inStoreUsedItemsNumbers;
                        districtObj.inStoreUsedItemsValue += storeFulfilledData.inStoreUsedItemsValue;
                        districtObj.STSNumbers += store.STSNumbers;
                        districtObj.STSValue += store.STSValue;
                        districtObj.STSUsedItemsNumbers += store.STSUsedItemsNumbers;
                        districtObj.STSUsedItemsValue += store.STSUsedItemsValue;
                        districtObj.storeFulfilledData.push(storeFulfilledData);
                    }
                    else {
                        const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;
                        let districtFulfillmentData: IDistrictFulfilledData = {
                            districtId: storeObj.districtId,
                            district: districtName,
                            shippedNumbers: storeFulfilledData.shippedNumbers,
                            shippedValue: storeFulfilledData.shippedValue,
                            customerPickedupNumbers: storeFulfilledData.customerPickedupNumbers,
                            customerPickedupValue: storeFulfilledData.customerPickedupValue,
                            shippedUsedItemsNumbers: storeFulfilledData.shippedUsedItemsNumbers,
                            shippedUsedItemsValue: storeFulfilledData.shippedUsedItemsValue,
                            inStoreUsedItemsNumbers: storeFulfilledData.inStoreUsedItemsNumbers,
                            inStoreUsedItemsValue: storeFulfilledData.inStoreUsedItemsValue,
                            STSNumbers: storeFulfilledData.STSNumbers,
                            STSValue: storeFulfilledData.STSValue,
                            STSUsedItemsNumbers: storeFulfilledData.STSUsedItemsNumbers,
                            STSUsedItemsValue: storeFulfilledData.STSUsedItemsValue,
                            storeFulfilledData: [storeFulfilledData]
                        };
                        fulfillReport.districtfulfillData.push(districtFulfillmentData);
                    }
                }
            }

        });
        return fulfillReport
    }
    public static mapOrdersTimeData = (data: any, stores: any, districts: any) => {
        let orderTime: any = [];
        data.forEach((bucket: any) => {
            const packBucket = JSON.parse(bucket.pack)
            const pickBucket = JSON.parse(bucket.pick)
            const shipBucket = JSON.parse(bucket.ship)

            for (let i = 0; i < packBucket.length; i++) {
                if (JSON.parse(bucket?.pack)[i]?.store !== "undefined") {
                    let obj = {};
                    if (orderTime.length === 0) {
                        const storeObj = stores.find((s: any) => s.id == packBucket[i].store);
                        obj = { districtId: storeObj ? storeObj.districtId : 0, store: packBucket[i].store, pack: 0, packDivisor: 0, pick: 0, pickDivisor: 0, ship: 0, shipDivisor: 0 }
                        orderTime.push(obj)
                    }
                    const index = orderTime.findIndex((e: any) => e.store === packBucket[i].store);
                    if (index > -1) {
                        orderTime[index].pack = orderTime[index].pack + packBucket[i].time
                        orderTime[index].packDivisor += 1;
                    } else {
                        const storeObj = stores.find((s: any) => s.id == packBucket[i].store);

                        orderTime.push({ districtId: storeObj ? storeObj.districtId : 0, store: packBucket[i].store, pack: packBucket[i]?.time, packDivisor: 1, pick: 0, pickDivisor: 0, ship: 0, shipDivisor: 0 })

                    }

                }

                // console.log("orderTIme", orderTime);

            }
            for (let i = 0; i < pickBucket.length; i++) {
                if (pickBucket?.store !== "undefined") {
                    let obj = {};
                    if (orderTime.length === 0) {
                        const storeObj = stores.find((s: any) => s.id == pickBucket[i].store);
                        obj = { districtId: storeObj, store: pickBucket[i].store, pack: 0, packDivisor: 0, pick: 0, pickDivisor: 0, ship: 0, shipDivisor: 0 }
                        orderTime.push(obj)
                    }
                    const index = orderTime.findIndex((e: any) => e.store === pickBucket[i].store);
                    if (index > -1) {
                        orderTime[index].pick = orderTime[index].pick + pickBucket[i].time
                        orderTime[index].pickDivisor += 1;
                    } else {
                        const storeObj = stores.find((s: any) => s.id == pickBucket[i].store);
                        console.log(storeObj, "storeObj")

                        orderTime.push({ districtId: storeObj ? storeObj.districtId : 0, store: pickBucket[i].store, pack: 0, packDivisor: 0, pick: pickBucket[i].time, pickDivisor: 1, ship: 0, shipDivisor: 0 })

                    }
                }

            }
            for (let i = 0; i < shipBucket.length; i++) {
                if (shipBucket[i]?.store !== "undefined") {
                    let obj = {};
                    if (orderTime.length === 0) {
                        const storeObj = stores.find((s: any) => s.id == shipBucket[i].store);
                        obj = { districtId: storeObj, store: shipBucket[i].store, pack: 0, packDivisor: 0, pick: 0, pickDivisor: 0, ship: 0, shipDivisor: 0 }
                        orderTime.push(obj)
                    }
                    const index = orderTime.findIndex((e: any) => e.store === shipBucket[i].store);
                    if (index > -1) {
                        orderTime[index].ship = orderTime[index].ship + shipBucket[i].time
                        orderTime[index].shipDivisor += 1;
                    } else {
                        console.log(shipBucket[i].store, "bucket.ship[i].store")
                        const storeObj = stores.find((s: any) => s.id == shipBucket[i].store);

                        orderTime.push({ districtId: storeObj ? storeObj.districtId : 0, store: shipBucket[i].store, pack: 0, packDivisor: 0, pick: 0, pickDivisor: 0, ship: shipBucket[i].time, shipDivisor: 1 })


                    }
                }

            }

        });
        //Calculating Date Wise Averages
        orderTime.forEach((bucket: any) => {
            if (bucket.pickDivisor > 1) {
                bucket.pick = bucket.pick / bucket.pickDivisor;
            }
            if (bucket.packDivisor > 1) {
                bucket.pack = bucket.pack / bucket.packDivisor;
            }
            if (bucket.shipDivisor > 1) {
                bucket.ship = bucket.ship / bucket.shipDivisor;
            }
        });
        let otGridData: IOrderTimeReport = { districtOrderTimeData: [] };
        orderTime.forEach((bucket: any) => {
            const storeObj = stores.find((s: any) => s.id == bucket.store);
            if (storeObj) {
                let orderTimeStoresData: IStoreOrderTimeData = {
                    storeId: storeObj.id,
                    storeName: `${storeObj.id}-${storeObj.name}`,
                    pick: bucket.pick,
                    pack: bucket.pack,
                    ship: bucket.ship
                }
                if (storeObj.districtId) {
                    let districtObj = otGridData.districtOrderTimeData.find((x: IDistrictOrderTimeData) => x.districtId == storeObj.districtId);
                    if (districtObj) {
                        districtObj.pick += orderTimeStoresData.pick;
                        districtObj.pack += orderTimeStoresData.pack;
                        districtObj.ship += orderTimeStoresData.ship;
                        districtObj.orderTimeStoresData.push(orderTimeStoresData);
                    }
                    else {
                        const districtName = districts.find((d: any) => d.id == storeObj.districtId)?.name;
                        let districtOrderTimeData: IDistrictOrderTimeData = {
                            districtId: storeObj.districtId,
                            district: districtName,
                            pick: orderTimeStoresData.pick,
                            pack: orderTimeStoresData.pack,
                            ship: orderTimeStoresData.ship,
                            orderTimeStoresData: [orderTimeStoresData]
                        };
                        otGridData.districtOrderTimeData.push(districtOrderTimeData);
                    }
                }
            }

        })

        console.log("District Level Data", otGridData.districtOrderTimeData);
        console.log(otGridData, "otGridData")

        return otGridData

    }
    public static getKFormat = (num: number) => {
        return Math.abs(num) > 999 ? Math.sign(num) * Number(((Math.abs(num) / 1000).toFixed(1))) + 'k' : Math.sign(num) * Math.abs(num)
    }

    public static validateUSNumber = (num: string) => {
        // Check for valid US number
        const isValidPhoneNumber = /^(?:\+?1[-\s\.]?)?\(?\d{3}\)?[-\s\.]?\d{3}[-\s\.]?\d{4}$/.test(num)
        if (!isValidPhoneNumber) {
            return false
        }

        // Check for dashes
        let customerPhoneNumber = num
        if (num?.includes("-")) {
            customerPhoneNumber = num?.replace(/-/g, "");
        }

        return customerPhoneNumber

    }

    public static nFormatter = (num: any, digits: any) => {

        try {
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "K" },
                { value: 1e6, symbol: "M" },
                { value: 1e9, symbol: "B" },
                { value: 1e12, symbol: "T" },
                { value: 1e15, symbol: "P" },
                { value: 1e18, symbol: "E" }
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            let item = lookup.slice().reverse().find(function (item) {
                return num >= item.value;
            });
            return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
        }
        catch (e) {
            console.log(e, "nFormatter");
        }



    }

    public static getConfigurationWithKey = (key: any) => {
        let configurations = localStorage.getItem(Constants.Config);
        if (configurations) {
            let configValues = JSON.parse(configurations);
            return configValues.find((x: any) => x.Key == key)?.Value;
        }
        return null;
    }

    public static getToolTipValue = (key: any) => {
        let configurations = Utils.getConfigurationWithKey("ReportsToolTips")
        if (configurations) {
            let configValues = JSON.parse(configurations);
            return configValues.find((x: any) => x.hasOwnProperty(key))[key];
        }
        return null;
    }

    public static getCarrierURL = (key: any) => {
        let configurations = Utils.getConfigurationWithKey(Constants.Configurations.CarrierUrl);
        if (configurations && key) {
            const lowerCaseKey = key?.toLowerCase();
            let configValues = JSON.parse(configurations);
            //Making keys lowercase
            const configResult = configValues?.map((config:any) => {
                const newObj:any = {};
                Object.keys(config)?.forEach((key:any) => {
                    newObj[key?.toLowerCase()] = config[key];
                });
                return newObj;
            },[]);
            const result = configResult.find((x: any) => x.hasOwnProperty(lowerCaseKey));
            return result ? result[lowerCaseKey]: null;
        }
        return null;
    }

    public static getCarrierMsg = () => {
        let msg = Utils.getConfigurationWithKey(Constants.Configurations.CarrierMsg);
        if (msg) {
            return msg;
        }
        return Constants.MESSAGES.CARRIER_MSG;
    }

    public static findCarrier = (trackNo:any) => {
        const upsRegex = /^1Z[0-9A-Z]{16}$/;
        const fedexRegex = /^(?:\d{12}|\d{15}|\d{20}|(?:96|98)\d{16})$/;
        const uspsRegex = /^(?:(?:92|93|94|95|96|97)[0-9]{20,22}|[0-9]{22}|[A-Z]{2}[0-9]{9}[A-Z]{2}|[A-Z]{2}[0-9]{9}US)$/;
        if (upsRegex.test(trackNo)) {
            return "ups";
        } else if (fedexRegex.test(trackNo)) {
            return "fedex";
        } else if (uspsRegex.test(trackNo)) {
            return "usps";
        }
        return "";
    }

    // Get SMS text from Dynamo
    public static getSMSMsg = (key: any) => {
        let configurations = Utils.getConfigurationWithKey("SMSMessages")

        if (configurations) {
            let configValues = JSON.parse(configurations);
            return configValues.find((x: any) => x.hasOwnProperty(key))[key];
        }
        return null;
    }

    public static getFeedbackConfiguration = () => {
        let configurations = Utils.getConfigurationWithKey("FeedbackConfigs")
        return JSON.parse(configurations)
    }

    public static getFeedbackQuestions = () => {
        let configurations = Utils.getConfigurationWithKey("FeedbackQuestions")
        return JSON.parse(configurations)
    }

    public static getFeedbackQuestionsDropdown = () => {
        let configurations = Utils.getConfigurationWithKey("FeedbackQuestions");
        if (configurations) {
            let options = JSON.parse(configurations);
            return options.map((x: any) => {
                return { id: x.questionId, name: x.questionText };
            })
        }
        return [];

    }

    // Get Notifications from Dynamo
    public static getNotification = () => {
        let configurations = Utils.getConfigurationWithKey("notification")
        if (configurations) {
            return JSON.parse(configurations);

        }
        return null;
    }
    // Get Customer Info
    public static customerInfoFromElasticOrNot = () => {
        let configurations = Utils.getConfigurationWithKey("elasticCustomerDetailsCheck")
        return configurations;
    }

    // Send Message Flag
    public static sendMessageToCustomer = () => {
        let configurations = Utils.getConfigurationWithKey("sendMessageFlag")
        return configurations;
    }

    public static groupByFields = (data: any, fields: any) => {
        if (fields.length === 0) {
            return data;
        }

        const field = fields[0];
        const grouped = data.reduce((acc: any, obj: any) => {
            const key = obj[field];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});

        const result: any = {};
        for (const [key, value] of Object.entries(grouped)) {
            let val: any = value;
            result[key] = this.groupByFields(val, fields.slice(1));
        }

        return result;
    }

    public static mapRegionsForDropdown = (empRegions: IStoreInfo[]) => {

        let mappedData: any = []
        empRegions = Utils.getDistinctObjects(empRegions)
        empRegions.forEach((storeInfo: IStoreInfo) => {
            mappedData.push({ id: storeInfo.RegionId.toString(), name: storeInfo.RegionName })
        });
        return mappedData
    }
    public static getDistinctObjects = (empRegions: IStoreInfo[]) => {
        const distinctObjects = [];
        const seenIds = new Set();
        for (let i = 0; i < empRegions.length; i++) {
            const object = empRegions[i];
            if (!seenIds.has(object.RegionId)) {
                distinctObjects.push(object);
                seenIds.add(object.RegionId);
            }
        }
        return distinctObjects;
    }

    public static pickHighest = (obj: any, num = 1) => {
        const requiredObj: any = {};
        if (num > Object.keys(obj).length) {
            return false;
        };
        Object.keys(obj).sort((a, b) => obj[b] - obj[a]).forEach((key, ind) => {
            if (ind < num) {
                requiredObj[key] = obj[key];
            }
        });
        return requiredObj;
    }
    public static convertHoursToDays = (ms: number) => {
        const Days = Math.floor(ms / (24 * 60 * 60 * 1000));
        const daysms = ms % (24 * 60 * 60 * 1000);
        const hours = Math.floor(daysms / (60 * 60 * 1000));
        const hoursms = ms % (60 * 60 * 1000);
        const minutes = Math.floor(hoursms / (60 * 1000));
        const minutesms = ms % (60 * 1000);
        const sec = Math.floor(minutesms / 1000);
        return {
            "Days": Days, "Hours": hours, "Minutes": minutes, "Sec": sec
        };
    }

    public static getCommissionEmployeeIds = (orderDetail: any) => {
        let employeeIds = [] as any
        if (orderDetail?.ShipmentLines?.ShipmentLine && orderDetail?.ShipmentLines?.ShipmentLine.length > 0) {
            orderDetail?.ShipmentLines?.ShipmentLine.forEach((shipment: any) => {
                if (shipment?.ItemDetails?.GCOrderLineCommissionList && shipment?.ItemDetails?.GCOrderLineCommissionList?.GCOrderLineCommission && shipment?.ItemDetails?.GCOrderLineCommissionList?.GCOrderLineCommission.length > 0) {
                    shipment?.ItemDetails?.GCOrderLineCommissionList?.GCOrderLineCommission.forEach((user: any) => {
                        if (user?.UserID) {
                            employeeIds.push(user?.UserID)
                        }
                    });
                }
            });
        }
        return employeeIds;
    }

    public static isResourcedOrder = (orderDetail: any) => {
        let isResourced = false;
        if (orderDetail?.ShipmentLines?.ShipmentLine?.length > 0) {
            orderDetail.ShipmentLines.ShipmentLine.forEach((shipment: any) => {
                if (shipment?.ExtnRescheduleReqCount > 0) {
                    isResourced = true;
                }
            });
        }
        return isResourced;
    }

    public static shippingAPI = () => {
        let config = Utils.getConfigurationWithKey(Constants.Configurations.ShippingAPI);
        return config?.toLowerCase() == "true" ? true : false;
    }

    public static onHold = () => {
        let onHoldConfig = Utils.getConfigurationWithKey(Constants.Configurations.OnHold)
        return onHoldConfig;
    }

    public static onUnHold = () => {
        let onUnHoldConfig = Utils.getConfigurationWithKey(Constants.Configurations.OnUnHold)
        return onUnHoldConfig;
    }

    public static partialResource = () => {
        let partialResourceConfig = Utils.getConfigurationWithKey(Constants.Configurations.PartialResource)
        return partialResourceConfig;
    }

    public static scannerTesting = () => {
        let scannerTesting = Utils.getConfigurationWithKey(Constants.Configurations.ScannerTesting)
        return scannerTesting;
    }


    public static getAgeTextFormat = (ms: any) => {
        const obj = Utils.convertHoursToDays(ms)

        if (obj.Days && obj.Hours) {
            return `${obj.Days} d(s) - ${obj.Hours} hr(s)`
        }
        if (obj.Days) {
            return `${obj.Days} d(s) `
        }
        if (obj.Hours) {
            return `${obj.Hours} hr(s) `
        }
        if (obj.Minutes) {
            return `${obj.Minutes} min(s) `
        }
        if (obj.Sec) {
            return `${obj.Sec} sec(s) `
        } else { return "" }
    }

    public static isObjectEmpty = (objectName: any) => {
        return Object.keys(objectName).length === 0
    }

    public static getAllShipmentKeysFromDetails = (containers: any) => {
        let keys: any = []
        containers.forEach((container: any) => {
            if (container.ShipmentKey) {
                keys.push(container.ShipmentKey)
            }
        });
        return keys
    }
    public static filterBoxesByShipmentKeys = (shipmentKeys: any, responseNumberBoxHistory: any) => {
        let boxes: any = []
        responseNumberBoxHistory?.data?.hits?.hits.forEach((boxDetails: any) => {
            if (shipmentKeys.includes(boxDetails._source?.shipmentKey)) {
                let details = JSON.parse(boxDetails._source?.boxNumberList)
                boxes.push(...details)
            }
        })
        return boxes;
    }

    public static sumSimilarBoxes = (boxes: any) => {
        let sumedBoxes: any = []
        boxes.forEach((boxDetails: any) => {
            let foundOne = sumedBoxes.findIndex((box: any) => (box.ItemID === boxDetails.ItemID && box.FulfillmentType === boxDetails.FulfillmentType && box.ExtnPOSItemID === boxDetails.ExtnPOSItemID && box?.identifier === boxDetails?.identifier));
            if (foundOne > -1) {
                let sumedBox = sumedBoxes[foundOne]
                sumedBox.boxNumber = Number(sumedBox.boxNumber) + Number(boxDetails.boxNumber)
                sumedBoxes[foundOne] = sumedBox
            }
            else {
                sumedBoxes.push(boxDetails)
            }
        })
        return sumedBoxes;
    }

    public static getEIDUsingShipmentLineKey = (ShipmentLineKey: any, unpackagedItems: any) => {
        let eEid = ""
        let filteredResult = unpackagedItems.filter((line: any) => line.ShipmentLineKey === ShipmentLineKey)
        if (filteredResult && filteredResult.length > 0) {
            eEid = filteredResult[0]?.ItemID
        }
        return eEid;
    }

    public static getEIDUsingShipmentLineKeyFromPendingReceipt = (orderLineKey: any, shipmentLines: any) => {
        let eEid = ""
        let filteredResult = shipmentLines.filter((line: any) => line?.OrderLineKey === orderLineKey)
        if (filteredResult && filteredResult.length > 0) {
            eEid = filteredResult[0]?.ItemID
        }
        return eEid;
    }

    public static isUsedItemByDescription = (description: string) => {
        if (description && description.length > 0) {
            return description.toLocaleLowerCase().startsWith("used")
        }
        else {
            return false
        }
    }

    public static getItemsLocationPayload = (data: any) => {
        let store = data?.ShipNode?.ShipNode
        let itemIds: any = []
        data?.ShipmentLines?.ShipmentLine?.forEach((shipmentLine: any) => {
            if (shipmentLine.hasOwnProperty("CustomComponents")) {
                shipmentLine.CustomComponents.ShipmentLine.forEach((cShipmentLine: any) => {
                    if (cShipmentLine?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID) {
                        itemIds.push({ "location": store, "posItemId": cShipmentLine?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID, "orderNo": cShipmentLine?.OrderNo ?? "" });
                    }
                });
            }
            else {
                if (shipmentLine?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID) {
                    itemIds.push({ "location": store, "posItemId": shipmentLine?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID, "orderNo": shipmentLine?.OrderNo ?? "" });
                }
            }
        });
        return itemIds
    }

    reduceDuplicateFromArray = () => { }

    public static getItemsLocationPayloadForSlip = (data: any) => {
     
        let store = data?.ShipNode
        let itemIds: any = []
        data.ShipmentLines.ShipmentLine.forEach((shipmentLine: any) => {
            if (shipmentLine.hasOwnProperty("CustomComponents")) {
                shipmentLine.CustomComponents.ShipmentLine.forEach((cShipmentLine: any) => {
                    if (cShipmentLine?.ItemDetails?.Extn?.ExtnPOSItemID) {
                        const posItemId = cShipmentLine?.ItemDetails?.Extn?.ExtnPOSItemID;
                        if (!itemIds.some((item: any) => item?.posItemId === posItemId)) {
                            itemIds.push({ "location": store, "posItemId": posItemId });
                        }
                    }
                });
            }
            else {
                if (shipmentLine?.ItemDetails?.Extn?.ExtnPOSItemID) {
                    const posItemId = shipmentLine?.ItemDetails?.Extn?.ExtnPOSItemID;
                    if (!itemIds.some((item: any) => item?.posItemId === posItemId)) {
                        itemIds.push({ "location": store, "posItemId": posItemId });
                    }

                }
            }
        });
        return itemIds
    }

    public static renderTotalOrders = (pick: any, pack: any, ship: any) => {
        let totalOrders = pick + pack + ship;
        return totalOrders == 0 ? "-" : totalOrders;
    }
    
    public static getDollarValue = (value: any) => {
        let amount = this.nFormatter(value, 2);
        if (amount) {
        return '$' + amount;
        }
        return "";
    }

    public static getShortZipCode = (zipCode: any = "") => {
        const zip = zipCode?.split("-");
        return zip[0];
    }
    
    public static getBarcode = (input:string) => {
        const numericString = input.replace(/\D/g, '');
        if (numericString.length >= 10 && numericString.length <= 20) {
           return numericString;
        } else {
            return null;
        }
    }

}

